import { useEffect, useState, useRef } from "react";
import Table from "../../../components/table/Table";
import { useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Toast } from "primereact/toast";
import { protectedCall } from "../../../services/userService";

export default function UserCreation() {
  const [row, setRow] = useState({});
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleMapping, setRoleMapping] = useState({}); // To hold role_id to role_name mapping
  const tableName = "Users";
  const toast = useRef(null);

  useEffect(() => {
    const fetchRoles = async () => {
      // Fetch the roles from the API
      const rolesResponse = await protectedCall("api/roles"); // Make sure this API endpoint exists
      const newRoles = await protectedCall("api/users");

      // Create a mapping of role_id to role_name
      const mapping = {};
      rolesResponse.forEach(role => {
        mapping[role.id] = role.name; // Assuming the response has an id and name
      });
      setRoleMapping(mapping);

      // Map users to include role_name instead of role_id
      setRoles(
        newRoles.map((elem,index) => ({
          "S No": index + 1,
          id: elem.users_id,
          Users: elem.name,
          Role: mapping[elem.role_id] || "Unknown Role", // Use mapping to get the role name
          Status: elem.isActive ? "Active" : "Deactivated",
        }))
      );
    };

    fetchRoles();
  }, []);

  const showError = (errorMsg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMsg,
      life: 3000,
    });
  };

  const showInfo = (info) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: info,
      life: 3000,
    });
  };

  const actions = [
    {
      label: "Edit",
      icon: "pi pi-pen-to-square",
      command: () => {
        navigate("/admin/user/edit", {
          state: { id: row.id, roleName: row.Role, action: "edit" },
        });
      },
    },
    {
      label: "View",
      icon: "pi pi-eye",
      command: () => {
        navigate("/admin/user/view", {
          state: { id: row.id, roleName: row.Role, action: "view" },
        });
      },
    },
    {
      label: "Active/Deactivate",
      icon: "pi pi-trash",
      command: async () => {
        try {
          console.log("Toggle user active status for ID:", row.id);
          console.log("Current isActive status:", row.isActive);

          // Determine the new active status based on the current status
          const newIsActiveStatus = !row.isActive;

          // Call the API to toggle the user's active status
          const response = await protectedCall(
            `/api/users/toggle-active`,
            {
              users_id: parseInt(row.id, 10),
              isActive: newIsActiveStatus,
            },
            "post"
          );

          console.log("API response:", response);

          // Update the state with the new status
          setRoles((prevUsers) =>
            prevUsers.map((user) =>
              user.id === row.id ? { ...user, isActive: newIsActiveStatus, Status: newIsActiveStatus ? "Active" : "Deactivated" } : user
            )
          );

          showInfo(`User ${newIsActiveStatus ? 'Activated' : 'Deactivated'} Successfully`);
          setMenuOpen(false);
        } catch (e) {
          console.error("Error toggling user active status:", e.message);
          showError("Failed to toggle user status");
        }
      },
    },
  ];

  const head = Object.keys(
    roles.length ? { ...roles[0], action: "action" } : {}
  ).filter(col => col !== 'isActive'&& col !== "id"); // Exclude isActive from head

  return (
    <>
      <Toast ref={toast} />
      <Table
        products={roles}
        setProducts={setRoles}
        addBtnName={"Add User"}
        tableName={tableName}
        size="large"
        actions={actions}
        head={head}
        addBtnAction={() => {
          navigate("/admin/adduser");
        }}
        setRow={setRow}
        setMenuOpen={setMenuOpen}
        isMenuOpen={isMenuOpen}
      />
    </>
  );
}

import React from "react";
// import partnership from "../../images/partnership/partnership.jpg";
import partnership2 from "../../images/partnership/partnership2.jpg";

import tick from "../../images/partnership/tick.png";

const Partnershipsubconotent = () => {
  return (
    <div className="HeroBg py-20 px-6 md:px-10 lg:px-20">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="flex justify-center items-center">
          <img
            className="rounded-lg w-full md:h-96 lg:h-96 h-52"
            src={partnership2}
            alt="Partnership"
          />
        </div>
        <div className="flex flex-col justify-center space-y-6 lg:mx-14">
          <h1 className="font-bold text-2xl md:text-3xl lg:text-3xl text-pink-600">
            To become a partner with ELiNA through their franchise model, follow
            these steps:
          </h1>
          <div className="space-y-4">
            <div className="flex items-center">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3 flex justify-start align-top"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium  text-gray-500">
                <span className="font-bold text-pink-600">
                  Express Interest:
                </span>{" "}
                Reach out to ELiNA to express your interest in becoming a
                franchise partner.
              </p>
            </div>
            <div className="flex items-center">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium  text-gray-500">
                <span className="font-bold text-pink-600">
                  Initial consultation:
                </span>{" "}
                Participate in a consultation to discuss alignment with ELiNA’s
                mission and values.
              </p>
            </div>
            <div className="flex items-center lg:mx-18">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium text-gray-500">
                <span className="font-bold text-pink-600">
                  Review franchise information:
                </span>{" "}
                Review detailed information on the franchise model, including
                investment and support details.
              </p>
            </div>
            <div className="flex items-center">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium text-gray-500">
                <span className="font-bold text-pink-600">
                  Application process:
                </span>{" "}
                Complete the application process, including any required
                documentation.
              </p>
            </div>
            <div className="flex items-center">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium text-gray-500">
                <span className="font-bold text-pink-600">
                  Agreement signing:
                </span>{" "}
                Sign the franchise agreement to formalize the partnership.
              </p>
            </div>
            <div className="flex items-center">
              <img
                className="w-5 h-5 lg:w-6 lg:h-6 mr-3"
                src={tick}
                alt="Tick"
              />
              <p className="text-lg lg:text-lg font-medium text-gray-500">
                <span className="font-bold text-pink-600">
                  Training and launch:
                </span>{" "}
                Receive training and launch your ELiNA franchise in your
                community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnershipsubconotent;

import React, { useState, useEffect, lazy, Suspense } from "react";
import { Carousel } from "flowbite-react";
// import heroimg from "../../images/teaching3.jpg";
import heroimg from "../../images/compressed/hero/teaching3-min.jpg";
import heroimg2 from "../../images/heroPage/1.jpg";
import heroimg3 from "../../images/heroPage/2.jpg";

import { ArrowRightIcon, ChatIcon } from "@heroicons/react/outline";

const Hero = ({ HeroContent }) => {
  return (
    <>
      <div className="HeroBg relative ">
        <div className=" flex flex-col  lg:flex-row mx:[4%] lg:mx-[6%]   md:pt-[3%]  lg:pb-10 ">
          <div className=" flex flex-col justify-center text-start lg:text-start md:text-center gap-14 md:gap-6 my-6 mx-10 mt-7 md:mx-24 md:my-10  lg:pr-10 lg:m-0">
            <h1 className=" text-4xl font-black text-blue-700 md:text-4xl lg:text-5xl">
              Welcome to ELiNA's world:
              <br />
              <span className="text-5xl  text-pink-600 md:text-6xl lg:text-5xl">
                Empowering families and shaping futures
              </span>
            </h1>
            <p className="text-justify pb-10 text-sm md:pb-0  md:text-xl ">
              At ELiNA, we envision a world where every child is provided with
              the tools and support to realize their full potential. We are
              dedicated to offering comprehensive resources, guidance, and
              support to children with{" "}
              <span className="text-pink-600 font-black">ADHD,</span>{" "}
              <span className="text-pink-600 font-black">Autism,</span>{" "}
              <span className="text-pink-600 font-black">
                Learning Disabilities,
              </span>{" "}
              <span className="text-pink-600 font-black">Dyslexia,</span>{" "}
              <span className="text-pink-600 font-black">Dyspraxia,</span>{" "}
              <span className="text-pink-600 font-black">Dyscalculia,</span>{" "}
              <span className="text-pink-600 font-black">
                Intellectual Disability,
              </span>{" "}
              along with their families, schools, and professionals. Our mission
              is to empower families and shape brighter futures through
              personalized programs and inclusive practices.
            </p>
          </div>

          <>
            <img
              className="hidden rounded-3xl object-cover lg:block lg:w-[45%]"
              src={heroimg2}
              alt="Hero Bg"
            />
          </>
        </div>
        <div className="flex flex-wrap gap-3 pxh-10 pb-20 justify-start md:justify-center lg:justify-start mx-[10%] lg:mx-[6%] lg:pr-0 lg:pb-10 lg:gap-5">
          {HeroContent.map((item) => (
            <button className="text-white cursor-default bg-pink-600 border-pink-700 hover:border-pink-700 hover:text-pink-600 rounded-2xl text-sm p-1 px-3 md:px-7 md:py-2 md:text-lg lg:px-4 lg:py-1 lg:text-2xl">
              {item.HeroButtonText}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
export default Hero;

import React, { useEffect, useState } from "react";

import axios from "axios";
const TeamElina = () => {

  const [visionaries, setVisionaries] = useState([]);
  const [family, setFamily] = useState([])
  useEffect(() => {
    const fetchvisionaries = async () => {
      try {
        let type = "elina family"
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/aboutType/${type}`)
        const data = response.data;
        // console.log(data);
        setVisionaries(data)
      } catch (error) {
        console.log("error while fetching visionaries", error)
      }
    }
    const fetchfamily = async () => {
      try {
        let type = "Visionaries of elina"
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/aboutType/${type}`)
        const data = response.data;
        // console.log(data);
        setFamily(data)
      } catch (error) {
        console.log("error while fetching family", error)
      }
    }
    fetchvisionaries();
    fetchfamily();
  }, [])
  return (
    <>
      <div className="HeroBg2">
        <div className="p-10">
          <h1 className="text-center text-5xl lg:text-6xl font-black text-blue-700 my-10 lg:m-16 ">
            Visionaries of ELiNA
          </h1>
          <div className="flex flex-wrap justify-center w-[100%] gap-20">

            {visionaries.map((item) => {
              const base64String = btoa(
                new Uint8Array(item.image.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
              );
              const imgSrc = `data:image/jpeg;base64,${base64String}`;
              return (
                <div className="flex flex-col  items-center relative md:w-[20.3%] ">
                  <img
                    className="rounded-full w-[250px] h-[250px] "
                    src={imgSrc}
                    alt=""
                  ></img>
                  <h1 className="text-center text-lg font-bold lg:pt-[3%]">
                    {item.name}
                  </h1>
                  <p className="mx-auto font-medium text-base text-gray-500 ">
                    {item.role}
                  </p>
                  <div className="absolute opacity-0 ease-in-out delay-150 hover:opacity-100 duration-300">
                    <p className=" w-[250px] h-[250px] p-5 ease-in-out delay-150 text-xs font-light text-white text-center flex items-center rounded-full hover:bg-black-rgba ">
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="p-10">
          <h1 className="text-center text-4xl md:text-5xl lg:text-6xl font-black text-blue-700 my-10 lg:m-16 ">
            The ELiNA family
          </h1>
          <div className="flex justify-center flex-wrap w-[100%]">
            {family.map((item) => {
              const base64String = btoa(
                new Uint8Array(item.image.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
              );
              const imgSrc = `data:image/jpeg;base64,${base64String}`;
              return (
                <div className="flex flex-col items-center relative md:w-1/2 lg:w-[33.3%] mb-20 ">
                  <div className="">
                    <img
                      className="flex rounded-full w-[250px] h-[250px]"
                      src={imgSrc}
                      alt={item.name}
                    ></img>
                  </div>
                  <div className="">
                    <h1 className="text-center lg:text-2xl text-lg font-bold lg:pt-[3%]">
                      {item.name}
                    </h1>
                  </div>
                  <div className="">
                    <p className="mx-auto px-10 font-medium lg:text-base text-sm text-center text-gray-500">
                      {item.role}
                    </p>
                  </div>
                  <div className=" absolute opacity-0  ease-in-out delay-150 hover:opacity-100 duration-300">
                    <p className=" w-[250px] h-[250px] py-5 px-4 ease-in-out delay-150 text-xs font-light text-white text-center flex items-center rounded-full hover:bg-black-rgba ">
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamElina;

import { useState, useEffect, useRef } from "react";
import { Tree } from "primereact/tree";

import { protectedCall, } from "../../../services/userService";
import { useLocation, useNavigate, } from "react-router-dom";
import { useSelector } from "react-redux"

import styles from "./styles.module.css";
// import Button from "../../components/button/Button";
import { Toast } from "primereact/toast";
import TextInput from "../../../components/form/text/TextInput";
import { useForm } from "react-hook-form";
export default function UserPermissionsEditAndView() {
  const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const { state } = useLocation();
  const [rawMenu, setRawMenu] = useState();
  const [roles, setRoles] = useState([]);
  const { register, handleSubmit, setValue } = useForm();
  const role_id = useSelector(state => state.login.roleId);
  const navigate = useNavigate();
  const mapUamToTreeNodes = (uam) => {
    return uam.map((module) => ({
      key: `module-${module.moduleId}`,
      label: module.moduleName,
      data: `Module ${module.moduleId}`,
      children: [
        ...module.subModules.map((subModule) => ({
          key: `subModule-${subModule.subModuleId}`,
          label: subModule.subModuleName,
          data: `SubModule ${subModule.subModuleId}`,
          children: subModule.subModuleScreens.map((screen) => ({
            key: `screen-${screen.screenId}`,
            label: screen.screenName,
            data: `Screen ${screen.screenId}`,
            children: screen.actions.map((action) => ({
              key: `action-${screen.screenId}-${action.id}`,
              label: action.name,
              data: action.id,
            })),
          })),
        })),
        ...module.screens.map((screen) => ({
          key: `screen-${screen.screenId}`,
          label: screen.screenName,
          data: `Screen ${screen.screenId}`,
          children: screen.actions.map((action) => ({
            key: `action-${screen.screenId}-${action.id}`,
            label: action.name,
            data: action.id,
          })),
        })),
      ],
    }));
  };
  console.log("state id", state.id)
  
  const fetchRoles = async () => {
    try {
      const newRoles = await protectedCall("/api/roles");
      setRoles(
        newRoles.map((elem) => {
          return {
            id: elem.id,
            Role_Name: elem.name,
          };
        })
      );
    } catch (e) {
      console.error(e.message);
      showError("Failed to fetch roles");
    }
  };
  useEffect(() => {
    fetchRoles(); // Fetch roles on component mount
  }, []);

  const mapRolePermissionsToSelectedKeys = (permissions) => {
    let selectedKeys = {};

    permissions.forEach((module) => {
      let moduleChecked = false;

      module.subModules.forEach((subModule) => {
        let subModuleChecked = false;

        subModule.subModuleScreens.forEach((screen) => {
          let screenChecked = false;

          screen.actions.forEach((action) => {
            if (action.hasPermission) {
              selectedKeys[`action-${screen.screenId}-${action.id}`] = {
                checked: true,
                partialChecked: false,
              };
              screenChecked = true;
            }
          });

          if (screenChecked) {
            selectedKeys[`screen-${screen.screenId}`] = {
              checked: true,
              partialChecked: false,
            };
            subModuleChecked = true;
          }
        });

        if (subModuleChecked) {
          selectedKeys[`subModule-${subModule.subModuleId}`] = {
            checked: true,
            partialChecked: false,
          };
          moduleChecked = true;
        }
      });

      module.screens.forEach((screen) => {
        let screenChecked = false;

        screen.actions.forEach((action) => {
          if (action.hasPermission) {
            selectedKeys[`action-${screen.screenId}-${action.id}`] = {
              checked: true,
              partialChecked: false,
            };
            screenChecked = true;
          }
        });

        if (screenChecked) {
          selectedKeys[`screen-${screen.screenId}`] = {
            checked: true,
            partialChecked: false,
          };
          moduleChecked = true;
        }
      });

      if (moduleChecked) {
        selectedKeys[`module-${module.moduleId}`] = {
          checked: true,
          partialChecked: false,
        };
      }
    });

    return selectedKeys;
  };

  const CallApi = (selectedKeys, nodes, roleName) => {
    const permissions = [];

    rawMenu?.forEach((module) => {
      // Process subModules

      module?.subModules?.forEach((subModule) => {
        subModule?.subModuleScreens?.forEach((screen) => {
          const actionIds = [];

          // Check selected actions in the screen
          screen?.actions?.forEach((action) => {
            const key = `action-${screen.screenId}-${action.id}`;
            if (selectedKeys[key] && selectedKeys[key].checked) {
              actionIds.push(action.id); // Collect action IDs
            }
          });

          // If there are selected actions for this screen, add it to permissions
          if (actionIds.length > 0) {
            permissions.push({
              id: module.moduleId, // Use moduleId directly
              screenId: screen.screenId, // Use screenId directly
              actionIds: actionIds,
            });
          }
        });
      });

      module?.screens?.forEach((screen) => {
        const actionIds = [];

        screen?.actions?.forEach((action) => {
          const key = `action-${screen.screenId}-${action.id}`;
          if (selectedKeys[key] && selectedKeys[key].checked) {
            actionIds.push(action.id); // Collect action IDs
          }
        });

        if (actionIds.length > 0) {
          permissions.push({
            id: module.moduleId, // Use moduleId directly
            screenId: screen.screenId, // Use screenId directly
            actionIds: actionIds,
          });
        }
      });
    });

    return {
      name: `${roleName} Updated`,
      description: `Updated ${roleName} role`,
      permissions: permissions,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state?.id) {
          const menu = await protectedCall(`/api/menu-creator`);
          const rolePermission = await protectedCall(
            `/api/menu-creator/${state?.id}`
          );

          // Set the tree nodes
          setNodes(mapUamToTreeNodes(menu?.menus));

          // Set the selected keys
          setSelectedKeys(mapRolePermissionsToSelectedKeys(rolePermission));

          setRawMenu(menu?.menus);
          //saran
          // Fetch user details and set default values
          const userDetails = await protectedCall(`/api/users/${state.id}`);
          setValue("name", userDetails.name);
          setValue("email_id", userDetails.email_id);
          setValue("password", userDetails.password);
          setValue("phoneNumber", userDetails.phone_number);
          setValue("role_id", userDetails.role_id); 
        }
      } catch (e) {
        console.error(e.message);
      }
    };

    fetchData();
  }, [state?.id, setValue]);

  const toast = useRef(null);
  const showError = (errorMsg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMsg,
      life: 3000,
    });
  };

  const showInfo = (info) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: info,
      life: 3000,
    });
  };



  const handleUpdate = async (e) => {
    console.log("update state id ", state.id);
    if (state?.id) {
      try {
        // Update the field name to match the expected format
        const newData = {
          ...e,
          users_id: state.id,
          role_id: parseInt(e.role_id, 10),
          phone_number: e.phoneNumber // Change phone_number to phoneNumber
        };
        delete newData.phoneNumber; // Remove the original phone_number field

        await protectedCall(`/api/users/update`, newData, "post");
        showInfo("User Updated Successfully");
        navigate(-1);
      } catch (e) {
        toast.current.clear();
        showError(e?.message);
      }
    }
  };

  return (
    <>

      <div className="w-full flex justify-center ">
        <div className="w-9/12 pt-10 gap-2">
          <form onSubmit={handleSubmit(handleUpdate)} >
            <div className="gap-6">
              <div className="ml-2 pb-4">
                <TextInput
                  id="name"
                  name="name"
                  register={register}
                  labelText={"Name"}
                  required={"Enter Name"}
                  disabled={state?.action === "view"} 
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="email_id"
                  name="email_id"
                  register={register}
                  labelText={"Email Id"}
                  required={"Enter Your Email id"}
                  disabled={state?.action === "view"} 
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="password"
                  name="password"
                  register={register}
                  labelText={"Password"}
                  required={"Enter Your Password"}
                  disabled={state?.action === "view"} 
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="phoneNumber"
                  name="phoneNumber"
                  register={register}
                  labelText={"Phone Number"}
                  required={"Enter Your Phone number"}
                  disabled={state?.action === "view"} 
                />
              </div>
              <div className="ml-2 pb-4">
                <label className="w-full " htmlFor="role_id">Select Role:</label><br/>
                <select id="role_id" {...register("role_id")} className="border-1 w-full rounded border-black" disabled={state?.action === "view"}>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.Role_Name}
                    </option>
                  ))}
                  
                </select>
              </div>

            </div>
            <div
              className={styles.menuWrapper}
              style={{
                height: "69vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="flex gap-5">
                {state?.action === "edit" && (
                  <button className="p-2 bg-blue-500 border-blue-500 hover:text-blue-500 hover:border-blue-500 hover:border-2">
                    Update
                  </button>
                )}
                <button
                  className="p-2 bg-blue-500 border-blue-500 hover:text-blue-500 hover:border-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Toast ref={toast} />



    </>
  );
}

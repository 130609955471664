import React, { useEffect } from "react";
import BlogOne from "../../../components/mainBlogPage/BlogOne";
import BlogTwo from "../../../components/mainBlogPage/BlogTwo";
import BlogThree from "../../../components/mainBlogPage/BlogThree";
import BlogFour from "../../../components/mainBlogPage/BlogFour";
import BlogFive from "../../../components/mainBlogPage/BlogFive";
import BlogEleven from "../../../components/mainBlogPage/BlogEleven";

import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
// import BlogSix from "../../../components/mainBlogPage/BlogSix";
import BlogSeven from "../../../components/mainBlogPage/BlogSeven";
import BlogEight from "../../../components/mainBlogPage/BlogEight";
import BlogNine from "../../../components/mainBlogPage/BlogNine";
import BlogTen from "../../../components/mainBlogPage/BlogTen";
import BlogTwelve from "../../../components/mainBlogPage/BlogTwelve";
import BlogThirteen from "../../../components/mainBlogPage/BlogThirteen";
import BlogFourteen from "../../../components/mainBlogPage/BlogFourteen";
import ScrollToTop from "react-scroll-to-top";
const MainBlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams();
  const blog = [
    BlogOne,
    BlogTwo,
    BlogThree,
    BlogFour,
    BlogFive,
    // BlogSix,
    BlogSeven,
    BlogEight,
    BlogNine,
    BlogTen,
    BlogEleven,
    BlogTwelve,
    BlogThirteen,
    BlogFourteen,
  ];
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );

  return (
    <>
      {blog.map((elem, index) => {
        if (blog.length < Number(params.id)) console.log("error");
        if (index + 1 === Number(params.id)) return React.createElement(elem);
      })}
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default MainBlogPage;

// src/components/Testimonials.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";

const Testimonials = () => {
  // Modal state variables
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false); // State for view modal
  const [isAddLoading, setIsAddLoading]=useState(true)
  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  const action_ids = useSelector((state) => state.login.action_ids);

  // Data state variables
  const [testimonials, setTestimonials] = useState([]);
  const [formData, setFormData] = useState({
    testimonial_type: "",
    name: "",
    content: "",
    image: null, // Initialize image as null
  });

  const [currentTestimonial, setCurrentTestimonial] = useState(null);

  // Base API URL
  const API_BASE = process.env.REACT_APP_BASE_URL || "http://192.168.1.31:3001/api";

  useEffect(() => {
    fetchTestimonials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch testimonials with proper loading state management
  const fetchTestimonials = async (testimonial_type = "isms page") => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${API_BASE}/testimonials_type/${testimonial_type}`);
      setTestimonials(response.data);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
      toast.error("Failed to fetch testimonials.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const bufferToBase64 = (buffer) => {
    if (!buffer) return "";
    let binary = '';
    const len = buffer.length;
    const chunkSize = 0x8000; // 32768

    for (let i = 0; i < len; i += chunkSize) {
      const chunk = buffer.subarray(i, i + chunkSize);
      binary += String.fromCharCode.apply(null, chunk);
    }
    return `data:image/png;base64,${btoa(binary)}`;
  };

  const base64ToBlob = (base64String) => {
    const [header, base64] = base64String.split(',');
    const mime = header.match(/:(.*?);/)[1];

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      if (files.length > 0) {
        const file = files[0];

        // Validate file type
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only JPEG and PNG images are allowed.");
          return;
        }

        // Validate file size (e.g., max 5MB)
        const maxSize = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
          toast.error("Image size should be less than 5MB.");
          return;
        }

        setFormData({ ...formData, image: file });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Add a new testimonial
  const handleAddTestimonial = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true); // Start loading

    // Validate required fields
    if (!formData.testimonial_type || !formData.name || !formData.content) {
      toast.error("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    // Create FormData
    const data = new FormData();
    data.append("testimonial_type", formData.testimonial_type);
    data.append("name", formData.name);
    data.append("content", formData.content);
    if (formData.image) {
      data.append("image", formData.image);
    }

    try {
      await axios.post(`${API_BASE}/insertTestimonials`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFormData({ testimonial_type: "", name: "", content: "", image: null });
      toast.success("Testimonial added successfully!");
      setIsAddOpen(false);
      fetchTestimonials(formData.testimonial_type);
    } catch (error) {
      console.error("Error adding testimonial:", error);
      toast.error("Failed to add testimonial.");
    } finally {
      setIsLoading(false); // Stop loading
      setIsAddLoading(true)
    }
  };

  // View testimonial details
  const handleView = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setIsViewOpen(true);
  };

  // Open update modal with existing testimonial data
  const openUpdateModal = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setFormData({
      testimonial_type: testimonial.testimonial_type,
      name: testimonial.name,
      content: testimonial.content,
      image: null, // Reset image; upload new if needed
    });
    setIsUpdateOpen(true);
  };



const handleUpdateTestimonial = async (e) => {
  e.preventDefault();
  setIsLoading(true); 
  if (!formData.testimonial_type || !formData.name || !formData.content) {
    toast.error("Please fill in all required fields.");
    setIsLoading(false);
    return;
  }

  const data = new FormData();
  data.append("testimonial_type", formData.testimonial_type);
  data.append("name", formData.name);
  data.append("content", formData.content);

  // Check if a new image is uploaded
  if (formData.image) {
    data.append("image", formData.image);
  } else {
    // If no new image is uploaded, retain the existing image
    if (currentTestimonial.image && currentTestimonial.image.data) {
      const existingImageBlob = base64ToBlob(
        bufferToBase64(new Uint8Array(currentTestimonial.image.data))
      );
      data.append("image", existingImageBlob, "existingImage.png"); // Provide a filename
    }
  }

  try {
    await axios.put(`${API_BASE}/updateTestimonials/${currentTestimonial.id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("Testimonial updated successfully!");
    setIsUpdateOpen(false);
    setCurrentTestimonial(null);
    fetchTestimonials(formData.testimonial_type);
  } catch (error) {
    console.error("Error updating testimonial:", error);
    toast.error("Failed to update testimonial.");
  } finally {
    setIsLoading(false); // Stop loading
  }
};


  // Open delete confirmation modal
  const openDeleteModal = (testimonial) => {
    setCurrentTestimonial(testimonial);
    setIsDeleteOpen(true);
  };

  // Delete a testimonial
  const handleDeleteTestimonial = async () => {
    setIsLoading(true); // Start loading
    try {
      await axios.put(`${API_BASE}/deletetestimonial/${currentTestimonial.id}`);
      toast.success("Testimonial deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentTestimonial(null);
      fetchTestimonials();
    } catch (error) {
      console.error("Error deleting testimonial:", error);
      toast.error("Failed to delete testimonial.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    const selectedType = e.target.value;
    fetchTestimonials(selectedType);
  };

  return (
    <div className="container mx-auto p-6 relative">
      <ToastContainer />

      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="font-bold text-black text-5xl">Testimonials</h1>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"}>
          {
            isAddOpen ? "":
            <button
            onClick={() => setIsAddOpen(!isAddOpen)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none disabled:opacity-50 hover:text-white"
            disabled={isLoading}
          >
            Add Testimonial
          </button>
          }
        </HasPermission>
      </div>

      {/* Add Testimonial Form */}
      {isAddOpen && !isLoading && (
        <div className="mb-6 p-6 bg-white rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Add New Testimonial</h2>
          <form onSubmit={handleAddTestimonial}>
            <div className="mb-4">
              <label htmlFor="testimonial_type" className="block text-gray-700 mb-2">
                Testimonial Type<span class="text-red-500">*</span>
              </label>
              <select
                id="testimonial_type"
                name="testimonial_type"
                value={formData.testimonial_type}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
                disabled={isLoading}
              >
                <option value="">Select Type</option>
                <option value="isms page">ISMS Page</option>
                <option value="home page">Home Page</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 mb-2">
                Author Name<span class="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter author name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
                disabled={isLoading}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="content" className="block text-gray-700 mb-2">
                Content<span class="text-red-500">*</span>
              </label>
              <textarea
                id="content"
                name="content"
                placeholder="Enter testimonial content"
                value={formData.content}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
                disabled={isLoading}
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="image" className="block text-gray-700 mb-2">
                Image 
              </label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleChange}
                className="border p-2 w-full"
                disabled={isLoading}
              />
              <small>Optional. Only JPEG and PNG images under 5MB are allowed.</small>
            </div>
            <div className="flex justify-start space-x-2">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => 
                {
                  setIsAddOpen(false)
                  setFormData({ testimonial_type: "", name: "", content: "", image: null });
                }
                }
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none disabled:opacity-50"
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Filter Dropdown */}
      <div className="flex justify-end mb-4">
        <label htmlFor="filter" className="mr-2 text-gray-700 flex items-center">
          Filter by Type:
        </label>
        <select
          id="filter"
          onChange={handleFilterChange}
          defaultValue="isms page"
          className="p-2 border border-gray-300 rounded"
          disabled={isLoading}
        >
          <option value="isms page">ISMS Page</option>
          <option value="home page">Home Page</option>
          {/* Add more options as needed */}
        </select>
      </div>

      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {/* Testimonials Table */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="py-2 px-4 border">Author Name</th>
                <th className="py-2 px-4 border">Content</th>
                <th className="py-2 px-4 border">Image</th>
                <th className="py-2 px-4 border w-64">Actions</th>
              </tr>
            </thead>
            <tbody>
              {testimonials.length > 0 ? (
                [...testimonials]
                .reverse()
                .map((testimonial, index) => (
                  <tr key={testimonial.id} className="text-center hover:bg-gray-100">
                    <td className="py-2 px-4 border">{index + 1}</td>
                    <td className="py-2 px-4 border">{testimonial.name}</td>
                    <td className="py-2 px-4 border">{testimonial.content}</td>
                    <td className="py-2 px-4 border">
                      {testimonial.image && testimonial.image.data ? (
                        <img
                          src={bufferToBase64(new Uint8Array(testimonial.image.data))}
                          alt={testimonial.name}
                          className="w-16 h-16 object-cover rounded-full"
                        />
                      ) : (
                        "No Image"
                      )}
                    </td>
                    <td className="py-2 px-4 border space-x-2">
                      <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => handleView(testimonial)}
                          className="bg-gray-300 text-black px-3 py-1 rounded hover:bg-gray-400 hover:text-black border-none disabled:opacity-50"
                          disabled={isLoading}
                        >
                          View
                        </button>
                      </HasPermission>
                      <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => openUpdateModal(testimonial)}
                          className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 hover:text-white border-none disabled:opacity-50"
                          disabled={isLoading}
                        >
                          Update
                        </button>
                      </HasPermission>
                      <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => openDeleteModal(testimonial)}
                          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 hover:text-white border-none disabled:opacity-50"
                          disabled={isLoading}
                        >
                          Delete
                        </button>
                      </HasPermission>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="py-4 text-center">
                    No testimonials found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* View Modal */}
      {isViewOpen && currentTestimonial && !isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/2 lg:w-2/4 overflow-y-auto">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Testimonial Details</h2>
            <p className="mb-2">
              <strong>Testimonial Type:</strong> {currentTestimonial.testimonial_type}
            </p>
            <div className="mb-4">
              <strong>Image:</strong>
              {currentTestimonial.image && currentTestimonial.image.data ? (
                <img
                  src={bufferToBase64(new Uint8Array(currentTestimonial.image.data))}
                  alt={currentTestimonial.name}
                  className="w-32 h-32 object-cover rounded-full mt-2"
                />
              ) : (
                <p>No Image</p>
              )}
            </div>
            <p className="mb-2">
              <strong>Author Name:</strong> {currentTestimonial.name}
            </p>
            <p className="mb-4">
              <strong>Content:</strong> {currentTestimonial.content}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none disabled:opacity-50"
              disabled={isLoading}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {isUpdateOpen && currentTestimonial &&  (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 h-4/5 overflow-y-scroll">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Update Testimonial</h2>
            <form onSubmit={handleUpdateTestimonial}>
              <div className="mb-4">
                <label className="block mb-1">Current Image</label>
                {currentTestimonial.image && currentTestimonial.image.data ? (
                  <img
                    src={bufferToBase64(new Uint8Array(currentTestimonial.image.data))}
                    alt={currentTestimonial.name}
                    className="w-32 h-32 object-cover rounded-full mb-2"
                  />
                ) : (
                  <p>No Image</p>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-700 mb-2">
                  Upload New Image (optional) <br/><small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
                </label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleChange}
                  className="border p-2 w-full"
                  disabled={isLoading}
                />
                <small>If you don't upload a new image, the existing image will be retained.</small>
              </div>
              <div className="mb-4">
                <label htmlFor="testimonial_type" className="block text-gray-700 mb-2">
                  Testimonial Type<span class="text-red-500">*</span>
                </label>
                <select
                  id="testimonial_type"
                  name="testimonial_type"
                  value={formData.testimonial_type}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  disabled={isLoading}
                >
                  <option value="">Select Type</option>
                  <option value="isms page">ISMS Page</option>
                  <option value="home page">Home Page</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-2">
                  Author Name<span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter author name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  disabled={isLoading}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="content" className="block text-gray-700 mb-2">
                  Content<span class="text-red-500">*</span>
                </label>
                <textarea
                  id="content"
                  name="content"
                  placeholder="Enter testimonial content"
                  value={formData.content}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  disabled={isLoading}
                ></textarea>
              </div>
              <div className="flex justify-start space-x-2">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 hover:text-white border-none disabled:opacity-50"
                  disabled={isLoading}
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentTestimonial(null);
                    setFormData({ testimonial_type: "", name: "", content: "", image: null });
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none disabled:opacity-50"
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && currentTestimonial && !isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-80">
            <h2 className="text-xl font-bold text-red-500 mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the testimonial by{" "}
              <strong>{currentTestimonial.name}</strong>?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeleteTestimonial}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white border-none disabled:opacity-50"
                disabled={isLoading}
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentTestimonial(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none disabled:opacity-50"
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonials;

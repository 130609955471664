import React, { useEffect } from "react";
import ProfessionalHero from "../../../components/professional/ProfessionalHero";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import Professional2 from "../../../components/professional/Professional2";
import SpecialNeedCare from "../../../components/professional/SpecialNeedCare";
import WeHelpKids from "../../../components/professional/WeHelpKids";
import Steps from "../../../components/professional/Steps";
import JoinUs from "../../../components/professional/JoinUs";
import ScrollToTop from "react-scroll-to-top";
const ProfessionalsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );

  return (
    <>
      <ProfessionalHero />
      <Professional2 />
      <SpecialNeedCare />
      <WeHelpKids />
      <Steps />
      <JoinUs />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default ProfessionalsPage;

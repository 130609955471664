
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";
const FAQ = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [currentfaq, setCurrentfaq] = useState(null);
  const [faq, setFaq] = useState([]);
  const [isAddLoading,setIsAddLoading]=useState(true)
  const [formData, setFormData] = useState({
    faq_type: "",
    questions: "",
    answer: "",
  });
  const action_ids = useSelector(state => state.login.action_ids)
  const [isLoading,setIsLoading]=useState(false)
  const API_BASE = "http://192.168.1.31:3001/api";
  useEffect(() => {
    fetchfaq();
  }, []);
  const fetchfaq = async (faq_type = "parents") => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/faq_type/${faq_type}`);
      setFaq(response.data);
    } catch (error) {
      console.error("Error fetching faq:", error);
      toast.error("Failed to fetch faq");
    }
    finally{
      setIsLoading(false)
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleAddfaq = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true)
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertfaq`, formData,);
      setFormData({ faq_type: "", questions: "", answer: "" });
      // toast.success("faq added successfully!");
      setIsAddOpen(false);
      fetchfaq(formData.faq_type);
    } catch (error) {
      console.error("Error adding faq:", error);
      // toast.error("Failed to add faq.");
    }
    finally{
      setIsLoading(false)
      setIsAddLoading(true)
    }
  };
  const handleView = (faq) => {
    setCurrentfaq(faq);
    setIsViewOpen(true);
  };
  const openUpdateModal = (faq) => {
    setCurrentfaq(faq);
    setFormData({
      faq_type: faq.faq_type,
      questions: faq.questions,
      answer: faq.answer,
    });
    setIsUpdateOpen(true);
  };

  const handleUpdatefaq = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/updateFaq/${currentfaq.id}`, formData);
      setIsUpdateOpen(false);
      setCurrentfaq(null);
      setFormData({ faq_type: "", questions: "", answer: "" });
      document.getElementById("filter").value = "parents"; // Reset filter to "parents"
      fetchfaq("parents"); // Fetch FAQs of type "parents"
    } catch (error) {
      console.error("Error updating faq:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleUpdatefaq = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true)
  //   try {
  //     await axios.put(`${process.env.REACT_APP_BASE_URL}/updateFaq/${currentfaq.id}`, formData);
  //     // toast.success("faq updated successfully!");
  //     setIsUpdateOpen(false);
  //     setCurrentfaq(null);
  //     fetchfaq(formData.faq_type);
      
  //   } catch (error) {
  //     console.error("Error updating faq:", error);
  //     // toast.error("Failed to update faq.");
  //   }
  //   finally{
  //     setIsLoading(false)
  //   }
  // };
  const openDeleteModal = (faq) => {
    setCurrentfaq(faq);
    setIsDeleteOpen(true);
  };

  const handleDeletefaq = async () => {
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deleteFaq/${currentfaq.id}`);
      setIsDeleteOpen(false);
      setCurrentfaq(null);
      document.getElementById("filter").value = "parents"; // Reset filter to "parents"
      fetchfaq("parents"); // Fetch FAQs of type "parents"
    } catch (error) {
      console.error("Error deleting faq:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // const handleDeletefaq = async () => {
  //   setIsLoading(true)
  //   try {
  //     await axios.put(`${process.env.REACT_APP_BASE_URL}/deleteFaq/${currentfaq.id}`);
  //     // toast.success("faq deleted successfully!");
  //     setIsDeleteOpen(false);
  //     setCurrentfaq(null);
  //     fetchfaq();
  //   } catch (error) {
  //     console.error("Error deleting faq:", error);
  //     // toast.error("Failed to delete faq.");
  //   }
  //   finally{
  //     setIsLoading(false)
  //   }
  // };

  const handleFilterChange = (e) => {
    const selectedType = e.target.value;
    fetchfaq(selectedType);
  };

  return (
    <>
     <div className="container mx-auto p-6">
      <ToastContainer />
      <div className="flex justify-between items-center mb-6">
        <h1 className="font-bold text-black text-5xl">FAQ</h1>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"} >
        {
          isAddOpen?"":
          <button
          onClick={() => setIsAddOpen(!isAddOpen)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 hover:text-white border-none"
        >
          Add FAQ
        </button>
        }
        </HasPermission>
      </div>

       

      {/* Add Testimonial Form */}
      {isAddOpen && (
        <div className="mb-6 p-6 bg-white rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Add New FAQ</h2>
          <form onSubmit={handleAddfaq}>
            <div className="mb-4">
              <label htmlFor="faq_type" className="block text-gray-700 mb-2">
                FAQ Type<span class="text-red-500">*</span>
              </label>
              <select
                id="faq_type"
                name="faq_type"
                value={formData.faq_type}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Type</option>
                <option value="Parents">Parents</option>
                <option value="Professionals">Professionals</option>
                <option value="School">Schools</option> 
                <option value="Intern">Intern</option>  

                {/* Add more options as needed */}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 mb-2">
                Questions<span class="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="questions"
                name="questions"
                placeholder="Enter Question"
                value={formData.questions}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="content" className="block text-gray-700 mb-2">
                Answer<span class="text-red-500">*</span>
              </label>
              <textarea
                id="answer"
                name="answer"
                placeholder="Enter answer"
                value={formData.answer}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-start space-x-2">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAddOpen(false)
                  setFormData({ testimonial_type: "", name: "", content: "" })
                }
                }
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="flex justify-end mb-4">
        <label htmlFor="filter" className="mr-2 text-gray-700 flex text-lg  items-center">
          Filter by Type:
        </label>
        <select
          id="filter"
          onChange={handleFilterChange}
          defaultValue="parents"
          className="p-2 border border-gray-300 rounded"
        >
          <option value="parents">Parents</option>
          <option value="professionals">Professionals</option>
          <option value="school">Schools</option>
          <option value="intern">Intern</option>

        </select>
      </div>
      {isLoading && (
          <div className="flex justify-center items-center my-8">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      {!isLoading && (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-2 px-4 border">S.No</th>
              <th className="py-2 px-4 border">Question</th>
              <th className="py-2 px-4 border">Answer</th>
              <th className="py-2 px-4 border w-40">Actions</th> {/* Static width for Actions */}
            </tr>
          </thead>
          <tbody>
            {faq.length > 0 ? (
              [...faq]
              .reverse()
              .map((faq, index) => (
                <tr key={faq.id} className="text-center hover:bg-gray-100">
                  <td className="py-2 px-4 border">{index + 1}</td>
                  <td className="py-2 px-4 border">{faq.questions}</td>
                  <td className="py-2 px-4 border">{faq.answer}</td>
                  <td className="py-2 px-4 border h-full flex justify-center space-x-2">
                  <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"} >
                  <button
                      onClick={() => handleView(faq)}
                      className="bg-gray-300 text-black px-3 py-1 rounded hover:bg-gray-400 hover:text-black border-none"
                    >
                      View
                    </button>
                    </HasPermission>
                    <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"} >
                    <button
                      onClick={() => openUpdateModal(faq)}
                      className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 hover:text-white border-none"
                    >
                      Update
                    </button></HasPermission>
                    <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"} >
                    <button
                      onClick={() => openDeleteModal(faq)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 hover:text-white border-none"
                    >
                      Delete
                    </button>
                    </HasPermission>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-4 text-center">
                  No testimonials found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      )}

      {isViewOpen && currentfaq && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/2 lg:w-2/4">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">FAQ Details</h2>
            <p className="mb-2">
              <strong>FAQ Type:</strong> {currentfaq.faq_type}
            </p>
            <p className="mb-2">
              <strong>Question:</strong> {currentfaq.questions}
            </p>
            <p className="mb-4">
              <strong>Answer:</strong> {currentfaq.answer}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isUpdateOpen && currentfaq && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg  w-3/4">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Update FAQ</h2>
            <form onSubmit={handleUpdatefaq}>
              <div className="mb-4">
                <label htmlFor="testimonial_type" className="block text-gray-700 mb-2">
                  faq type<span class="text-red-500">*</span>
                </label>
                <select
                  id="faq_type"
                  name="faq_type"
                  value={formData.faq_type}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select Type</option>
                  <option value="Parents">parents</option>
                  <option value="Professionals">Profesionals</option>
                  <option value="School">Schools</option>
                  <option value="Intern">Intern</option>

                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-2">
                  Question<span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="questions"
                  name="questions"
                  placeholder="Enter Question "
                  value={formData.questions}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="content" className="block text-gray-700 mb-2">
                  Answer<span class="text-red-500">*</span>
                </label>
                <textarea
                  id="answer"
                  name="answer"
                  placeholder="Enter answer"
                  value={formData.answer}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                ></textarea>
              </div>
              <div className="flex justify-start space-x-2">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 hover:text-white border-none"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentfaq(null);
                    setFormData({ testimonial_type: "", name: "", content: "" });
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isDeleteOpen && currentfaq && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-80">
            <h2 className="text-xl font-bold text-red-500 mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete this faq by{" "}
              <strong>{currentfaq.questions}</strong>?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeletefaq}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white border-none"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentfaq(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  )
};

export default FAQ;
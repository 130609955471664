import React from "react";
// import parents from "../../images/teaching2.jpg";
// import schools from "../../images/school2.jpg";
// import Professionals from "../../images/teaching.jpg";
import parents from "../../images/compressed/hero/teaching2-min.jpg";
import schools from "../../images/compressed/hero/school2-min.jpg";

import Professionals from "../../images/compressed/hero/teaching-min.jpg";

import { ArrowRightIcon } from "@heroicons/react/outline";

const WhatWeDoContent = [
  {
    h1: (
      <span>
        For <span className=" text-pink-500">parents</span>
      </span>
    ),
    sh: "Empower your child's journey",
    src: parents,
    href: "/#/parent",
    p: "Parenting a child with special needs can be challenging yet rewarding. At ELiNA, we support you every step of the way. Our programs are designed to:",
    li: [
      <span>
        <span className=" font-black text-pink-600">
          Provide personalized assessments:
        </span>{" "}
        Understand your child’s unique strengths and challenges.
      </span>,
      <span>
        <span className=" font-black text-pink-600">
          Offer tailored recommendations:{" "}
        </span>
        Receive expert guidance on managing behaviors, enhancing learning, and
        fostering social skills.
      </span>,
      <span>
        <span className=" font-black text-pink-600">
          Build a supportive community:{" "}
        </span>
        Connect with other parents, share experiences, and find comfort in a
        community that understands your journey.
      </span>,
    ],
    button: "Join us",
    p1: "We equip you with the knowledge and tools to support your child's growth, helping them thrive both at home and beyond.",
  },
  {
    h1: (
      <span>
        For <span className=" text-pink-500">schools</span>
      </span>
    ),
    sh: "Creating inclusive learning environments",
    src: schools,
    href: "/#/school",
    p: "Inclusive education is essential for the development of children with special needs. We collaborate closely with educational institutions to:",
    li: [
      <span>
        <span className=" font-black text-pink-600">
          Design inclusive curricula:
        </span>
        Develop learning strategies that cater to the diverse needs of all
        students.
      </span>,
      <span>
        <span className=" font-black text-pink-600">
          Provide training for educators:
        </span>
        Equip teachers with the skills and resources needed to support children
        with ADHD, Autism, and Learning Disabilities in the classroom.
      </span>,
      <span>
        <span className=" font-black text-pink-600">
          Foster collaborative learning:
        </span>
        Encourage peer interaction and understanding through inclusive
        activities and projects.
      </span>,
    ],
    button: "Join us",
    p1: "Our goal is to create a nurturing and inclusive environment where every child can succeed and feel valued.",
  },
  {
    h1: (
      <span>
        For <span className=" text-pink-500">professionals</span>
      </span>
    ),
    sh: "Enhancing expertise and collaboration",
    src: Professionals,
    href: "/#/professional",
    p: "We recognize the importance of a multidisciplinary approach to support children with special needs. ELiNA offers:",
    li: [
      <span>
        <span className=" font-black text-pink-600">
          Professional development workshops:
        </span>
        Stay updated with the latest research and techniques in special
        education and therapy.
      </span>,
      <span>
        <span className=" font-black text-pink-600">
          Collaboration opportunities:
        </span>
        Work alongside other experts in the field to develop comprehensive care
        plans.
      </span>,
      <span>
        <span className=" font-black text-pink-600">Resource library:</span>
        Access a wide range of materials, from research articles to practical
        guides, to enhance your practice. Stay informed and inspired with
        up-to-date resources tailored to your needs.
      </span>,
    ],
    button: "Join us",
    p1: "Join us in making a difference in the lives of children and their families by expanding your knowledge and skills.",
  },
];

const WhatWeDo = () => {
  return (
    <div className="WhatWeDoBg lg:w-full content-center lg:py-7 HeroBg2">
      <div className="flex flex-col items-center">
        <div className="lg:pb-5 text-center">
          <h1 className="text-5xl lg:text-6xl p-5 animationHero font-black text-blue-700">
            What we do?
          </h1>
        </div>
        <div className="grid lg:grid-cols-3  grid-cols-1 gap-10 p-5 lg:px-10 lg:gap-10 justify-center">
          {WhatWeDoContent.map((item) => (
            <div
              key={item.h1}
              className="flex flex-col items-stretch drop-shadow-xl rounded-3xl bg-white p-5 lg:p-7"
            >
              <div className="flex flex-col flex-grow items-center text-left">
                <div className="">
                  <h2 className="text-3xl md:text-4xl text-gray-800 font-black py-3 text-center">
                    {item.h1}
                  </h2>
                </div>
                <div className=" min-h-16">
                  <p className="font-bold pb-3 text-blue-900 md:text-xl lg:text-lg text-center">
                    {item.sh}
                  </p>
                </div>
                <div className="">
                  <img
                    className="w-[85%]  lg:w-full lg:min-h-60 lg:max-h-60 rounded-3xl mx-auto"
                    src={item.src}
                    alt={item.h1}
                  />
                </div>
                <div className="flex flex-col flex-grow justify-between ">
                  <div className="p-5 lg:p-7 text-gray-800 md:px-10 lg:px-0">
                    <p className="font-bold  lg:min-h-32">{item.p}</p>
                    {item.li.map((i, index) => (
                      <p key={index} className="mt-1">
                        {i}
                      </p>
                    ))}
                  </div>
                  <div className="p-5 lg:p-7 text-gray-800 lg:pt-0 md:px-10 lg:px-0">
                    {item.p1}<a href={item.href} className="text-blue-700 font-medium">
                      <span> Know more...</span>
                    </a>
                  </div>
                </div>
              </div>
              {/* <button
                onClick={() => (window.location.href = item.href)}
                className="flex items-center w-1/2 justify-center mx-auto bg-pink-600 hover:bg-transparent text-white hover:text-pink-600 border-pink-600 p-2 rounded-xl mt-4 lg:mt-6"
              >
                <a href={item.href} className="">
                  {item.button}
                </a>
                <ArrowRightIcon className="w-4 ml-2" />
              </button> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;

// src/components/AboutAdmin.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";
const AboutAdmin = () => {

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const action_ids = useSelector(state => state.login.action_ids)
  const [isAddLoading,setIsAddLoading]=useState(true)
  const [abouts, setAbouts] = useState([]);
  const [formData, setFormData] = useState({
    type: "",
    image: null, 
    name: "",
    role: "",
    description: "",
  });

  const [currentAbout, setCurrentAbout] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    fetchAbouts("elina family"); 
  }, []);

  const fetchAbouts = async (type = "") => {
    setIsLoading(true);
    try {
      let endpoint = `${process.env.REACT_APP_BASE_URL}/about`;
      if (type) {
        endpoint = `${process.env.REACT_APP_BASE_URL}/aboutType/${type}`;
      }
      const response = await axios.get(endpoint,);
      setAbouts(response.data);
    } catch (error) {
      console.error("Error fetching about entries:", error);
      toast.error("Failed to fetch about entries.");
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };
  const handleAddAbout = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
   
    const formDataToSend = new FormData();
    formDataToSend.append("Type", formData.type);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("role", formData.role);
    formDataToSend.append("description", formData.description);

   
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertAbout`, formDataToSend,);
      toast.success("About Us details added successfully!");
      setIsAddOpen(false);
      setFormData({
        type: "",
        image: null,
        name: "",
        role: "",
        description: "",
      });
      fetchAbouts("elina family"); 
    } catch (error) {
      console.error("Error adding about entry:", error);
      toast.error("Failed to add about entry.");
    } finally {
      setIsLoading(false);
      setIsAddLoading(true)
    }
  };

  // View about entry details
  const handleView = (about) => {
    setCurrentAbout(about);
    setIsViewOpen(true);
  };

  // Open update modal with existing about data
  const openUpdateModal = (about) => {
    setCurrentAbout(about);
    setFormData({
      type: about.Type,
      image: null, // Reset to null; existing image will be handled separately
      name: about.name,
      role: about.role,
      description: about.description,
    });
    setIsUpdateOpen(true);
  };

  // Efficiently convert buffer to base64 string
  const bufferToBase64 = (buffer) => {
    let binary = "";
    const len = buffer.length;
    const chunkSize = 0x8000; // 32768

    for (let i = 0; i < len; i += chunkSize) {
      const chunk = buffer.subarray(i, i + chunkSize);
      binary += String.fromCharCode.apply(null, chunk);
    }

    return `data:image/png;base64,${btoa(binary)}`;
  };

  // Convert base64 string back to Blob
  const base64ToBlob = (base64String) => {
    const [header, base64] = base64String.split(",");
    const mime = header.match(/:(.*?);/)[1];

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  // Update an existing about entry
  const handleUpdateAbout = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("Type", formData.type);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("role", formData.role);
    formDataToSend.append("description", formData.description);

    if (formData.image) {
      formDataToSend.append("image", formData.image);
    } else {
      if (currentAbout.image && currentAbout.image.data) {
        const existingImageBlob = base64ToBlob(
          bufferToBase64(new Uint8Array(currentAbout.image.data))
        );
        formDataToSend.append("image", existingImageBlob, "existingImage.png"); // Provide a filename
      }
    }

    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/updateAbout/${currentAbout.id}`, formDataToSend,);
      toast.success("About entry updated successfully!");
      setIsUpdateOpen(false);
      setCurrentAbout(null);
      fetchAbouts("elina family"); // Refresh with default filter
    } catch (error) {
      console.error("Error updating about entry:", error);
      toast.error("Failed to update about entry.");
    } finally {
      setIsLoading(false);
    }
  };
  const openDeleteModal = (about) => {
    setCurrentAbout(about);
    setIsDeleteOpen(true);
  };
  const handleDeleteAbout = async () => {
    setIsLoading(true);

    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deleteAbout/${currentAbout.id}`,{},);
      // toast.success("About entry deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentAbout(null);
      fetchAbouts("elina family"); // Refresh with default filter
    } catch (error) {
      console.error("Error deleting about entry:", error);
      // toast.error("Failed to delete about entry.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    const selectedType = e.target.value;
    fetchAbouts(selectedType);
  };

  return (
    <div className="container mx-auto p-6">
      <ToastContainer />
      <div className="flex justify-between items-center mb-6">
        <h1 className="font-bold text-black text-5xl">About Us</h1>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"} >
        {
          isAddOpen? "":
          <button
          onClick={() => setIsAddOpen(!isAddOpen)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none hover:text-white"
        >
          Add About
        </button>
        }
        </HasPermission>
      </div>



      {/* Add About Form */}
      {isAddOpen && (
        <div className="mb-6 p-6 bg-white rounded shadow">
          <h2 className="text-xl font-semibold mb-4">Add New About Entry</h2>
          <form onSubmit={handleAddAbout}>
            <div className="mb-4">
              <label htmlFor="type" className="block text-gray-700 mb-2">
                Type<span class="text-red-500">*</span>
              </label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Type</option>
                <option value="elina family">Elina family</option>
                <option value="visionaries of elina">Visionaries of elina</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="image" className="block text-gray-700 mb-2">
                Image<span class="text-red-500">*</span><br/>
                <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 mb-2">
                Name<span class="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="role" className="block text-gray-700 mb-2">
                Role<span class="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="role"
                name="role"
                placeholder="Enter role"
                value={formData.role}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block text-gray-700 mb-2">
                Description<span class="text-red-500">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter description"
                value={formData.description}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-start space-x-2">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAddOpen(false)
                  setFormData({
                    type: "",
                    image: null,
                    name: "",
                    role: "",
                    description: "",
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Filter Dropdown */}
      <div className="flex justify-end mb-4">
        <label htmlFor="filter" className="mr-2 text-gray-700 flex items-center">
          Filter by Type:
        </label>
        <select
          id="filter"
          onChange={handleFilterChange}
          defaultValue="elina family"
          className="p-2 border border-gray-300 rounded"
        >
          <option value="elina family">Elina family</option>
          <option value="visionaries of elina">Visionaries of elina</option>
          {/* Add more options as needed */}
        </select>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="py-2 px-4 border">S.No</th>
              <th className="py-2 px-4 border">Image</th>
              <th className="py-2 px-4 border">Name</th>
              <th className="py-2 px-4 border">Role</th>
              <th className="py-2 px-4 border">Description</th>
              <th className="py-2 px-4 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {abouts.length > 0 ? (
              [...abouts]
              .reverse()
              .map((about, index) => (
                <tr key={about.id} className="text-center hover:bg-gray-100">
                  <td className="py-2 px-4 border">{index + 1}</td>
                  <td className="py-2 px-4 border">
                    {about.image && about.image.data ? (
                      <img
                        src={bufferToBase64(new Uint8Array(about.image.data))}
                        alt={about.name}
                        className="w-16 h-16 object-cover rounded"
                      />
                    ) : (
                      "No Image"
                    )}
                  </td>
                  {/* <td className="py-2 px-4 border">{about.Type}</td> */}
                  <td className="py-2 px-4 border">{about.name}</td>
                  <td className="py-2 px-4 border">{about.role}</td>
                  <td className="py-2 px-4 border">{about.description}</td>
                  <td className="py-2 px-4 border space-x-2 lg:w-64">
                  <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"} >
                    <button
                      onClick={() => handleView(about)}
                      className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 hover:text-black border-none"
                    >
                      View
                    </button>
                    </HasPermission>
                    <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"} >
                    <button
                      onClick={() => openUpdateModal(about)}
                      className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 hover:text-white border-none"
                    >
                      Update
                    </button>
                    </HasPermission>
                    <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"} >
                    <button
                      onClick={() => openDeleteModal(about)}
                      className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 hover:text-white border-none"
                    >
                      Delete
                    </button>
                    </HasPermission>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="py-4 text-center">
                  No about entries found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      )}

      {/* View Modal */}
      {isViewOpen && currentAbout &&  (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/2 lg:w-2/4">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">About Entry Details</h2>
            {currentAbout.image && currentAbout.image.data ? (
              <img
                src={bufferToBase64(new Uint8Array(currentAbout.image.data))}
                alt={currentAbout.name}
                className="w-32 h-32 object-cover rounded mb-4"
              />
            ) : (
              "No Image"
            )}
            <p className="mb-2">
              <strong>Type:</strong> {currentAbout.Type}
            </p>
            <p className="mb-2">
              <strong>Name:</strong> {currentAbout.name}
            </p>
            <p className="mb-2">
              <strong>Role:</strong> {currentAbout.role}
            </p>
            <p className="mb-2">
              <strong>Description:</strong> {currentAbout.description}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {isUpdateOpen && currentAbout && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 lg:h-3/4 overflow-y-scroll">
            <h2 className="text-xl mb-4">Update About </h2>
            <form onSubmit={handleUpdateAbout}>
              <div className="mb-4">
                <label htmlFor="type" className="block text-gray-700 mb-2">
                  Type <span class="text-red-500">*</span>
                </label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select Type</option>
                  <option value="elina family">Elina family</option>
                  <option value="visionaries of elina">Visionaries of elina</option>
                  {/* Add more options as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-700 mb-2">
                  Current Image<span class="text-red-500">*</span>
                </label>
                {currentAbout.image && currentAbout.image.data ? (
                  <img
                    src={bufferToBase64(new Uint8Array(currentAbout.image.data))}
                    alt={currentAbout.name}
                    className="w-32 h-32 object-cover rounded-full mb-2"
                  />
                ) : (
                  "No Image"
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="image" className="block text-gray-700 mb-2">
                  Upload New Image<span class="text-red-500">*</span> <br/><small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
                </label>
                <input
                  type="file"
                  id="image"
                  name="image" // Consistently using 'image'
                  accept="image/*"
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <small className="text-gray-500">
                  If you don't upload a new image, the existing image will be retained.
                </small>
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-2">
                  Name<span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="block text-gray-700 mb-2">
                  Role<span class="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="role"
                  name="role"
                  placeholder="Enter role"
                  value={formData.role}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="block text-gray-700 mb-2">
                  Description<span class="text-red-500">*</span>
                </label>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  rows="4"
                ></textarea>
              </div>
              <div className="flex justify-start space-x-2">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 hover:text-white border-none"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentAbout(null);
                    setFormData({
                      type: "",
                      image: null,
                      name: "",
                      role: "",
                      description: "",
                    });
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      
      {isDeleteOpen && currentAbout && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-80">
            <h2 className="text-xl font-bold text-red-500 mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the about entry <strong>{currentAbout.name}</strong>?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeleteAbout}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white border-none"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentAbout(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutAdmin;

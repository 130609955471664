import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HasPermission from "../UAM/HasPermission";
import { useSelector } from "react-redux";

const Quotes = () => {
  // Modal states
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isAddLoading,setIsAddLoading]=useState(true)
  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Quotes data
  const [quotes, setQuotes] = useState([]);

  // Form data for Add and Update
  const [formData, setFormData] = useState({
    quotes: "",
    name: "",
  });

  // Current quote being viewed, updated, or deleted
  const [currentQuote, setCurrentQuote] = useState(null);

  // Permissions from Redux store
  const action_ids = useSelector((state) => state.login.action_ids);



  // Fetch quotes on component mount
  useEffect(() => {
    fetchQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch quotes with proper loading state management
  const fetchQuotes = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/quotes`);
      setQuotes(response.data);
    } catch (error) {
      console.error("Error fetching quotes:", error);
      toast.error("Failed to fetch quotes.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle adding a new quote
  const handleAddQuote = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during add operation
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertquote`, formData);
      setFormData({ quotes: "", name: "" });
      toast.success("Quote added successfully!");
      setIsAddOpen(false);
      fetchQuotes();
    } catch (error) {
      console.error("Error adding quote:", error);
      toast.error("Failed to add quote.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after add operation
      setIsAddLoading(true)
    }
  };

  // Handle viewing a quote
  const handleView = (quote) => {
    setCurrentQuote(quote);
    setIsViewOpen(true);
  };

  // Open update modal with selected quote data
  const openUpdateModal = (quote) => {
    setCurrentQuote(quote);
    setFormData({
      quotes: quote.quotes,
      name: quote.name,
    });
    setIsUpdateOpen(true);
  };

  // Handle updating a quote
  const handleUpdateQuote = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during update operation
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/updatequotes/${currentQuote.id}`, formData);
      toast.success("Quote updated successfully!");
      setIsUpdateOpen(false);
      setCurrentQuote(null);
      fetchQuotes();
    } catch (error) {
      console.error("Error updating quote:", error);
      toast.error("Failed to update quote.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after update operation
    }
  };

  // Open delete confirmation modal with selected quote
  const openDeleteModal = (quote) => {
    setCurrentQuote(quote);
    setIsDeleteOpen(true);
  };


  const handleDeleteQuote = async () => {
    setIsLoading(true); // Optional: Show loading during delete operation
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deletequotes/${currentQuote.id}`);
      toast.success("Quote deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentQuote(null);
      fetchQuotes();
    } catch (error) {
      console.error("Error deleting quote:", error);
      toast.error("Failed to delete quote.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after delete operation
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />

      {/* Header Section */}
      <div className="mb-4 flex justify-between pb-8">
        <p className="font-bold text-black text-5xl">Quotes</p>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"}>
          {
            isAddOpen ? "" :
              <button
                onClick={() => setIsAddOpen(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none hover:text-white"
              >
                Add Quote
              </button>
          }
        </HasPermission>
      </div>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {/* Add Quote Modal */}
      {isAddOpen && (
        <div className="mb-6 p-4 border rounded shadow">
          {/* <div className="bg-white p-6 rounded shadow-lg w-full max-w-md"> */}
          <h2 className="text-xl mb-4">Add New Quote</h2>
          <form onSubmit={handleAddQuote}>
            <label className="block mb-2">Enter Author Name <span class="text-red-500">*</span></label>
            <input
              type="text"
              name="name"
              placeholder="Author Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="border p-2 mb-4 w-full"
            />
            <label className="block mb-2">Enter Quote <span class="text-red-500">*</span></label>
            <textarea
              name="quotes"
              placeholder="Quote"
              value={formData.quotes}
              onChange={handleChange}
              required
              className="border p-2 mb-4 w-full"
              rows="4"
            ></textarea>
            <div className="flex justify-start space-x-2">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAddOpen(false)
                  setFormData({
                    quotes: "",
                    name: "",
                  })
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
              >
                Cancel
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>
      )}

      {/* Quotes Table */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="border">Author Name</th>
                <th className="border">Quote</th>
                <th className="border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {quotes.length > 0 ? (
                // Clone the quotes array and reverse it before mapping
                [...quotes]
                  .reverse()
                  .map((quote, index) => (
                    <tr key={quote.id} className="text-center hover:bg-gray-100">
                      <td className="py-2 px-4 border">{index + 1}</td>
                      <td className="py-2 px-4 border">{quote.name}</td>
                      <td className="py-2 px-4 border">{quote.quotes}</td>
                      <td className="py-2 px-4 border space-x-2 w-64">
                        <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => handleView(quote)}
                            className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 hover:text-black border-none"
                          >
                            View
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openUpdateModal(quote)}
                            className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 hover:text-white border-none"
                          >
                            Update
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openDeleteModal(quote)}
                            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 hover:text-white border-none"
                          >
                            Delete
                          </button>
                        </HasPermission>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 text-center">
                    No quotes found.
                  </td>
                </tr>
              )}
            </tbody>

          </table>
        </div>
      )}



      {/* View Quote Modal */}
      {isViewOpen && currentQuote && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Quote Details</h2>
            <p className="py-2">
              <strong>Author Name:</strong> {currentQuote.name}
            </p>
            <p className="py-2 pb-4">
              <strong>Quote:</strong> {currentQuote.quotes}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Quote Modal */}
      {isUpdateOpen && currentQuote && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Update Quote</h2>
            <form onSubmit={handleUpdateQuote}>
              <label className="block mb-2">Author Name<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="name"
                placeholder="Author Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="border p-2 mb-4 w-full"
              />
              <label className="block mb-2">Quote <span class="text-red-500">*</span></label>
              <textarea
                name="quotes"
                placeholder="Quote"
                value={formData.quotes}
                onChange={handleChange}
                required
                className="border p-2 mb-4 w-full"
                rows="4"
              ></textarea>
              <div className="flex justify-start space-x-2">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 border-none hover:text-white "
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentQuote(null);
                    setFormData({
                      quotes: "",
                      name: "",
                    })
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white "
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && currentQuote && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the quote "<strong>{currentQuote.quotes}</strong>" by{" "}
              <strong>{currentQuote.name}</strong>?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeleteQuote}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 border-none hover:text-white"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentQuote(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quotes;

import { useEffect, useState, useRef } from "react";
import Table from "../../../components/table/Table";
import { useNavigate } from "react-router-dom";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Toast } from "primereact/toast";
import { protectedCall } from "../../../services/userService";

export default function Roles() {
  const [row, setRow] = useState({});
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleMapping, setRoleMapping] = useState({});
  const toast = useRef(null);
  const tableName = "Role Permissions";

  useEffect(() => {
    const fetchRoles = async () => {
      const rolesResponse = await protectedCall("api/roles");
      const newRoles = await protectedCall("api/roles");
      // Create a mapping of role_id to role_name
      const mapping = {};
      rolesResponse.forEach(role => {
        mapping[role.id] = role.name; // Assuming the response has an id and name
      });

      setRoleMapping(mapping);
      setRoles(
        newRoles.map((elem, index) => {
          return {
            "S No": index + 1,
            id: elem.id,
            Role_Name: elem.name,
            //  Role: mapping[elem.role_id] || "Unknown Role", 
            Status: elem.activeFlag ? "Active" : "Deactivated" // Correctly display status using activeFlag
          };
        })
      );
    };
    fetchRoles();
  }, []);

  const actions = [
    {
      label: "Edit",
      icon: "pi pi-pen-to-square",
      command: () => {
        navigate("/admin/edit", {
          state: { id: row?.id, roleName: row?.Role_Name, action: "edit" },
        });
      },
    },
    {
      label: "View",
      icon: "pi pi-eye",
      command: () => {
        navigate("/admin/view", {
          state: { id: row?.id, roleName: row?.Role_Name, action: "view" },
        });
      },
    },
    {
      label: "Active/Deactivate",
      icon: "pi pi-refresh", // Changed to a refresh icon for clarity
      command: async () => {
        try {
          console.log("Toggle role active status for ID:", row.id);

          // Determine the new active status based on the current status
          const newActiveFlagStatus = !row.activeFlag;

          // Call the API to toggle the role's active status
          const response = await protectedCall(
            `/api/roles/activeStatus/role`,
            {
              id: parseInt(row.id, 10),
              action: newActiveFlagStatus ? "enable" : "disable",
            },
            "post"
          );

          console.log("API response:", response);



          // Update the state with the new status
          setRoles((prevRoles) =>
            prevRoles.map((role) =>
              role.id === row.id ? {
                ...role,
                activeFlag: newActiveFlagStatus,
                Status: newActiveFlagStatus ? "Active" : "Deactivate" // Update status accordingly
              } : role
            )
          );

          showInfo(`Role ${newActiveFlagStatus ? 'Activated' : 'Deactivated'} Successfully`);
          setMenuOpen(false);
        } catch (e) {
          console.error("Error toggling role active status:", e.message);
          showError("Failed to toggle role status");
        }
      },
    },
  ];

  const showError = (errorMsg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMsg,
      life: 3000,
    });
  };

  const showInfo = (info) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: info,
      life: 3000,
    });
  };

  const head = Object.keys(
    roles.length ? { "S No": "S No", ...roles[0], action: "action" } : {}
  ).filter(column => column !== "activeFlag" && column !== "id"); // Exclude activeFlag from the header

  return (
    <>
      <Toast ref={toast} />
      <Table
        products={roles}
        setProducts={setRoles}
        addBtnName={"Add Role"}
        tableName={tableName}
        size="large"
        actions={actions}
        head={head}
        addBtnAction={() => {
          navigate("/admin/addrole");
        }}
        setRow={setRow}
        setMenuOpen={setMenuOpen}
        isMenuOpen={isMenuOpen}
      />
    </>
  );
}

import { Outlet } from "react-router-dom";
import BreadCrumbs from "../reuseable/BreadCrumbs";
import { useEffect, useState } from "react";
import axios from "axios";

const Content = () => {
  const [faqNavBar, setFaqNavBar] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState("School"); // Default to "schools"
  const [openQuestion, setOpenQuestion] = useState(null); // To track which question is open

  useEffect(() => {
    const fetchFaqType = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/faq`);
        const data = response.data;
        // console.log(data);
        setFaqNavBar(data);

       
        if (data.school && data.school.length > 0) {
          setOpenQuestion(0);
        }
      } catch (error) {
        console.log("Error while fetching the FAQ type", error);
        setError("Failed to fetch FAQ types.");
      } finally {
        setLoading(false);
      }
    };
    fetchFaqType();
  }, []);

  const handleFaqTypeClick = (faqType) => {
    setSelectedType(faqType);
    setOpenQuestion(null); 
  };
  const handleQuestionClick = (index) => {
    setOpenQuestion(openQuestion === index ? null : index); // Toggle between open and closed
  };

  return (
    <>
      <div className="md:ml-0 md:pt-0">
        <BreadCrumbs />
      </div>
      <div className="p-5 HeroBg">
        <span className="flex justify-center items-center text-5xl font-extrabold mb-10 text-blue-700">
          FAQ
        </span>
        <div className="flex flex-col items-center">
          {loading ? (
            <div>Loading FAQs...</div>
          ) : error ? (
            <div>{error}</div>
          ) : Object.keys(faqNavBar).length === 0 ? (
            <div>No FAQs available</div>
          ) : (
            <>
              {/* Display FAQ types as clickable buttons */}
              <div className="mb-4 flex flex-wrap justify-center gap-4">
                {Object.keys(faqNavBar).map((faqType, index) => (
                  <button
                    key={index}
                    onClick={() => handleFaqTypeClick(faqType)}
                    className={`md:p-2 p-1 lg:px-4 lg:text-2xl md:text-xl text-base font-bold rounded-lg cursor-pointer border-none hover:text-pink-500 ${selectedType === faqType ? " text-pink-500 bg-transparent" : "text-black bg-transparent"
                      }`}
                  >
                    {faqType}
                  </button>
                ))}
              </div>

              {/* Display the selected FAQ type's questions and answers */}
              {selectedType && (
                <div className="p-4 w-full max-w-3xl rounded-lg text-center md:text-xl text-md lg:text-2xl font-bold mb-4">
                  <h1 className="text-white bg-pink-500 p-3  rounded-md   mb-4">For {selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}</h1>
                  <ul className="text-left">
                    {faqNavBar[selectedType].map((faq, i) => (
                      <li key={i} className="mb-4">
                        <div className="rounded-md shadow-md ">
                        <div
                          onClick={() => handleQuestionClick(i)}
                          className="font-semibold text-lg md:text-lg lg:text-xl cursor-pointer flex justify-between items-center p-3 "
                        >
                          {faq.questions}
                          <span
                            className={`transform transition-transform duration-300 text-pink-500 text-3xl ${openQuestion === i ? "rotate-45" : "rotate-0"
                              }`}
                          >
                            +
                          </span>
                         
                        </div>
                        <div>
                            {openQuestion === i && (
                              <div className="mt-2 text-black lg:text-lg text-base   p-3 font-light ">
                                {faq.answer}
                              </div>
                            )}
                          </div>
                        </div>


                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
        {/* <Outlet /> */}
      </div>
    </>
  );
};

export default Content;

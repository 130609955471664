import React, { useEffect, useState } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import user from "../../images/blog/blogHero/girl.png";
const apiUrl = process.env.API_URL_NAME;

const Carousel = () => {
  const [carouselContent, setCarouselContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/testimonials_type/isms page`
        );
        const activeTestimonials = response.data;
        setCarouselContent(activeTestimonials);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Navigate to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselContent.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Navigate to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselContent.length - 1 : prevIndex - 1
    );
  };

  const hasPrevSlide = currentIndex > 0;
  const hasNextSlide =
    carouselContent.length > 1 && currentIndex < carouselContent.length - 1;


  return (
    <div className="lg:max-w-full">
      <p className="text-4xl p-5 text-center flex justify-center mt-24 mb-10 md:text-5xl lg:text-6xl font-black text-blue-600">
        Voices of our community
      </p>
      <div className="flex justify-center  lg:gap-20">
        <button className="bg-transparent border-none" onClick={prevSlide}>
          {hasPrevSlide ? (
            <ArrowLeftIcon className="w-5 text-gray-600" />
          ) : (
            <ArrowLeftIcon className="w-5 text-transparent" />
          )}
        </button>
        {carouselContent.map((item, index) => {
          const imgSrc = item.image && item.image.data
            ? `data:image/jpeg;base64,${btoa(
              new Uint8Array(item.image.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            )}`
            : user;
          return (
            <div
              key={index}
              className={`max-w-lg ${index === currentIndex ? "" : "hidden"
                } transition-opacity duration-500 flex flex-col items-center h-[100%]   bg-pink-600 shadow-2xl lg:p-10 rounded-3xl`}
            >
              <div className="lg:h-[300px] md:p-2 py-10 flex flex-col justify-center items-center ">
                <h1 className="text-center text-white md:text-base align-middle p-5">
                  {item.content || "Error on fetching data..."}
                </h1>
              </div>
              <p className="text-center text-white font-semibold md:text-lg p-5 flex">
                {/* <UserCircleIcon className="w-6 text-white " /> */}
                <img src={imgSrc} className="md:w-7 w-12" alt="user" /> -
                {item.name || "Error on fetching data..."}
              </p>
            </div>
          )
        })}
        <button className="bg-transparent border-none" onClick={nextSlide}>
          {hasNextSlide ? (
            <ArrowRightIcon className="w-5 text-gray-600" />
          ) : (
            <ArrowRightIcon className="w-5 text-transparent" />
          )}
        </button>
      </div>
      <div className="flex justify-center mt-8">
        {carouselContent.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-2 w-2 mx-4 border-none rounded-full ${index === currentIndex
                ? "bg-gray-700 border"
                : "bg-gray-400 hover:bg-gray-400"
              }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

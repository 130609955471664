import React, { useEffect, useRef } from "react";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import ParentFormHero from "../../../components/forms/parent/ParentFormHero";
import ParentForm from "../../../components/forms/parent/ParentForm";
import Test from "../../../components/forms/parent/Test";
import ScrollEvent from "../../../components/forms/parent/ScrollEvent";

const ParentPageForm = () => {
  // Create a ref for the ParentForm component
  const formRef = useRef(null);

  useEffect(() => {
    // Scroll to the ParentForm component when the component mounts
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <ParentFormHero />
      {/* <Test /> */}
      <ScrollEvent />
      {/* Attach the ref to a wrapper element around ParentForm */}
      <div ref={formRef}>
        <ParentForm />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ParentPageForm;

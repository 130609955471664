
import React, { useEffect, useState } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useSelector } from 'react-redux'
import { selectLoggedUser } from "../../pages/login/loginSlice";
import { protectedCall } from "../../services/userService"

const SideBar = () => {
  const loggedUser = useSelector(select => select.login);




  function convertModulesToSidebarFormat(modules) {
    return modules.flatMap(module =>
      module.screens.map(screen => ({
        href: `/#${screen.screenRoute}`,
        title: screen.screenName,
        svg: (
          <svg
            className="text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 21"
          >
            <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
            <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
          </svg>
        ),
      }))
    );
  }

  const [originalData, setOriginalData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const newData = await protectedCall(`/api/menu-creator/${loggedUser?.roleId}`);
        
        setOriginalData(newData);
      } catch (error) {
        console.error("Error fetching sidebar data", error);
      }
    };
    fetchData();
  }, [loggedUser?.roleId]);


  const sideBarContent = [...convertModulesToSidebarFormat(originalData), {
    href: "#/logoutpage",
    title: "Log out",
    svg: (
      <svg
        class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 16"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
        />
      </svg>
    ),
  },
  ];

  // const sideBarContent = [
  //   ["Super Admin"]?.includes(loggedUser?.role) &&  
  //   {
  //     title: "Roles",
  //     href: "#/admin/roles",
  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "Users",
  //     href: "#/admin/user",

  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "Quote",
  //     href: "#/admin/",

  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "Events ",
  //     href: "#/admin/events",

  //     svg: (
  //       <svg
  //         class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 20 20"
  //       >
  //         <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "Event Poster",
  //     href: "#/admin/poster",

  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 

  //   {
  //     title: "Champion",
  //     href: "#/admin/champion",

  //     svg: (
  //       <svg
  //       class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //       aria-hidden="true"
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="currentColor"
  //       viewBox="0 0 20 20"
  //     >
  //       <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
  //     </svg>
  //     ),
  //   },

  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     href: "#/admin/testimonials",

  //     title: "Testimonials",
  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "About us",
  //     href: "#/admin/about",

  //     svg: (
  //       <svg
  //         class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 20 20"
  //       >
  //         <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "FAQ",
  //     href: "#/admin/faq",

  //     svg: (
  //       <svg
  //         class="  text-gray-500 w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 22 21"
  //       >
  //         <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
  //         <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     title: "Gallery",
  //     href: "#/admin/gallery",

  //     svg: (
  //        <svg
  //         class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="currentColor"
  //         viewBox="0 0 20 20"
  //       >
  //         <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
  //       </svg>
  //     ),
  //   },
  //   ["Super Admin","Admin","Staff"]?.includes(loggedUser?.role) && 
  //   {
  //     href: "#/logoutpage",
  //     title: "Log out",
  //     svg: (
  //       <svg
  //         class="flex-shrink-0 w-6 h-6  text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
  //         aria-hidden="true"
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 18 16"
  //       >
  //         <path
  //           stroke="currentColor"
  //           stroke-linecap="round"
  //           stroke-linejoin="round"
  //           stroke-width="2"
  //           d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
  //         />
  //       </svg>
  //     ),
  //   },

  // ].filter(Boolean);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`fixed top-18 left-0 h-full px-3 py-4 bg-gray-100 dark:bg-gray-800 ${isExpanded ? "w-full" : "w-16"
        } sm:w-16 md:w-64 transition-all duration-300`}
    >
      <button
        onClick={toggleSidebar}
        className="mb-4 flex justify-center items-center text-gray-900 dark:text-white sm:flex md:hidden"
      >
        {isExpanded ? (
          <ChevronLeftIcon className="h-6 w-6 bg-white" /> // Icon to collapse
        ) : (
          <ChevronRightIcon className="h-6 w-6 bg-white" /> // Icon to expand
        )}
      </button>

      <ul className="space-y-2 font-medium">
        {sideBarContent.map((item, index) => (
          <li key={index}>
            <a
              href={item.href}
              className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <span className="text-lg">{item.svg}</span>
              <span
                className={`ml-3 ${isExpanded ? "inline" : "hidden"
                  } md:inline transition-all duration-300`}
              >
                {item.title}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;

// src/components/Quote.jsx

import axios from "axios";
import React, { useEffect, useState } from "react";

const Quote = () => {
  // State variables to manage quotes, loading, and error states
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [error, setError] = useState(null);     // Tracks error state

  useEffect(() => {
    // Define an asynchronous function to fetch quotes
    const fetchQuotes = async () => {
      try {
        

        // Make the GET request to fetch quotes
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/quotes`);

       

        // Check if the response data is an array
        if (Array.isArray(response.data)) {
          setQuotes(response.data);
        } else if (response.data.quotes && Array.isArray(response.data.quotes)) {
          // If quotes are nested inside a 'quotes' property
          setQuotes(response.data.quotes);
        } else {
          // Handle unexpected data structures
          console.warn("Unexpected data structure:", response.data);
          setError("Received unexpected data format from the server.");
        }

        // Update loading state
        setLoading(false);
      } catch (err) {
        // Log the error for debugging
        console.error("Error fetching quotes:", err);

        // Update error state with a user-friendly message
        setError("Failed to fetch quotes. Please try again later.");

        // Update loading state
        setLoading(false);
      }
    };

    // Invoke the fetch function
    fetchQuotes();
  }, []); // Empty dependency array ensures this runs once on mount

  // Render loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Loading quotes...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 text-xl mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  // Render quotes
  return (
    <div className="p-5 lg:py-[10%]">
      {quotes.length > 0 ? (
        quotes.map((quote, index) => (
          <div
            key={quote.id || index} // Use a unique key; fallback to index if id is unavailable
            className="blockquoteblack text-pink-600 p-5 rounded-3xl bg-white lg:mx-24 lg:p-8 lg:rounded-3xl lg:font-semibold mb-6"
          >
            {/* SVG Icon */}
            <svg
              className="w-10 h-10 mx-auto mb-3 text-pink-600" // Corrected 'class' to 'className'
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 14"
            >
              <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
            </svg>

            {/* Quote Content */}
            <p className="text-center italic text-base font-bold pb-2 md:text-xl md:pb-3 lg:text-2xl">
              {quote.quotes}
              {/* Example: "Inclusion is not a strategy to help people fit into the systems and
              structures that exist in our societies, it is about transforming
              those systems and structures to make it better for everyone." */}
            </p>

            {/* Quote Author */}
            <h1 className="text-end mt-auto font-semibold text-pink-600 lg:font-bold lg:text-lg">
              - {quote.name}
              {/* Example: - DIANE Rechard */}
            </h1>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">No quotes available.</p>
      )}
    </div>
  );
};

export default Quote;

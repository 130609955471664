import axios from "axios";
import { useEffect, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useNavigate } from "react-router-dom";

const Cookiespages = () => {
  const [ip, setIp] = useState("");
  const navigate = useNavigate();

  // Function to fetch the IP address
  const fetchIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setIp(response.data.ip);
    } catch (error) {
      console.error("Error fetching the IP address:", error);
    }
  };

  useEffect(() => {
    document.cookie = `ip=${ip}`;
  }, [ip]);

  useEffect(() => {
    // Check if cookies are already accepted
    if (Cookies.get("CookieConsent") === "true") {
      fetchIP(); // Fetch IP if consent was already given
    }
  }, []);

  return (
    <div className="relative">
      <CookieConsent
        style={{
          backgroundColor: "#f9a8d4",
          color: "black",
          height: "auto",
          padding: "1rem",
        }}
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="CookieConsent"
        enableDeclineButton
        onAccept={() => {
          fetchIP();
        }}
        onDecline={() => {
          setIp("");
        }}
        buttonStyle={{
          backgroundColor: "#4CAF50", // Green accept button
          color: "white",
          fontSize: "13px",
          padding: "0.5rem 1rem",
        }}
        declineButtonStyle={{
          backgroundColor: "#f44336", // Red decline button
          color: "white",
          fontSize: "13px",
          padding: "0.5rem 1rem",
        }}
        buttonClassName="py-2 px-4 text-white text-xs font-medium rounded"
        declineButtonClassName="py-2 px-4 text-white text-xs font-medium rounded"
      >
        This website uses cookies to improve your browsing experience. By
        continuing, you agree to our use of cookies. For more information,
        please review our{" "}
        <span
          className="underline cursor-pointer"
          onClick={() => navigate("/privacy")}
        >
          Privacy Policy
        </span>
        .
      </CookieConsent>
    </div>
  );
};

export default Cookiespages;

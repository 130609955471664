import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'primeicons/primeicons.css';
const GalleryEvents = () => {
    const [eventType, setEventType] = useState([]); 
    const [galleryData, setGalleryData] = useState({}); 
    const [selectedType, setSelectedType] = useState("");
    const [currentPage, setCurrentPage] = useState(1); 
    const imagesPerPage = 9; 
    const [isLoading,setIsLoading]=useState(false)
    useEffect(() => {
        const fetchGallery = async () => {
            setIsLoading(true)
            try {
                
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery`);
                const data = response.data;
                const eventTypes = Object.keys(data);
                setEventType(eventTypes);
                setGalleryData(data); 
                if (eventTypes.length > 0) {
                    setSelectedType(eventTypes[0]);
                }
            } catch (error) {
                console.log("Gallery fetch error", error);
            } finally{
                setIsLoading(false)
            }
        };
        fetchGallery();
    }, []);

    const handleGalleryType = (galleryType) => {
        setSelectedType(galleryType); 
        setCurrentPage(1); 
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const displayedImages = galleryData[selectedType]?.slice(0, currentPage * imagesPerPage) || [];

    return (
        <div className="pt-20 text-center">
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-indigo-600">
                Wall Of Love
            </h1>
            
            {isLoading ? (
                <div className="flex items-center justify-center h-48">
                    <i className="pi pi-spin pi-spinner text-indigo-600 text-4xl"></i>
                    <h1 className="ml-4 text-indigo-600 text-lg">Loading...</h1>
                </div>
            ) : (
                <>
                    <div className={`flex flex-wrap justify-center gap-4 mt-12 mb-8 ${eventType.length > 6 ? 'overflow-y-scroll h-48' : ''}`}>
                        {eventType.map((item, index) => (
                            <button
                                key={index}
                                onClick={() => handleGalleryType(item)}
                                className={`md:px-4 px-2 md:py-2 y-1 text-base lg:text-xl font-bold border-none rounded-lg transition-colors duration-300 ${
                                    selectedType === item ? 'bg-transparent text-indigo-600 ' : 'bg-transparent text-black '
                                }`}
                            >
                                {item}
                            </button>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-6 sm:mx-12 lg:mx-40">
                        {displayedImages.map((item, index) => {
                            const imageUrl = `${process.env.REACT_APP_SERVICES_IMG_URL}/${item.image}`;
                            return (
                                <div key={index} className="flex justify-center p-2 md:p-4 sm:px-4">
                                    <img
                                        src={imageUrl}
                                        alt={item.name}
                                        className="w-64 h-48 sm:w-64 sm:h-48 md:w-96 md:h-56 lg:w-full lg:h-72 object-cover rounded-2xl shadow-lg transition-transform duration-300 hover:scale-105"
                                        onError={(e) => {
                                            e.currentTarget.src = 'path/to/placeholder/image.jpg';
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    {galleryData[selectedType]?.length > displayedImages.length && (
                        <div className="flex justify-center mt-6 md:mb-16 mb-10">
                            <button
                                onClick={handleNextPage}
                                className="px-6 py-2 bg-indigo-600 text-white rounded-lg font-bold transition-transform duration-300 hover:scale-105 border-none"
                            >
                                More
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default GalleryEvents;

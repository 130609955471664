import React from "react";
import hero from "../../images/school/schoolCompressed/schoolHero-min.jpg";

const SchoolActualHero = () => {
  return (
    <>
      <div className="HeroBg relative ">
        <div className=" flex flex-col  lg:flex-row mx:[4%] lg:mx-[6%]   md:pt-[3%]  lg:pb-10 ">
          <div className=" flex flex-col justify-center text-center lg:text-start md:text-center gap-14 md:gap-6 my-6 mx-10 mt-7 md:mx-24 md:my-10  lg:pr-10 lg:m-0">
            <h1 className=" text-5xl font-black text-blue-700 md:text-4xl lg:text-6xl">
              Together for every child
              <br />
              <span className="text-3xl  text-pink-600 md:text-6xl lg:text-4xl">
                Creating inclusive learning environments for all
              </span>
            </h1>
            <p className="  md:pb-0  text-xl text-center lg:text-start">
              Empowering every student to thrive through equitable and inclusive
              education.
            </p>
            {/* <a href="/#/school-form">
              <button className="bg-pink-600 lg:px-12 border-pink-600 text-white hover:bg-transparent hover:text-pink-600 lg:text-2xl hover:border-pink-600 px-10 py-2 lg:py-3 text-xl rounded-xl">
                Get involved
              </button>
            </a> */}
          </div>
          <>
            <img
              className="m-10 lg:m-0 rounded-3xl object-cover lg:block lg:w-[45%]"
              src={hero}
              alt="Hero Bg"
            />
          </>
        </div>
      </div>
    </>
  );
};
export default SchoolActualHero;


// import React from "react";
// import BreadCrumbs from "../reuseable/BreadCrumbs";

// const SchoolActualHero = () => {
//   return (
//     <>
//       <BreadCrumbs />
//       <div className=" schoolBgHero h-screen ">
//         <div className=" lg:bg-black-bg1 bg-black-bg px-[0%] h-screen lg:px-[5%] py-[40%] lg:py-[9%]">
//           <div className="flex flex-col justify-center items-center gap-20">
//             <div className="lg:bg-black-bg lg:p-10 rounded-3xl">
//               <p className="text-white text-4xl md:text-6xl font-black text-center">
//                 Together for every child <br />
//                 <span className="text-4xl md:text-5xl lg:text-6xl">
//                   "Creating inclusive learning environments for all"
//                 </span>
//               </p>
//               <p className="text-xl text-center font-bold text-white">
//                 Empowering every student to thrive through equitable and
//                 inclusive education.
//               </p>
//             </div>
//             <div className="flex gap-5">
//               <a href="/#/school-form">
//                 <button className="bg-pink-600 border-pink-600 text-white hover:bg-transparent hover:text-white lg:text-2xl hover:border-white p-2 lg:p-4 text-xl rounded-xl">
//                   Get involved
//                 </button>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SchoolActualHero;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";

const Champion = () => {
  // Modal state variables
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isAddLoading,setIsAddLoading]=useState(true)
  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Data state variables
  const [champions, setChampions] = useState([]);
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    role: "",
    award_name: "",
    award_description: "",
    other_details: "",
  });

  const [currentChampion, setCurrentChampion] = useState(null);

  const action_ids = useSelector(state => state.login.action_ids)

  const API_BASE = process.env.REACT_APP_BASE_URL || "http://192.168.1.31:3001/api";

  useEffect(() => {
    fetchChampions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch all champions with proper loading state management
  const fetchChampions = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${API_BASE}/champions`);
      setChampions(response.data);
    } catch (error) {
      console.error("Error fetching champions:", error);
      toast.error("Failed to fetch champions.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  // Add a new champion
  const handleAddChampion = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true); // Start loading
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      await axios.post(`${API_BASE}/insertChampions`, formDataToSend);
      toast.success("Champion added successfully!");
      setIsAddOpen(false);
      setFormData({
        image: null,
        name: "",
        role: "",
        award_name: "",
        award_description: "",
        other_details: "",
      });
      fetchChampions();
    } catch (error) {
      console.error("Error adding champion:", error);
      toast.error("Failed to add champion.");
    } finally {
      setIsLoading(false); // Stop loading
      setIsAddLoading(true)
    }
  };

  // View champion details
  const handleView = (champion) => {
    setCurrentChampion(champion);
    setIsViewOpen(true);
  };

  // Open update modal with existing champion data
  const openUpdateModal = (champion) => {
    setCurrentChampion(champion);
    setFormData({
      image: null, // Reset to null; existing image will be handled separately
      name: champion.name || "",
      role: champion.role || "",
      award_name: champion.award_name || "",
      award_description: champion.award_description || "",
      other_details: champion.other_details || "",
    });
    setIsUpdateOpen(true);
  };

  // Convert buffer to base64 string efficiently
  const bufferToBase64 = (buffer) => {
    let binary = '';
    const len = buffer.length;
    const chunkSize = 0x8000; // 32768

    for (let i = 0; i < len; i += chunkSize) {
      const chunk = buffer.subarray(i, i + chunkSize);
      binary += String.fromCharCode.apply(null, chunk);
    }

    return `data:image/png;base64,${btoa(binary)}`;
  };

  // Convert base64 string back to Blob
  const base64ToBlob = (base64String) => {
    const [header, base64] = base64String.split(',');
    const mime = header.match(/:(.*?);/)[1];

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  // Update an existing champion
  const handleUpdateChampion = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("role", formData.role);
    formDataToSend.append("award_name", formData.award_name);
    formDataToSend.append("award_description", formData.award_description);
    formDataToSend.append("other_details", formData.other_details);

    if (formData.image) {
      // If a new image is uploaded, append it
      formDataToSend.append("image", formData.image);
    } else {
      // If no new image is uploaded, retain the existing image
      if (currentChampion.image && currentChampion.image.data) {
        const existingImageBlob = base64ToBlob(
          bufferToBase64(new Uint8Array(currentChampion.image.data))
        );
        formDataToSend.append("image", existingImageBlob, "existingImage.png"); // Provide a filename
      }
    }

    try {
      await axios.put(`${API_BASE}/updateChampions/${currentChampion.id}`, formDataToSend);
      toast.success("Champion updated successfully!");
      setIsUpdateOpen(false);
      setCurrentChampion(null);
      fetchChampions();
    } catch (error) {
      console.error("Error updating champion:", error);
      toast.error("Failed to update champion.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Open delete confirmation modal
  const openDeleteModal = (champion) => {
    setCurrentChampion(champion);
    setIsDeleteOpen(true);
  };

  // Delete a champion
  const handleDeleteChampion = async () => {
    setIsLoading(true); // Start loading
    try {
      await axios.put(`${API_BASE}/deleteChampions/${currentChampion.id}`);
      toast.success("Champion deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentChampion(null);
      fetchChampions();
    } catch (error) {
      console.error("Error deleting champion:", error);
      toast.error("Failed to delete champion.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />

      {/* Header Section */}
      <div className="mb-4 flex justify-between pb-8">
        <p className="font-bold text-black text-5xl">Champion</p>
        <div>
          <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"}>
            {
              isAddOpen ? "" :
                <button
                  onClick={() => setIsAddOpen(true)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none hover:text-white "
                >
                  Add Champion
                </button>
            }
          </HasPermission>
        </div>
      </div>

      {/* Add Champion Modal */}
      {isAddOpen && !isLoading && (
        <div className="mb-6 p-4 border rounded shadow">
          {/* <div className="bg-white p-6 rounded shadow-lg w-full max-w-md overflow-y-auto"> */}
          <h2 className="text-xl mb-4">Add New Champion</h2>
          <form onSubmit={handleAddChampion}>
            <div className="mb-4">
              <label className="block mb-1">Image<span class="text-red-500">*</span></label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleChange}
                required
                className="border p-2 w-full"
              />
              <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
            </div>
            <div className="mb-4">
              <label className="block mb-1">Name<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Role<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="role"
                placeholder="Role"
                value={formData.role}
                onChange={handleChange}
                required
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Award Name<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="award_name"
                placeholder="Award Name"
                value={formData.award_name}
                onChange={handleChange}
                required
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Award Description<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="award_description"
                placeholder="Award Description"
                value={formData.award_description}
                onChange={handleChange}
                required
                className="border p-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">Other Details</label>
              <input
                type="text"
                name="other_details"
                placeholder="Other Details"
                value={formData.other_details}
                onChange={handleChange}
                className="border p-2 w-full"
              />
            </div>
            <div className="flex space-x-2 justify-start">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => 
                {
                  setIsAddOpen(false)
                  setFormData({
                    image: null,
                    name: "",
                    role: "",
                    award_name: "",
                    award_description: "",
                    other_details: "",
                  })
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
              >
                Cancel
              </button>
            </div>
          </form>
          {/* </div> */}
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}



      {/* Champions Table */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="py-2 px-4 border">Image</th>
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Role</th>
                <th className="py-2 px-4 border">Award Name</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>

              {champions.length > 0 ? (
                [...champions]
                  .reverse()
                  .map((champion, index) => (
                    <tr key={champion.id} className="text-center hover:bg-gray-100">
                      <td className="py-2 px-4 border">{index + 1}</td>
                      <td className="py-2 px-4 border flex justify-center">
                        {champion.image && champion.image.data ? (
                          <img
                            src={bufferToBase64(new Uint8Array(champion.image.data))}
                            alt={champion.name}
                            className="w-16 h-16 object-cover rounded-full"
                          />
                        ) : (
                          "No Image"
                        )}
                      </td>
                      <td className="py-2 px-4 border">{champion.name}</td>
                      <td className="py-2 px-4 border">{champion.role}</td>
                      <td className="py-2 px-4 border">{champion.award_name}</td>
                      <td className="py-2 px-4 border space-x-2 lg:w-64">
                        <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => handleView(champion)}
                            className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 border-none hover:text-black"
                          >
                            View
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openUpdateModal(champion)}
                            className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 border-none hover:text-white"
                          >
                            Update
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openDeleteModal(champion)}
                            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 border-none hover:text-white"
                          >
                            Delete
                          </button>
                        </HasPermission>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 text-center">
                    No champions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* View Modal */}
      {isViewOpen && currentChampion && !isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md overflow-y-auto">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Champion Details</h2>
            {currentChampion.image && currentChampion.image.data ? (
              <img
                src={bufferToBase64(new Uint8Array(currentChampion.image.data))}
                alt={currentChampion.name}
                className="w-32 h-32 object-cover rounded-full mb-4"
              />
            ) : (
              "No Image"
            )}
            <p>
              <strong>Name:</strong> {currentChampion.name || "N/A"}
            </p>
            <p>
              <strong>Role:</strong> {currentChampion.role || "N/A"}
            </p>
            <p>
              <strong>Award Name:</strong> {currentChampion.award_name || "N/A"}
            </p>
            <p>
              <strong>Award Description:</strong> {currentChampion.award_description || "N/A"}
            </p>
            <p>
              <strong>Other Details:</strong> {currentChampion.other_details || "N/A"}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {isUpdateOpen && currentChampion && !isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4 h-4/5 overflow-y-scroll">
            <h2 className="text-xl mb-4">Update Champion</h2>
            <form onSubmit={handleUpdateChampion}>
              <div className="mb-4">
                <label className="block mb-1">Current Image</label>
                {currentChampion.image && currentChampion.image.data ? (
                  <img
                    src={bufferToBase64(new Uint8Array(currentChampion.image.data))}
                    alt={currentChampion.name}
                    className="w-32 h-32 object-cover rounded-full mb-2"
                  />
                ) : (
                  "No Image"
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-1">Upload New Image (optional) <span class="text-red-500">*</span><br/> <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small></label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleChange}
                  className="border p-2 w-full"
                />
                <small>If you don't upload a new image, the existing image will be retained.</small>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Name<span class="text-red-500">*</span></label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Role<span class="text-red-500">*</span></label>
                <input
                  type="text"
                  name="role"
                  placeholder="Role"
                  value={formData.role}
                  onChange={handleChange}
                  required
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Award Name<span class="text-red-500">*</span></label>
                <input
                  type="text"
                  name="award_name"
                  placeholder="Award Name"
                  value={formData.award_name}
                  onChange={handleChange}
                  required
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Award Description<span class="text-red-500">*</span></label>
                <input
                  type="text"
                  name="award_description"
                  placeholder="Award Description"
                  value={formData.award_description}
                  onChange={handleChange}
                  required
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Other Details</label>
                <input
                  type="text"
                  name="other_details"
                  placeholder="Other Details"
                  value={formData.other_details}
                  onChange={handleChange}
                  className="border p-2 w-full"
                />
              </div>
              <div className="flex space-x-2 justify-start">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 border-none hover:text-white"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentChampion(null);
                    setFormData({
                      image: null,
                      name: "",
                      role: "",
                      award_name: "",
                      award_description: "",
                      other_details: "",
                    })
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && currentChampion && !isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the champion "<strong>{currentChampion.name || "N/A"}</strong>"?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeleteChampion}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 border-none hover:text-white"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentChampion(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Champion;

import React from "react";

const stepsContent = [
  {
    h1: "Fill out the registration form",
    p: "Express interest and provide professional details & book a consultation meeting",
  },
  {
    h1: "Schedule and attend a consultation",
    p: "Discuss potential roles, contributions, areas of expertise to choose a collaboration path",
  },
  {
    h1: "Participate in orientation and training sessions",
    p: "Understand ELiNA's approach and tools, including the ISMS",
  },
  {
    h1: "Start collaborating on specific assignments",
    p: "Coordinated efforts with other professionals to get regular updates using ISMS tool",
  },
  {
    h1: "Use the ISMS tool for seamless communication and tracking",
    p: "Ensure consistent and aligned support across all professionals and enable continuous professional development",
  },
  {
    h1: " Access resources and training for ongoing development",
    p: "Stay updated with best practices and new strategies",
  },
];

const Steps = () => {
  return (
    <>
      <div className="HeroBg2">
        <div className="flex flex-col items-center justify-center py-[5%]  px-[5%] lg:px-[8%]">
          <h1 className="text-center text-4xl md:text-6xl lg:text-7xl font-bold text-blue-700">
            Getting started is simple
          </h1>
          <div className="flex items-center ">
            <div className="flex lg:flex-row flex-col w-full gap-5 flex-wrap  justify-center text-center py-10">
              {stepsContent.map((item, index) => (
                <div className="lg:w-[32%] w-full flex flex-col gap-5 bg-blue-600 bg-opacity-10 rounded-3xl p-10 justify-between">
                  <div className="lg:h-14 md:h-10">
                    <h1 className=" text-blue-600 text-4xl font-black">
                      {index + 1}
                    </h1>
                  </div>
                  <div className="lg:h-28 md:h-20">
                    <h1 className="text-2xl font-bold">{item.h1}</h1>
                  </div>
                  <div className="lg:h-32 md:h-20">
                    <p className="text-lg">{item.p}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Steps;

import React, { useState } from "react";
import BreadCrumbs from "../reuseable/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";

const NavBarEnrollContent = [
  { li: "Parents", href: "/#/parent-form" },
  { li: "Schools", href: "/#/school-form" },
  { li: "Service provider", href: "/#/service-form" },
  { li: "Interns", href: "/#/intern-form" },
];

const MultiLevelDropDown = [
  { li: "Beyond 8", href: "" },
  { li: "HLC", href: "" },
  { li: "SSN", href: "" },
  { li: "Others", href: "" },
];

const IsmsHeroPage = () => {
  const [navEnrollDropDown, setNavEnrollDropDown] = useState(false);
  const [side, setSide] = useState(false);
  const navigate = useNavigate();

  const handleNavDropdownToggle = () => setNavEnrollDropDown((prev) => !prev);
  const handleSideToggle = () => setSide((prev) => !prev);

  return (
    <>
      <BreadCrumbs />
      <div className="HeroBg2 pt-[30%] pb-36 md:pt-0 md:h-fit">
        <div className="flex flex-col gap-10 items-center justify-center">
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-center text-blue-700 md:px lg:px-48 mt-[8%] font-black md:p-10">
            A Platform for Holistic Intervention Management Services
          </h1>
          <p className="text-center lg:px-[20%] px-[5%] text-lg">
            Take your therapy experience online to enrich the lives of both
            caregivers and children, facilitating improved support and
            reinforcing skills in the comfort of home.
          </p>
          {/* <div className="flex justify-center w-full items-center gap-5">
            <div
              className="lg:w-1/5"
              onMouseEnter={handleNavDropdownToggle}
              onMouseLeave={handleNavDropdownToggle}
            >
              <button
                onClick={(e) => {
                  // Check if the screen width is less than the `md` breakpoint (768px)
                  if (window.innerWidth < 768) {
                    handleNavDropdownToggle(); // Call your function here
                  }
                }}
                className="lg:p-3 w-full flex justify-center gap-5 items-center text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none border-none focus:ring-blue-300  font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 hover:text-white lg:font-black lg:text-2xl"
              >
                Get Started <ChevronDownIcon className="w-3 lg:w-7" />
              </button>
              {navEnrollDropDown && (
                <div className="absolute left-[30%] md:left-[39%] transform -translate-x-1/2 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-48 lg:w-80 dark:bg-gray-100">
                  <ul className="py-2 text-sm text-gray-700 dark:text-gray-800 relative">
                    <li
                      className="relative"
                      onMouseEnter={handleSideToggle}
                      onMouseLeave={handleSideToggle}
                    >
                      <a
                        href="/parent-form"
                        className="block relative px-4 lg:text-base py-2 hover:bg-gray-200 dark:hover:bg-indigo-600 font-bold dark:hover:text-white"
                      >
                        <div className="flex flex-row-reverse md:flex-row justify-between items-center">
                          <div className="mr-5">
                            <ChevronLeftIcon className="w-4 hidden lg:block" />
                            <ChevronRightIcon className="w-4 block sm:hidden " />
                          </div>
                          Parents
                        </div>
                      </a>
                      {side && (
                        <ul className="absolute -right-[93%] md:right-[100%] top-0 flex flex-col w-44 justify-center items-center rounded-lg shadow-lg bg-white mt-2">
                          {MultiLevelDropDown.map((item, index) => (
                            <li
                              key={index}
                              className="px-4 lg:text-base py-2 w-full flex justify-center hover:bg-gray-200 dark:hover:bg-indigo-600 font-bold dark:hover:text-white"
                            >
                              {item.li}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                    {NavBarEnrollContent.slice(1).map((item, index) => (
                      <li key={index}>
                        <a
                          href={item.href}
                          className="block px-4 lg:text-base py-2  hover:bg-gray-200 dark:hover:bg-indigo-600 font-bold dark:hover:text-white"
                        >
                          <div className="flex md:justify-end justify-start">
                            {item.li}
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="lg:w-1/5">
              <button
                onClick={() => navigate("/contact/getintouch")}
                className="border-none lg:p-3 w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 hover:text-white lg:font-black lg:text-2xl"
              >
                Schedule Demo
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default IsmsHeroPage;

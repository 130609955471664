// src/components/GalleryAdmin.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";
const GalleryAdmin = () => {
  // Modal state variables
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [galleryType, setGalleryType] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [isAddLoading,setIsAddLoading]= useState(true)
  const [formData, setFormData] = useState({
    event_type: "",
    image: null, // Consistently using 'image'
    name: "",
  });

  const [currentGallery, setCurrentGallery] = useState(null);
  const [isOther, setIsOther] = useState(false);
  const [newEventName, setNewEventName] = useState(""); // For capturing new event name
  const action_ids = useSelector(state => state.login.action_ids)
  useEffect(() => {
    fetchGalleries(); // Fetch all galleries by default
    fetchGalleryEvent(); // Fetch event types
  }, []);


  const fetchGalleries = async (event_type = "default") => {
    setIsLoading(true)
    try {
      let endpoint = `${process.env.REACT_APP_BASE_URL}/gallery`;
      if (event_type && event_type !== "default") {
        endpoint = `${process.env.REACT_APP_BASE_URL}/event_type/${event_type}`;
      }
      const response = await axios.get(endpoint);
      let data = response.data;

      if (event_type === "default") {
        // If fetching all, flatten the grouped data into a single array
        data = Object.values(data).flat();
      }

      setGalleries(data);
    } catch (error) {
      console.error("Error fetching gallery entries:", error);
      // Handle error without toast
    }
    finally {
      setIsLoading(false)
    }
  };

  const fetchGalleryEvent = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery`);
      const data = response.data;
      if (typeof data === 'object' && data !== null) {
        const eventTypes = Object.keys(data);
        setGalleryType(eventTypes);
      } else {
        setGalleryType([]);
        console.error("Unexpected data format for event types:", data);
        // Handle unexpected data format without toast
      }
    } catch (error) {
      console.error("Error fetching gallery event types:", error);
      // Handle error without toast
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Update the form data based on input type
    if (name === "image" && files) {
      // If the input is for the image, set the first file
      setFormData({
        ...formData,
        image: files[0], // Set the selected file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value, // Update the corresponding field
      });
    }
  };

  // Handle new event name change
  const handleNewEventNameChange = (e) => {
    setNewEventName(e.target.value);
  };

  const handleAddGallery = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("event_type", isOther ? newEventName : formData.event_type);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("name", formData.name);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertgallery`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsAddOpen(false);
      setFormData({
        event_type: "",
        image: null,
        name: "",
      });
      fetchGalleries(); // Refresh gallery list
    } catch (error) {
      console.error("Error adding gallery entry:", error);
    } finally {
      setIsLoading(false);
      setIsAddLoading(true)
    }
  };

  // View gallery entry details
  const handleView = (gallery) => {
    setCurrentGallery(gallery);
    setIsViewOpen(true);
  };

  // Open update modal with existing gallery data
  const openUpdateModal = (gallery) => {
    setCurrentGallery(gallery);
    setFormData({
      event_type: gallery.event_type,
      image: null, // Reset to null; existing image will be handled separately
      name: gallery.name,
    });
    setIsUpdateOpen(true);
  };

  const handleUpdateGallery = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("event_type", formData.event_type);
    formDataToSend.append("name", formData.name);

    // If a new image is uploaded, append it; otherwise, keep the existing image unchanged
    if (formData.image) {
        formDataToSend.append("image", formData.image);
    } else {
        // Assuming your backend expects the existing image path
        // Send a placeholder or the current image's filename, adjust as needed
        formDataToSend.append("existing_image", currentGallery.image); // Specify the key for existing image
    }

    // Log FormData contents for debugging
    // for (let [key, value] of formDataToSend.entries()) {
    //     console.log(key, value);
    // }

    try {
        // Send the update request to the server
        await axios.put(`${process.env.REACT_APP_BASE_URL}/updategallery/${currentGallery.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        // Close the update modal and clear current gallery
        setIsUpdateOpen(false);
        setCurrentGallery(null);
        fetchGalleries(); // Refresh gallery list
    } catch (error) {
        console.error("Error updating gallery entry:", error);
    } finally {
        setIsLoading(false);
    }
};

  
  // Efficiently convert buffer to base64 string
  const bufferToBase64 = (buffer) => {
    let binary = "";
    const len = buffer.length;
    const chunkSize = 0x8000; // 32768

    for (let i = 0; i < len; i += chunkSize) {
      const chunk = buffer.subarray(i, i + chunkSize);
      binary += String.fromCharCode.apply(null, chunk);
    }

    return `data:image/png;base64,${btoa(binary)}`;
  };

  // Convert base64 string back to Blob
  const base64ToBlob = (base64String) => {
    const [header, base64] = base64String.split(",");
    const mime = header.match(/:(.*?);/)[1];

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  // Open delete confirmation modal
  const openDeleteModal = (gallery) => {
    setCurrentGallery(gallery);
    setIsDeleteOpen(true);
  };

  // Delete a gallery entry
  const handleDeleteGallery = async () => {
    setIsLoading(true)
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deletegallery/${currentGallery.id}`);
      // Optionally, you can add success handling here (e.g., display a success message)
      setIsDeleteOpen(false);
      setCurrentGallery(null);
      fetchGalleries(); // Refresh with default filter
    } catch (error) {
      console.error("Error deleting gallery entry:", error);
      // Handle error without toast
    }
    finally {
      setIsLoading(false)
    }
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    const selectedType = e.target.value;
    fetchGalleries(selectedType);
  };

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    let objectUrl = null;
    if (formData.image) {
      objectUrl = URL.createObjectURL(formData.image);
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [formData.image]);

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="font-bold text-black text-5xl">Gallery</h1>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"} >
          {
            isAddOpen?"":
            <button
            onClick={() => setIsAddOpen(!isAddOpen)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 hover:text-white border-none"
          >
            Add Gallery
          </button>
          }
        </HasPermission>
      </div>



      {/* Add Gallery Form */}
      {isAddOpen && (
        <div className="mb-6 p-6 bg-white rounded shadow">
          <h2 className="font-bold text-xl mb-4">Add Gallery</h2>
          <form onSubmit={handleAddGallery}>
            <div className="mb-4">
              <label className="block mb-2">Event Type<span class="text-red-500">*</span></label>
              <select
                name="event_type"
                value={formData.event_type}
                onChange={(e) => {
                  handleChange(e);
                  setIsOther(e.target.value === "Other");
                }}
                className="border p-2 w-full"
                required
              >
                <option value="">Select Event Type</option>
                {galleryType.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
                <option value="Other">Other</option>
              </select>
            </div>
            {isOther && (
              <div className="mb-4">
                <label className="block mb-2">New Event Name</label>
                <input
                  type="text"
                  value={newEventName}
                  onChange={(e) => setNewEventName(e.target.value)}
                  className="border p-2 w-full"
                  required
                />
              </div>
            )}
            <div className="mb-4">
              <label className="block mb-2">Image<span class="text-red-500">*</span></label>
              <input
                type="file"
                name="image"
                onChange={handleChange}
                accept="image/*"
                required
              /><br/>
              <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Name<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </div>
            <div className="flex space-x-2">
            <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer "
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
            <button type="button" onClick={() => 
            {
              setIsAddOpen(false)
              setFormData({
                event_type: "",
                image: null, // Consistently using 'image'
                name: "",
              })
            }
            } className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white">
              Cancel
            </button>
            </div>
          </form>
        </div>
      )}

      {/* Filter Dropdown */}
      <div className="flex justify-end mb-4">
        <label htmlFor="filter" className="mr-2 text-gray-700 flex text-xl items-center">
          Filter by Type:
        </label>
        <select onChange={handleFilterChange} className="p-2 border border-gray-300 rounded">
          <option value="default">All</option>
          {galleryType.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
      </div>

      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {/* Gallery Entries */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="py-2 px-4 border">Image</th>
                <th className="py-2 px-4 border">Gallery Name</th>
                <th className="py-2 px-4 border">Event Type</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {galleries.length > 0 ? (
                [...galleries]
                .reverse()
                .map((gallery, index) => {
                  const imageUrl = `${process.env.REACT_APP_SERVICES_IMG_URL}/${gallery.image}`;
                  return (
                    <tr key={gallery.id} className="text-center hover:bg-gray-100">
                      <td className="py-2 px-4 border">{index + 1}</td>
                      <td className="py-2 px-4 border flex justify-center">
                        <img
                          src={imageUrl}
                          alt={gallery.name}
                          className="w-16 h-16 object-cover rounded"
                        />
                      </td>
                      <td className="py-2 px-4 border">{gallery.name}</td>
                      <td className="py-2 px-4 border">{gallery.event_type}</td>
                      <td className="py-2 px-4 border space-x-2 lg:w-64">
                        <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => handleView(gallery)}
                            className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 hover:text-black border-none"
                          >
                            View
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openUpdateModal(gallery)}
                            className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 hover:text-white border-none"
                          >
                            Update
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openDeleteModal(gallery)}
                            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 hover:text-white border-none"
                          >
                            Delete
                          </button>
                        </HasPermission>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="py-4">No galleries found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

{isUpdateOpen && currentGallery && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg  w-3/4">
            <h2 className="font-bold text-2xl mb-4">Update Gallery</h2>
            <form onSubmit={handleUpdateGallery}>
              <div className="mb-4">
                <label className="block mb-2">Event Type<span class="text-red-500">*</span></label>
                <select
                  name="event_type"
                  value={formData.event_type}
                  onChange={handleChange}
                  className="border p-2 w-full"
                  required
                >
                  <option value="">Select Event Type</option>
                  {galleryType.map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Existing Image</label>
                {currentGallery.image && (
                  <img
                    src={`${process.env.REACT_APP_SERVICES_IMG_URL}/${currentGallery.image}`}
                    alt="Current"
                    className="mb-2 h-32 w-32 object-cover"
                  />
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2">New Image (leave blank to keep existing)</label>
                <input
                  type="file"
                  name="image"
                  onChange={handleChange}
                  accept="image/*"
                /><br/>
                <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Name<span class="text-red-500">*</span></label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 hover:text-white border-none mr-2"
              >
                Update
              </button>
              <button
                type="button"
                onClick={() => 
                {
                  setIsUpdateOpen(false)
                  setIsAddOpen(false)
              setFormData({
                event_type: "",
                image: null, // Consistently using 'image'
                name: "",
              })}

                }
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 mt-4 hover:text-black text-black border-none"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {/* View Gallery Modal */}
      {isViewOpen && currentGallery && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg w-2/4">
            <h2 className="font-bold text-xl mb-4">Gallery Entry</h2>
            <img
              src={`${process.env.REACT_APP_SERVICES_IMG_URL}/${currentGallery.image}`}
              alt={currentGallery.name}
              className="w-48 h-48 object-cover mb-4 rounded"
            />
            <p className="mb-2"><strong>Name:</strong> {currentGallery.name}</p>
            <p className="mb-4"><strong>Event Type:</strong> {currentGallery.event_type}</p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 border-none hover:text-black text-black"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && currentGallery && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="font-bold text-xl mb-4">Delete Gallery Entry</h2>
            <p>Are you sure you want to delete "{currentGallery.name}"?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleDeleteGallery}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-2 hover:text-white border-none"
              >
                Delete
              </button>
              <button
                onClick={() => setIsDeleteOpen(false)}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 hover:text-black text-black border-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryAdmin;

import React, { useEffect, useRef } from "react";
import SchoolForm from "../../../components/forms/school/SchoolForm";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import SchoolFormHero from "../../../components/forms/school/SchoolFormHero";

const SchoolPageForm = () => {
  // Create a ref for the SchoolForm component
  const formRef = useRef(null);

  useEffect(() => {
    // Scroll to the SchoolForm component when the component mounts
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <SchoolFormHero />
      {/* Attach the ref to a wrapper element around SchoolForm */}
      <div ref={formRef}>
        <SchoolForm />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SchoolPageForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { unProtectedCall } from '../../services/userService';
import { generateAccessToken } from '../../pages/login/loginSlice';

const Login = () => {
  const [email_id, setEmail_id] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errormessage = useSelector(state => state.login.error)
  const success = useSelector(state => state.login.success); 
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // console.log("success status", success);
    if (success) {
      navigate("/admin/");
    }
  }, [success, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    const data = { email_id, password };
    dispatch(generateAccessToken({ unProtectedCall, data }));
  };

  return (
    <div className='w-screen h-screen flex justify-center items-center bg-grey-500'>
      <div className='w-96 bg-white p-8 rounded-lg shadow-lg'>
        <h2 className='text-3xl text-black mb-6 text-center font-bold'>Admin panel</h2>
        {error && <p className='text-red-500 mb-4'>{error}</p>}

        <form onSubmit={handleSubmit} className='flex flex-col'>
      {/* Email */}
      <label className='text-gray-700 mb-2 font-bold'>Email</label>
      <input
        type='text'
        placeholder='Email address'
        value={email_id}
        onChange={(e) => setEmail_id(e.target.value)}
        required
        className='mb-4 p-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 rounded-full'
      />

      {/* Password */}
      <label className='text-gray-700 mb-2 font-bold'>Password</label>
      <div className='relative mb-4'>
        <input
          type={showPassword ? 'text' : 'password'} // Toggle between text and password
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className='p-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 rounded-full w-full'
        />
        <button
          type='button'
          onClick={() => setShowPassword(!showPassword)} // Toggle visibility on button click
          className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 bg-transparent border-none'
        >
          {showPassword ? '👁️' : '👁️‍🗨️'} {/* Eye icon based on state */}
        </button>
      </div>
      
      {/* Error message */}
      <div>
        {errormessage === "Request failed with status code 401" && (
          <p className='text-red-500'>Incorrect email or password</p>
        )}
        
        <button
          type='submit'
          className='bg-blue-500 text-white py-2 mt-10 rounded-full w-full transition duration-200 hover:bg-blue-700 border-none hover:text-white'
        >
          Login
        </button>
      </div>
    </form>
      </div>
    </div>
  );
};

export default Login;

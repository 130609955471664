import React from "react";
import logo1 from "../../images/partnership/logo1HLC.png";
// import logo2 from "../../images/partnership/Logo2Athena.png";

// import logo3 from "../../images/partnership/Sree-Rehabilitation-Chennai-scaled.webp";

// import logo4 from "../../images/partnership/cambridge-assessment-international-education.png";

// import logo5 from "../../images/partnership/hamsa-logo-resized.jpg";

import logo6 from "../../images/partnership/logoo.jpg";
// import logo7 from "../../images/partnership/ttcms-logo-symbol-text.svg";

const partnerImages = [
  {
    i1: logo1,
  },
  // {
  //   i1: logo2,
  // },
  // {
  //   i1: logo3,
  // },
  // {
  //   i1: logo4,
  // },
  // {
  //   i1: logo5,
  // },
  {
    i1: logo6,
  },
  // {
  //   i1: logo1,
  // },
  // {
  //   i1: logo2,
  // },
  // {
  //   i1: logo3,
  // },
  // {
  //   i1: logo4,
  // },
  // {
  //   i1: logo5,
  // },
  // {
  //   i1: logo6,
  // },
];

const PartnershipCarousel = () => {
  return (
    <>
      <div className="lg:p-10 my-20">
        <div className="w-full text-center  text-3xl sm:text-5xl md:text-6xl mb-24 text-pink-600 font-bold ">
          <span>Our partners</span>
        </div>
        {/* <div className="w-screen-lg flex justify-center">
        <div className="pb-16 lg:pb-28 lg:max-w-7xl">
          <div class="h-52 bg-red-300">
            <div class=" flex gap-24">
              {partnerImages.map((item) => (
                <div class=" flex w-full">
                  <img
                    className="w-full h-fit"
                    src={item.i1}
                    alt="Our partners"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
        <div className="w-full justify-center gap-[5%] items-center p-5  flex">
          <div>
            <img src={logo6}></img>
          </div>
          <div>
            <img src={logo1}></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipCarousel;

import React, { useEffect } from "react";
import ContactUsHero from "../../../components/contactus/ContactUsHero";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import FAQsContactUs from "../../../components/contactus/FAQsContactUs";
import ContactUsForm from "../../../components/contactus/ContactUsForm";
import SignUp from "../../../components/contactus/SignUp";
import ScrollToTop from "react-scroll-to-top";
import GetStartedHero from "../../../components/forms/getStarted/GetStartedHero";
import ContactDetails from "../../../components/contactus/ContactDetails";
const blogContent = [
  {
    h1: "What is ELiNA?",
    p: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    h1: "What is ELiNA?1",
    p: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    h1: "What is ELiNA?2",
    p: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );

  return (
    <>
      {/* <ContactUsHero /> */}
      <GetStartedHero />
      <FAQsContactUs blogContent={blogContent} />
      <ContactUsForm />
      <ContactDetails/>
      <SignUp />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default ContactUs;

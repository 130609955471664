import React from "react";
import SideBar from "./SideBar";
import logo from "../../images/elinalogoSaturation.png";
// import logo from "../../images/hrms.png";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuAlt1Icon,
} from "@heroicons/react/outline";

const AdminNavBar = () => {
  const navigate = useNavigate()
  return (
    <>
      <nav className="fixed top-0 left-0 w-full h-[10vh] z-50 bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <a href="" className="flex ms-2 md:me-24">
                <img src={logo} className="h-14 me-3" alt="ELiNA Logo" />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  ELiNA Service
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminNavBar;

import { useState, useEffect, useRef } from "react";
import { Tree } from "primereact/tree";

import { protectedCall } from "../../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./styles.module.css";
// import Button from "../../components/button/Button";
import { Toast } from "primereact/toast";

export default function RolePermissionsEditAndView() {
  const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const { state } = useLocation();
  const [rawMenu, setRawMenu] = useState();
  const [roleName, setRoleName] = useState("");
  const navigate = useNavigate();
  const mapUamToTreeNodes = (uam) => {
    return uam.map((module) => ({
      key: `module-${module.moduleId}`,
      label: module.moduleName,
      data: `Module ${module.moduleId}`,
      children: [
        ...module.subModules.map((subModule) => ({
          key: `subModule-${subModule.subModuleId}`,
          label: subModule.subModuleName,
          data: `SubModule ${subModule.subModuleId}`,
          children: subModule.subModuleScreens.map((screen) => ({
            key: `screen-${screen.screenId}`,
            label: screen.screenName,
            data: `Screen ${screen.screenId}`,
            children: screen.actions.map((action) => ({
              key: `action-${screen.screenId}-${action.id}`,
              label: action.name,
              data: action.id,
            })),
          })),
        })),
        ...module.screens.map((screen) => ({
          key: `screen-${screen.screenId}`,
          label: screen.screenName,
          data: `Screen ${screen.screenId}`,
          children: screen.actions.map((action) => ({
            key: `action-${screen.screenId}-${action.id}`,
            label: action.name,
            data: action.id,
          })),
        })),
      ],
    }));
  };

  const mapRolePermissionsToSelectedKeys = (permissions) => {
    let selectedKeys = {};

    permissions.forEach((module) => {
      let moduleChecked = false;

      module.subModules.forEach((subModule) => {
        let subModuleChecked = false;

        subModule.subModuleScreens.forEach((screen) => {
          let screenChecked = false;

          screen.actions.forEach((action) => {
            if (action.hasPermission) {
              selectedKeys[`action-${screen.screenId}-${action.id}`] = {
                checked: true,
                partialChecked: false,
              };
              screenChecked = true;
            }
          });

          if (screenChecked) {
            selectedKeys[`screen-${screen.screenId}`] = {
              checked: true,
              partialChecked: false,
            };
            subModuleChecked = true;
          }
        });

        if (subModuleChecked) {
          selectedKeys[`subModule-${subModule.subModuleId}`] = {
            checked: true,
            partialChecked: false,
          };
          moduleChecked = true;
        }
      });

      module.screens.forEach((screen) => {
        let screenChecked = false;

        screen.actions.forEach((action) => {
          if (action.hasPermission) {
            selectedKeys[`action-${screen.screenId}-${action.id}`] = {
              checked: true,
              partialChecked: false,
            };
            screenChecked = true;
          }
        });

        if (screenChecked) {
          selectedKeys[`screen-${screen.screenId}`] = {
            checked: true,
            partialChecked: false,
          };
          moduleChecked = true;
        }
      });

      if (moduleChecked) {
        selectedKeys[`module-${module.moduleId}`] = {
          checked: true,
          partialChecked: false,
        };
      }
    });

    return selectedKeys;
  };

  const CallApi = (selectedKeys, nodes, roleName) => {
    const permissions = [];

    rawMenu?.forEach((module) => {
      // Process subModules

      module?.subModules?.forEach((subModule) => {
        subModule?.subModuleScreens?.forEach((screen) => {
          const actionIds = [];

          // Check selected actions in the screen
          screen?.actions?.forEach((action) => {
            const key = `action-${screen.screenId}-${action.id}`;
            if (selectedKeys[key] && selectedKeys[key].checked) {
              actionIds.push(action.id); // Collect action IDs
            }
          });

          // If there are selected actions for this screen, add it to permissions
          if (actionIds.length > 0) {
            permissions.push({
              id: module.moduleId, // Use moduleId directly
              screenId: screen.screenId, // Use screenId directly
              actionIds: actionIds,
            });
          }
        });
      });

      module?.screens?.forEach((screen) => {
        const actionIds = [];

        screen?.actions?.forEach((action) => {
          const key = `action-${screen.screenId}-${action.id}`;
          if (selectedKeys[key] && selectedKeys[key].checked) {
            actionIds.push(action.id); // Collect action IDs
          }
        });

        if (actionIds.length > 0) {
          permissions.push({
            id: module.moduleId, // Use moduleId directly
            screenId: screen.screenId, // Use screenId directly
            actionIds: actionIds,
          });
        }
      });
    });

    return {
      name: `${roleName}`,
      description: `Updated ${roleName} role`,
      permissions: permissions,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state?.id) {
          const menu = await protectedCall(`/api/menu-creator`);
          const rolePermission = await protectedCall(
            `/api/menu-creator/${state?.id}`
          );
          console.log("state id for view", state.id)
          console.log("view role permission", rolePermission)

          // Set the tree nodes
          setNodes(mapUamToTreeNodes(menu?.menus));

          // Set the selected keys
          setSelectedKeys(mapRolePermissionsToSelectedKeys(rolePermission));

          setRawMenu(menu?.menus);
          setRoleName(state?.roleName || "");
        }
      } catch (e) {
        console.error(e.message);
      }
    };

    fetchData();
  }, [state?.id]);

  const toast = useRef(null);
  const showError = (errorMsg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMsg,
      life: 3000,
    });
  };

  const showInfo = (info) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: info,
      life: 3000,
    });
  };

  const handleUpdate = async () => {
    if (state?.id)
      try {
        console.log("role upadate id", state.id)
        const transformed = CallApi(selectedKeys, nodes, roleName);
        await protectedCall(`/api/roles/${state?.id}`, transformed, "post");
        showInfo("Role updated Successful");
        navigate('/admin/roles')
      } catch (e) {
        if (toast.current) { 
          toast.current.clear(); 
        }
        showError(e?.message);
      }
      
    console.log();
  };

  return (
    <>

      <div className={styles.addMenuWrapper}>
        <h3 className="text-2xl font-bold">Role : {state?.roleName}</h3>
      </div>
      {state?.action === "edit" && (
      <div>
        <div>
          <label>Role</label>
          <input
            type="text"
            value={roleName} // Controlled input
            onChange={(e) => setRoleName(e.target.value)} // Update role name in state
          />
        </div>
      </div>)}
      <div
        className={styles.menuWrapper}
        style={{
          height: "70vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Tree
          value={nodes}
          selectionMode="checkbox"
          selectionKeys={selectedKeys}
          onSelectionChange={(e) => {
            if (state?.action === "edit") setSelectedKeys(e.value);
          }}
          className="w-full border-none bg-transparent custom-tree "
        />
        <div className="flex gap-5">
          {state?.action === "edit" && (
            <button className="bg-blue-500 text-white hover:text-white hover:bg-blue-700 p-2 border-none" onClick={handleUpdate}>Update</button>

          )}
          <button className="bg-blue-500 text-white p-2 hover:text-white hover:bg-blue-700 border-none" onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}>Back</button>

        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
}

import React, { useEffect, useRef } from "react";
import InternHero from "../../../components/forms/intern/InternHero";
import InternForm from "../../../components/forms/intern/InternForm";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";

const InternPageForm = () => {
  // Create a ref for the InternForm component
  const formRef = useRef(null);

  useEffect(() => {
    // Scroll to the InternForm component when the component mounts
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <InternHero />
      {/* Attach the ref to a wrapper element around InternForm */}
      <div ref={formRef}>
        <InternForm />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default InternPageForm;

import React from "react";
import { gsap } from "gsap";

const ParentFormHeroContent = [
  {
    svgImg: (
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 
  32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 
  192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 
  16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 
  16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 
  16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 
  16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 
  16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 
  16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"
        />
      </svg>
    ),
    p: "Schedule Appointment",
  },
  {
    svgImg: (
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
      </svg>
    ),
    p: "Meet1 - about child",
  },
  {
    svgImg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" stroke="none" fill="#000000" opacity="0" />

        <g transform="matrix(0.83 0 0 0.83 12 12)">
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "rgb(0,0,0)",
              fillRule: "nonzero",
              opacity: 1,
            }}
            transform="translate(-15, -15.51)"
            d="M 15 4 C 13.619288125423017 4 12.5 5.119288125423017 12.5 6.5 C 12.5 7.880711874576983 13.619288125423017 9 15 9 C 16.380711874576985 9 17.5 7.880711874576983 17.5 6.5 C 17.5 5.119288125423017 16.380711874576985 4 15 4 z M 5.5 5 C 4.119288125423017 5 3 6.119288125423017 3 7.5 C 3 8.880711874576983 4.119288125423017 10 5.5 10 C 6.880711874576983 10 8 8.880711874576983 8 7.5 C 8 6.119288125423017 6.880711874576983 5 5.5 5 z M 24.5 5 C 23.119288125423015 5 22 6.119288125423017 22 7.5 C 22 8.880711874576983 23.119288125423015 10 24.5 10 C 25.880711874576985 10 27 8.880711874576983 27 7.5 C 27 6.119288125423017 25.880711874576985 5 24.5 5 z M 15 11 C 12.791 11 11 12.791 11 15 L 11 16 L 8 16 L 8 14.5 C 8 13.119 6.881 12 5.5 12 C 4.119 12 3 13.119 3 14.5 L 3 19.5 C 3 20.82549 4.0339455 21.899362 5.3378906 21.984375 L 5.3378906 21.988281 L 9.6015625 22.400391 L 10 26 C 9.994899710454515 26.360635916577568 10.184375296169332 26.696081364571608 10.495872849714331 26.877887721486516 C 10.80737040325933 27.059694078401428 11.192629596740671 27.059694078401428 11.504127150285669 26.877887721486516 C 11.815624703830668 26.696081364571608 12.005100289545485 26.360635916577568 12 26 L 12 21.5 C 12 20.837863 11.548286 20.270062 10.943359 20.076172 L 10.943359 20.066406 L 8 19.214844 L 8 18 L 22 18 L 22 19.214844 L 19.056641 20.066406 L 19.056641 20.076172 C 18.451714 20.270062 18 20.837863 18 21.5 L 18 26 C 17.994899710454515 26.360635916577568 18.184375296169332 26.696081364571608 18.49587284971433 26.877887721486516 C 18.80737040325933 27.059694078401428 19.19262959674067 27.059694078401428 19.50412715028567 26.877887721486516 C 19.815624703830668 26.696081364571608 20.005100289545485 26.360635916577568 20 26 L 20.398438 22.400391 L 24.662109 21.988281 L 24.662109 21.984375 C 25.966054 21.899362 27 20.82549 27 19.5 L 27 14.5 C 27 13.119 25.881 12 24.5 12 C 23.119 12 22 13.119 22 14.5 L 22 16 L 19 16 L 19 15 C 19 12.791 17.209 11 15 11 z"
            strokeLinecap="round"
          />
        </g>
      </svg>
    ),
    p: "Meet2 - about ELiNA",
  },
  {
    svgImg: (
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2 .1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z" />
      </svg>
    ),
    p: "Sail enrollment ",
  },
  {
    svgImg: (
      <svg
        className="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
      >
        <path d="M64 64a64 64 0 1 1 128 0A64 64 0 1 1 64 64zM25.9 233.4C29.3 191.9 64 160 105.6 160h44.8c27 0 51 13.4 65.5 34.1c-2.7 1.9-5.2 4-7.5 6.3l-64 64c-21.9 21.9-21.9 57.3 0 79.2L192 391.2V464c0 26.5-21.5 48-48 48H112c-26.5 0-48-21.5-48-48V348.3c-26.5-9.5-44.7-35.8-42.2-65.6l4.1-49.3zM448 64a64 64 0 1 1 128 0A64 64 0 1 1 448 64zM431.6 200.4c-2.3-2.3-4.9-4.4-7.5-6.3c14.5-20.7 38.6-34.1 65.5-34.1h44.8c41.6 0 76.3 31.9 79.7 73.4l4.1 49.3c2.5 29.8-15.7 56.1-42.2 65.6V464c0 26.5-21.5 48-48 48H496c-26.5 0-48-21.5-48-48V391.2l47.6-47.6c21.9-21.9 21.9-57.3 0-79.2l-64-64zM272 240v32h96V240c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l64 64c9.4 9.4 9.4 24.6 0 33.9l-64 64c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V336H272v32c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-64-64c-9.4-9.4-9.4-24.6 0-33.9l64-64c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2z" />
      </svg>
    ),
    p: "In-depth Interaction",
  },
  {
    svgImg: (
      <svg
        id="Graph_Report_24"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" stroke="none" fill="#000000" opacity="0" />

        <g transform="matrix(0.77 0 0 0.77 12 12)">
          <path
            style={{
              stroke: "none",
              strokeWidth: 1,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeDashoffset: 0,
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              fill: "rgb(0,0,0)",
              fillRule: "nonzero",
              opacity: 1,
            }}
            transform="translate(-15, -15)"
            d="M 24.707 8.793 L 18.207 2.2929999999999993 C 18.019 2.105 17.765 2 17.5 2 L 7 2 C 5.895 2 5 2.895 5 4 L 5 26 C 5 27.105 5.895 28 7 28 L 23 28 C 24.105 28 25 27.105 25 26 L 25 9.5 C 25 9.235 24.895 8.981 24.707 8.793 z M 21.707 16.707 L 17.707 20.707 C 17.512 20.902 17.256 21 17 21 C 16.744 21 16.488 20.902 16.293 20.707 L 13 17.414 L 9.707 20.707 C 9.316 21.098 8.684000000000001 21.098 8.293000000000001 20.707 C 7.902000000000001 20.316000000000003 7.902000000000001 19.684 8.293000000000001 19.293 L 12.293000000000001 15.293 C 12.684000000000001 14.902 13.316 14.902 13.707 15.293 L 17 18.586 L 20.293 15.293 C 20.683999999999997 14.902 21.316 14.902 21.707 15.293 C 22.098000000000003 15.684 22.098 16.316 21.707 16.707 z M 18 10 C 17.448 10 17 9.552 17 9 L 17 3.904 L 23.096 10 L 18 10 z"
            strokeLinecap="round"
          />
        </g>
      </svg>
    ),
    p: "Reports",
  },
];

const ParentFormHero = () => {
  return (
    <>
      <div className="parentFormBg">
        <div className="flex flex-col p-5 lg:p-36">
          <div className="bg-black-bg rounded-3xl flex flex-col gap-5 lg:gap-16 p-8 lg:p-20">
            <h1 className="text-center text-5xl md:text-8xl font-black text-white">
              Get started with ELiNA
            </h1>
            <div className="flex lg:justify-between justify-center  w-[100%]  flex-wrap">
              {ParentFormHeroContent.map((item, index) => (
                <div className="flex flex-col w-1/2 md:w-[33.3%] lg:w-fit items-center mx-auto gap-2 mb-5">
                  <p className="bg-white rounded-full w-fit p-4">
                    {item.svgImg}
                  </p>
                  <p className="text-white text-center font-medium text-xs md:text-sm">
                    {item.p}
                  </p>
                </div>
              ))}
            </div>
            {/* <p className="text-center text-white">
              Welcome to ELiNA service, where conceived as a powerful concept of
              inclusion within HLC, and nurtured for a decade through extensive
              knowledge gathering, research, and real-life experiences. Our
              name, "ELiNA," signifies the first ray of sun and embodies our
              mission to be the first ray of hope for neurodiverse families.
            </p> */}
            {/* <p className="text-center text-white">
              <span className="font-bold ">Getting started is easy!</span>{" "}
              Follow these simple steps:
            </p> */}
          </div>
          <div className="mx-auto bg-black w-[80%] h-fit">
            <form></form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentFormHero;

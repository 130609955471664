import React from "react";

const SkeletonLoader = ({ itemCount }) => {
  return (
    <div className="relative flex items-center justify-center p-5 lg:p-12">
      <div className="mx-auto w-full md:w-[80%] bg-gray-200 rounded-3xl p-5 md:p-10">
        <div className="animate-pulse">
          {[...Array(itemCount)].map((_, index) => (
            <div className="flex mb-4 flex-wrap" key={index}>
              <div className="flex flex-col w-full md:w-[50%]">
                <div className="bg-white h-4 md:h-8 rounded mb-4 w-[50%] md:w-[30%]"></div>
                <div className="bg-white h-6 md:h-10 rounded mb-4 w-[90%]"></div>
              </div>
              <div className="flex flex-col w-full md:w-[50%]">
                <div className="bg-white h-4 md:h-8 rounded mb-4 w-[50%] md:w-[30%]"></div>
                <div className="bg-white h-6 md:h-10 rounded mb-4 w-[90%]"></div>
              </div>
            </div>
          ))}
          <div className="bg-white h-8 md:h-10 rounded mb-4 w-[60%] md:w-[30%] flex ml-auto"></div>
        </div>
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-base md:text-lg lg:text-3xl font-bold text-gray-600 animate-pulse">
          Submitting...
        </span>
      </div>
    </div>
  );
};

export default SkeletonLoader;

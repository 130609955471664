import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HasPermission from "../UAM/HasPermission";
import { useSelector } from "react-redux";

const EventsAdmin = () => {
  // Modal visibility states
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isAddLoading,setIsAddLoading]=useState(true)
  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Events data
  const [events, setEvents] = useState([]);

  // Form data for Add and Update
  const [formData, setFormData] = useState({
    event_name: "",
    description: "",
    date: "",
    location: "",
  });

  // Current event being viewed, updated, or deleted
  const [currentEvent, setCurrentEvent] = useState(null);

  // Permissions from Redux store
  const action_ids = useSelector((state) => state.login.action_ids);

  // Fetch events on component mount
  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to fetch all events with proper loading state management
  const fetchEvents = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/events`);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      toast.error("Failed to fetch events.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Handle input changes for forms
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle adding a new event
  const handleAddEvent = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during add operation
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertEvents`, formData);
      toast.success("Event added successfully!");
      setFormData({ event_name: "", description: "", date: "", location: "" });
      setIsAddOpen(false);
      fetchEvents();
    } catch (error) {
      console.error("Error adding event:", error);
      toast.error("Failed to add event.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after add operation
      setIsAddLoading(true)
    }
  };

  // Handle viewing an event
  const handleView = (event) => {
    setCurrentEvent(event);
    setIsViewOpen(true);
  };

  // Open update modal with selected event data
  const openUpdateModal = (event) => {
    setCurrentEvent(event);
    setFormData({
      event_name: event.event_name || "",
      description: event.description || "",
      date: event.date || "",
      location: event.location || "",
    });
    setIsUpdateOpen(true);
  };

  // Handle updating an event
  const handleUpdateEvent = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during update operation
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/updateEvent/${currentEvent.id}`, formData);
      toast.success("Event updated successfully!");
      setIsUpdateOpen(false);
      setCurrentEvent(null);
      fetchEvents();
    } catch (error) {
      console.error("Error updating event:", error);
      toast.error("Failed to update event.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after update operation
    }
  };

  // Open delete confirmation modal with selected event
  const openDeleteModal = (event) => {
    setCurrentEvent(event);
    setIsDeleteOpen(true);
  };

  // Handle deleting an event
  const handleDeleteEvent = async () => {
    setIsLoading(true); // Optional: Show loading during delete operation
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deleteEvents/${currentEvent.id}`);
      toast.success("Event deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentEvent(null);
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Failed to delete event.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after delete operation
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />

      {/* Header Section */}
      <div className="mb-4 flex justify-between pb-8">
        <p className="font-bold text-black text-5xl">Events</p>
        <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"}>
          {
            isAddOpen ? "":
            <button
            onClick={() => setIsAddOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none hover:text-white"
          >
            Add Event
          </button>
          }
        </HasPermission>
      </div>

      {/* Add Event Modal */}
      {isAddOpen && (
        <div className="mb-6 p-4 border rounded shadow">
          {/* <div className="bg-white p-6 rounded shadow-lg w-full max-w-md"> */}
            <h2 className="text-xl mb-4">Add New Event</h2>
            <form onSubmit={handleAddEvent}>
              <div className="mb-4">
                <label className="block mb-1">Event Name</label>
                <input
                  type="text"
                  name="event_name"
                  value={formData.event_name}
                  onChange={handleChange}
                  
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Description<span class="text-red-500">*</span></label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  className="w-full border px-3 py-2 rounded"
                  rows="4"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                 
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="flex space-x-2 justify-start">
              <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
                <button
                  type="button"
                  onClick={() => 
                  {
                    setIsAddOpen(false)
                    setFormData({
                      event_name: "",
                      description: "",
                      date: "",
                      location: "",
                    })
                  }
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none  hover:text-white"
                >
                  Cancel
                </button>
              </div>
            </form>
          {/* </div> */}
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading ? (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        /* Events Table */
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="py-2 px-4 border">Event Name</th>
                <th className="py-2 px-4 border">Description</th>
                <th className="py-2 px-4 border">Date</th>
                <th className="py-2 px-4 border">Location</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.length > 0 ? (
                [...events]
                .reverse()
                .map((event, index) => (
                  <tr key={event.id} className="text-center hover:bg-gray-100">
                    <td className="py-2 px-4 border">{index + 1}</td>
                    <td className="py-2 px-4 border">{event.event_name || "N/A"}</td>
                    <td className="py-2 px-4 border">{event.description || "N/A"}</td>
                    <td className="py-2 px-4 border">{event.date || "N/A"}</td>
                    <td className="py-2 px-4 border">{event.location || "N/A"}</td>
                    <td className="py-2 px-4 border space-x-2 w-64">
                      <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => handleView(event)}
                          className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 border-none hover:text-black"
                        >
                          View
                        </button>
                      </HasPermission>
                      <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => openUpdateModal(event)}
                          className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 border-none hover:text-white"
                        >
                          Update
                        </button>
                      </HasPermission>
                      <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                        <button
                          onClick={() => openDeleteModal(event)}
                          className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 border-none hover:text-white"
                        >
                          Delete
                        </button>
                      </HasPermission>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 text-center">
                    No events found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      

      {/* View Event Modal */}
      {isViewOpen && currentEvent && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Event Details</h2>
            <p>
              <strong>Name:</strong> {currentEvent.event_name || "N/A"}
            </p>
            <p>
              <strong>Description:</strong> {currentEvent.description || "N/A"}
            </p>
            <p>
              <strong>Date:</strong> {currentEvent.date || "N/A"}
            </p>
            <p>
              <strong>Location:</strong> {currentEvent.location || "N/A"}
            </p>
            <button
              onClick={() => setIsViewOpen(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Event Modal */}
      {isUpdateOpen && currentEvent && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4">
            <h2 className="text-2xl font-bold text-blue-500 mb-4">Update Event</h2>
            <form onSubmit={handleUpdateEvent}>
              <div className="mb-4">
                <label className="block mb-1">Event Name</label>
                <input
                  type="text"
                  name="event_name"
                  value={formData.event_name}
                  onChange={handleChange}
                  
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Description <span class="text-red-500">*</span></label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  className="w-full border px-3 py-2 rounded"
                  rows="4"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block mb-1">Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-1">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  
                  className="w-full border px-3 py-2 rounded"
                />
              </div>
              <div className="flex space-x-2 justify-start">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 border-none hover:text-white"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false);
                    setCurrentEvent(null);
                    setFormData({
                      event_name: "",
                      description: "",
                      date: "",
                      location: "",
                    })
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && currentEvent && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-sm">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the event "<strong>{currentEvent.event_name || "N/A"}</strong>"?
            </p>
            <div className="flex justify-end space-x-2 mt-6">
              <button
                onClick={handleDeleteEvent}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white border-none"
              >
                Delete
              </button>
              <button
                onClick={() => {
                  setIsDeleteOpen(false);
                  setCurrentEvent(null);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventsAdmin;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import event1 from "../../images/events/event1.jpg";
import event2 from "../../images/events/event2.jpg";
import event3 from "../../images/events/event3.png";
import event4 from "../../images/events/event4.png";

// import required modules

const Previousevent = () => {
  const slides = [
    {
      imgSrc: event1,
      heading: "Free consultation sessions for parents",
      content:
        "Date:30th March 2024 Time:10.00 A.M - 1.00 P.M Venue:lndiabulls GREENS Phase Clubhouse. Gain invaluable insights into your child's world end unlock their full potential. Join our session to become a more confident and informed parent, empowering your child to thrive independently.",
      date: "30/03/2024",
    },
    {
      imgSrc: event2,
      heading: "Autism award events",
      content:
        "Date:6th April 2024 Time:4.00 A.M - 6.OO P.M Venue: Edina, House of Hiranandani, Near Marina Mall, OMR Gain invaluable insights into your child's world and unlock their full potential. Join our session to become a more confident and informed parent, empowering your child to thrive independently.",
      date: "06/04/2024",
    },
    {
      imgSrc: event3,
      heading: "Free counselling session for parents",
      content:
        "Gain invaluable insights into your child's world and unlock their full potential. Join our session to become a more confident and informed parent, empowering your child to thrive independently. Website: www.ELiNAservices.com Venu...",
      date: "13/04/2024",
    },
    // {
    //   imgSrc: event4,
    //   heading: "Lorem Ipsum is simply dummy",
    //   content:
    //     "Gain invaluable insights into your child's world and unlock their full potential. Join our session to become a more confident and informed parent empowering your child to thrive independently. Website: www.ELiNAservices.com Venue: ELiNA Office CI-301, Pelican Nest, Creek Street, OMR Date: 13th April 2024 Time: 4pm - 6pm",
    //   date: "13/04/2024",
    // },
    {
      imgSrc: event1,
      heading: "Autism awareness month",
      content:
        "FREE CONSULTATION SESSIONS FOR PARENTS Important Note - This is not an assessment process. It's an opportunity for parents to seek guidance, ask questions, and learn about resources available to support their journey.",
      date: "30/03/2024",
    },
  ];

  return (
    <>
      <div className="lg:mb-20 md:mb-10 mb-8 lg:mt-32 relative lg:mx-4">
        <div className="text-center font-bold text-3xl md:text-5xl lg:text-6xl text-indigo-600 lg:mt-0 md:mt-20 mt-10">
          <p>Completed events</p>
        </div>
        {/* {/ Large Screens /} */}
        <div className="lg:mx-10 hidden lg:block md:hidden relative">
          <Swiper
            slidesPerView={3}
            spaceBetween={42}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {slides.map((items, index) => (
              <SwiperSlide key={index} className="relative lg:my-14 rounded-lg">
                <div className="relative">
                  <img
                    src={items.imgSrc}
                    alt="Event"
                    className="w-full h-56 object-cover"
                  />
                  <p className="lg:text-center md:text-center text-start font-bold lg:text-2xl md:text-lg text-sm mt-2 text-pink-500">
                    {items.heading}
                  </p>

                  <p className="hidden lg:block md:block px-2 mt-2 lg:text-lg md:text-sm">
                    {items.content}
                  </p>
                  <div className="hidden lg:block md:block absolute lg:top-2 lg:right-2 md:top-2 md:right-2 top-0 right-0 bg-pink-500 text-sm md:text-md lg:text-xl text-white px-2 py-1 rounded font-bold">
                    {items.date}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* {/ Navigation Arrows /} */}
          <div className="pl-10 swiper-button-prev lg:absolute lg:left-0 lg:-translate-x-full transform -translate-x-1/2 text-white arrow-small"></div>
          <div className="pr-10 swiper-button-next lg:absolute lg:right-0 lg:translate-x-full transform translate-x-1/2 text-white arrow-small"></div>
        </div>

        {/* {/ Medium Screens /} */}
        <div className="lg:mx-8 md:mx-8 mx-2 hidden lg:hidden md:block relative">
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next-md",
              prevEl: ".swiper-button-prev-md",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {slides.map((items, index) => (
              <SwiperSlide
                key={index}
                className="relative lg:my-8 md:my-8 my-8 rounded-lg"
              >
                <div className="relative">
                  <img
                    src={items.imgSrc}
                    alt="Event"
                    className="w-full h-56 object-cover"
                  />
                  <p className="lg:text-center md:text-center text-start font-bold lg:text-2xl md:text-lg text-sm mt-2 text-pink-500">
                    {items.heading}
                  </p>

                  <p className="hidden lg:block md:block px-2 mt-2 lg:text-lg md:text-sm">
                    {items.content}
                  </p>
                  <div className="hidden lg:block md:block absolute lg:top-2 lg:right-2 md:top-2 md:right-2 top-0 right-0 bg-pink-500 text-sm md:text-md lg:text-xl text-white px-2 py-1 rounded font-bold">
                    {items.date}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="pl-5 swiper-button-prev md:absolute md:left-0 md:-translate-x-full transform -translate-x-1/2 text-white arrow-small"></div>
          <div className="pr-5 swiper-button-next md:absolute md:right-0 md:translate-x-full transform translate-x-1/2 text-white arrow-small"></div> */}
        </div>
        {/* {/ Small Screens /} */}
        <div className="lg:mx-8 md:mx-8 mx-3 lg:hidden md:hidden relative">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next-sm",
              prevEl: ".swiper-button-prev-sm",
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {slides.map((items, index) => (
              <SwiperSlide
                key={index}
                className="relative overflow-hidden my-8 rounded-lg"
              >
                <div className="relative">
                  <img
                    src={items.imgSrc}
                    alt="Event"
                    className="w-full h-56 object-cover"
                  />
                  <p className="text-center font-bold text-2xl mt-2 text-pink-500 ">
                    {items.heading}
                  </p>
                  <p className="px-2 mt-2">{items.content}</p>
                  <div className="absolute top-2 right-2 bg-pink-500 text-white px-2 py-1 rounded font-bold">
                    {items.date}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="pl-10 swiper-button-prev sm:absolute sm:left-0 sm:-translate-x-full transform -translate-x-1/2 text-white"></div>
          <div className="pr-10 swiper-button-next sm:absolute sm:right-0 sm:translate-x-full transform translate-x-1/2 text-white"></div> */}
        </div>
      </div>
    </>
  );
};

export default Previousevent;

import React, { useEffect } from "react";
import PartnershipHero from "../../../components/partnership/PartnershipHero";
import PartnershipContent from "../../../components/partnership/PartnershipContent";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import PartnershipCarousel from "../../../components/partnership/PartnershipCarousel";
import Partnershipsubconotent from "../../../components/partnership/Partnershipsubconotent";
import ScrollToTop from "react-scroll-to-top";
const PartnershipPageContent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );
  return (
    <>
      <PartnershipHero />
      <PartnershipContent />
      <PartnershipCarousel />
      <Partnershipsubconotent />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default PartnershipPageContent;

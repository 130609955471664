import React from "react";
import { PlayIcon } from "@heroicons/react/outline";
import video from "../../video/video.mp4";

const Video = () => {
  return (
    <div className="pt-20 md:p-10 lg:py-20 HeroBg2">
      <h1 className="text-5xl lg:text-6xl font-black text-blue-700 text-center mb-10">
        About ELiNA
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 p-10 content-center lg:justify-around lg:items-center">
        <div className="flex justify-center">
          <video
            className="mx-auto rounded-2xl lg:w-[35rem] lg:h-[20rem]"
            controls
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
        <div className="flex flex-col justify-center lg:py-10">
          <p className="text-base lg:text-lg font-semibold pb-5">
            ELiNA was born out of a deep need to support parents of children
            with autism, ADHD, and learning differences. Many families struggle
            with understanding their child’s unique challenges and accessing the
            right resources. This confusion often leads to delayed intervention,
            making academic and social difficulties even harder to overcome.
          </p>
          <p className="text-base pb-5">
            ELiNA steps in to provide clarity, guidance, and comprehensive
            support, empowering parents to take the right steps for their
            child's growth. Through personalized resources and expert advice, we
            help families navigate their journey, ensuring that every child has
            the opportunity to thrive.
          </p>
          <p className="text-base pb-5">
            Our mission is simple: to be the first ray of hope for families,
            offering the support and tools they need to build brighter futures
            for their children.
          </p>
          <button
            onClick={() => window.open("https://youtu.be/lv9QqC_PXpw")}
            className="text-lg hover:text-[#FF0000] border border-[#FF0000] hover:border-[#FF0000] font-normal p-2 mt-5 lg:w-[30%] lg:h-8 flex justify-center items-center lg:gap-2 bg-[#FF0000] lg:text-2xl lg:p-6 lg:rounded-3xl lg:m-0"
          >
            YouTube
            <PlayIcon className="w-5 lg:w-8" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Video;

import React from "react";
// import professional2 from "../../../src/images/professional/professional2.jpg";
import professional2 from "../../../src/images/professional/professionalCompressed/professional2-min.jpg";

// const professionalLiContent = [
//   {
//     li: "Physical Therapy",
//   },
//   {
//     li: "Occupational Therapy",
//   },
//   {
//     li: "Speech Therapy",
//   },
//   {
//     li: "ABA Therapy",
//   },
// ];

const Professional2 = () => {
  return (
    <>
      <div className="HeroBg">
        <div className="flex flex-col lg:flex-row-reverse items-center justify-center p-5 lg:p-20">
          <div className="flex  flex-col  w-full lg:w-1/2 gap-6 lg:px-10 lg:py-10">
            <h1 className="font-bold text-3xl lg:text-5xl text-blue-700">
              For professionals: collaborating with ELiNA for comprehensive
              child support
            </h1>
            <p className="text-base">
              At ELiNA, we recognize the vital role that professionals play in
              the growth and development of children with neurodivergent
              conditions. Our CoMPASS Program is designed to facilitate
              collaboration among a diverse range of specialists, providing a
              unified and holistic approach to child support. Through
              technology-based enhancements, including our ISMS (Integrated
              Support Management System) tool, we ensure effective communication
              and coordination, regardless of geographic limitations.
            </p>

            {/* <a href="/#/service-form">
              <button className="w-full md:w-[50%] lg:w-[60%] py-4 px-3 rounded-2xl text-lg lg:text-xl bg-pink-600 border-pink-600 text-white hover:bg-transparent hover:border-pink-600 hover:text-pink-600">
                Join our professional network
              </button>
            </a> */}
          </div>
          <div className="flex w-full lg:w-1/2 py-5 lg:pl- ">
            <img
              className="rounded-3xl"
              src={professional2}
              alt="Professionals"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Professional2;

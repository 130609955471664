import React from "react";
import hero from "../../images/professional/professionalCompressed/professionalHero-min.jpg";

const ProfessionalHero = () => {
  return (
    <>
      <div className="HeroBg relative ">
        <div className=" flex flex-col  lg:flex-row mx:[4%] lg:mx-[6%]   md:pt-[3%]  lg:pb-10 ">
          <div className=" flex flex-col justify-center text-center lg:text-start md:text-center gap-14 md:gap-6 my-6 mx-5 mt-7 md:mx-24 md:my-10  lg:pr-10 lg:m-0">
            <h1 className=" text-5xl font-black text-blue-700 md:text-4xl lg:text-6xl">
              Guided by compassion, driven by expertise
            </h1>
            <p className="  md:pb-0  text-xl text-center lg:text-start">
              Welcome to a place where professional expertise seamlessly blends
              with heartfelt compassion. At ELiNA, we believe in a holistic
              approach to child development, partnering with therapists to
              empower every child’s journey.
            </p>
            {/* <a href="/#/service-form">
              <button className="bg-pink-700 border-pink-700 text-white hover:bg-transparent hover:text-pink-600 hover:border-pink-600 py-2 px-6 lg:py-3 lg:px-8 text-lg lg:text-xl rounded-xl border-2">
                Join us on this journey
              </button>
            </a> */}
          </div>
          <>
            <img
              className="m-10 lg:m-0 rounded-3xl object-cover lg:block lg:w-[45%]"
              src={hero}
              alt="Hero Bg"
            />
          </>
        </div>
      </div>
    </>
  );
};
export default ProfessionalHero;

// import React from "react";
// import BreadCrumbs from "../reuseable/BreadCrumbs";

// const ProfessionalHero = () => {
//   return (
//     <>
//       <BreadCrumbs />

//       <div className="professionalBg h-screen bg-black-bg lg:bg-black-bg1">
//         <div className="flex items-center justify-center h-full px-4 lg:px-10 py-10 lg:py-20">
//           <div className="bg-black-bg lg:bg-black-bg p-6 lg:p-10 rounded-3xl flex flex-col justify-center items-center text-center gap-8 lg:gap-12">
//             <p className="text-white text-3xl md:text-5xl lg:text-7xl font-black">
//               Guided by compassion, driven by expertise
//             </p>
//             <p className="text-lg md:text-xl font-normal text-white px-4 lg:px-8">
//               Welcome to a place where professional expertise seamlessly blends
//               with heartfelt compassion. At ELiNA, we believe in a holistic
//               approach to child development, partnering with therapists to
//               empower every child’s journey.
//             </p>
//             <div className="flex gap-4">
//               <a href="/#/service-form">
//                 <button className="bg-pink-700 border-pink-700 text-white hover:bg-transparent hover:text-white hover:border-white py-2 px-6 lg:py-3 lg:px-8 text-lg lg:text-xl rounded-xl border-2">
//                   Join us on this journey
//                 </button>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProfessionalHero;

import React from "react";
import partnership from "../../images/partnership/partnership.jpg";

const PartnershipHero = () => {
  return (
    <>
      <div className="HeroBg h-fit flex justify-center items-center px-4 lg:px-6">
        <div className="flex flex-col lg:flex-row w-full max-w-screen-xl mx-auto lg:space-x-10 space-y-10 lg:space-y-0 lg:my-32 md:my-24 my-24">
          <div className="flex flex-col justify-start lg:w-1/2 text-center lg:text-left gap-8">
            <span className="text-4xl lg:text-7xl font-semibold text-pink-600">
              Partner with ELiNA
            </span>
            <p className="text-sm lg:text-lg font-bold text-gray-700 text-justify md:mx-14 lg:mx-0 mx-8">
              ELiNA invites partners to join its mission of championing
              neurodiversity and fostering inclusivity through a partnership
              model. By partnering with ELiNA, you align with a dynamic
              organization committed to making a meaningful impact in the lives
              of neurodiverse individuals. ELiNA offers tailored programs,
              expert guidance, and comprehensive resources to support inclusion
              across various settings. As a partner, you'll have the opportunity
              to amplify these efforts in your community, benefiting from
              ELiNA's proven expertise and passion for driving positive change.
            </p>
            {/* <div className="w-full">
              <button className="py-2 w-fit px-10 lg:text-2xl mt-4 font-bold bg-pink-600 hover:text-pink-600 border-pink-600 text-white rounded-md sm:ml-auto md:mx-auto lg:mx-0">
                Join Us
              </button>
            </div> */}
          </div>
          <div className="lg:w-1/2 flex justify-center lg:justify-end hidden lg:flex">
            <img
              className="rounded-3xl object-cover w-full max-w-sm lg:max-w-full"
              src={partnership}
              alt="Partnership"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipHero;

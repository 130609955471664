import React, { useEffect } from "react";
import SchoolHero from "../../../components/school/SchoolHero";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import OurPrograms from "../../../components/school/OurPrograms";
import LeadYourTeacher from "../../../components/school/LeadYourTeacher";
import SchoolActualHero from "../../../components/school/SchoolActualHero";
import StepsSchools from "../../../components/school/StepsSchools";
import According from "../../../components/school/According";
import ScrollToTop from "react-scroll-to-top";
const SchoolPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );
  return (
    <>
      <SchoolActualHero />
      <SchoolHero />
      <LeadYourTeacher />
      <According />
      
      <StepsSchools />
      <OurPrograms />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default SchoolPage;

import React from "react";
import card1 from "../../images/professional/1.jpg";
import card2 from "../../images/professional/2.jpg";
import card3 from "../../images/professional/3.jpg";
import card4 from "../../images/professional/4.jpg";
import TR from "../../images/professional/cardImg/TargetedReferrals.png";
import SR from "../../images/professional/cardImg/SpecializedReferrals.png";
import IP from "../../images/professional/cardImg/IntegratedPerspective2.png";
import CS from "../../images/professional/cardImg/CollaborativeSuccess.png";
import EV from "../../images/professional/cardImg/EnhancedVisibility.png";
import CT from "../../images/professional/cardImg/CommunicationandTracking.png";
import hos from "../../images/professional/cardImg/holistic.png";
import SC from "../../images/professional/cardImg/seemlesscommunication.png";
import card5 from "../../images/professional/5.jpg";
import card6 from "../../images/professional/6.jpg";
import card7 from "../../images/professional/7.jpg";
import card8 from "../../images/professional/8.jpg";
import card9 from "../../images/professional/9.jpg";
import card10 from "../../images/professional/10.jpg";
import card11 from "../../images/professional/11.jpg";
import card12 from "../../images/professional/12.jpg";

const weHelpKidContent = [
  {
    h1: "Targeted referrals: Connecting with the right children",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">Specialized referrals:</span>{" "}
        ELiNA connects professionals with referrals for children with ADHD,
        Autism, and Learning Disabilities who can benefit from your expertise.
        Our system ensures children receive the specialized care they need from
        the most suitable professionals.
      </span>
    ),
    src: TR,
  },
  {
    h1: "Holistic view: Comprehensive understanding of the child's environment",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">Integrated perspective:</span>{" "}
        Gain a holistic view of the child's needs across all environments,
        including home, school, and therapy settings. This comprehensive
        understanding enables more targeted and effective interventions,
        ensuring that every aspect of the child's development is addressed.
      </span>
    ),
    src: hos,
  },
  {
    h1: "Effective intervention: Converging efforts for maximum impact",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">Collaborative success:</span>{" "}
        Deliver effective interventions through a coordinated approach. Our
        program integrates the expertise of various professionals, ensuring a
        cohesive strategy that maximizes the impact of your work.
      </span>
    ),
    src: card7,
  },
  {
    h1: "Targeted referrals: Reaching the children who need us most",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">
          Flexibility and accessibility:
        </span>
        The ISMS tool improves flexibility and accessibility by allowing
        professionals and parents to collaborate from anywhere, ensuring
        consistent intervention quality. It provides access to ELiNA’s unified
        program plan, enhancing communication and coordination, and aligning
        everyone with shared goals for increased effectiveness.
      </span>
    ),
    src: IP,
  },
  {
    h1: "Enhanced visibility: Understanding the broader scope of care",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">
          Interdisciplinary insights:
        </span>{" "}
        ELiNA provides visibility into the broader scope of care through
        collaboration with doctors, therapists, and educators. This
        interdisciplinary approach enriches your understanding of the child's
        overall needs and the strategies being implemented.
      </span>
    ),
    src: EV,
  },

  {
    h1: "Communication and tracking: Ongoing support and monitoring",
    decs: (
      <span>
        <span className="text-pink-600 font-bold">Seamless communication:</span>{" "}
        Benefit from structured communication channels via our ISMS tool,
        enabling ongoing dialogue between all stakeholders. Regular updates,
        progress tracking, and shared insights allow for real-time adjustments
        to support strategies based on the child's evolving needs.
      </span>
    ),
    src: CT,
  },
];

const WeHelpKids = () => {
  return (
    <>
      <div className="HeroBg">
        <div className="flex flex-col items-center lg:py-16 px-5 lg:px-0">
          <h1 className="text-center text-4xl md:text-6xl lg:text-7xl font-bold text-blue-700 w-full  p-5">
            Benefits of collaborating with ELiNA
          </h1>
          <div className="grid grid-flow-row md:grid-cols-1 lg:grid-cols-3 gap-16  md:mx-3 lg:mx-0 lg:py-[3%] lg:px-[10%]">
            {weHelpKidContent.map((item) => (
              <div className="flex flex-col items-center drop-shadow-xl rounded-3xl bg-white w-full ">
                <div className="w-full flex justify-center">
                  <img
                    className="lg:w-full lg:h-[250px] md:w-[300px] md:h-[200px] rounded-3xl"
                    src={item.src}
                    alt={item.h1}
                  />
                </div>
                <div className="bg-sky-00">
                  <div className="w-full lg:min-h-36 bg-red-00">
                    <p className="px-5 py-3 text-2xl text-gray-800 font-black text-center ">
                      {item.h1}
                    </p>
                  </div>
                  <div className="w-full mt-auto bg-green-00">
                    <p className="lg:text-base p-5 text-justify text-gray-600">
                      {item.decs}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WeHelpKids;

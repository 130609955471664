import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import Cookiespages from "../components/cookies/cookies";
import Chatbot from "../components/chatbot/Chatbot";
import bot from "../images/bot.png";
export default function User() {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false)
  useEffect(() => {
    if (!sessionStorage.getItem("sessionId")) {
      sessionStorage.setItem("sessionId", `ELiNA-${Date.now()}`);
    }

    const timer = setTimeout(() => {
      setShowWelcomeMessage(true);
    }, 5000);

  }, []);
  const [showChatbot, setShowChatbot] = useState(false);

  async function toggleChatbot(e) {
    setShowChatbot(!showChatbot);
    try {
      e.preventDefault();
      const res = await fetch(
        "https://elinaservices.com:7005/api/insertNewSession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sessionId: sessionStorage.getItem("sessionId"),
            ipAddress: getCookieValue("ip"),
          }),
        }
      );
      const data = await res.json();
      console.log(data);
    } catch (e) {
      console.log("Session ID was not created");
    }
  }
  function getCookieValue(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split("; ");
    for (let cookie of cookieArray) {
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length);
      }
    }
    return null;
  }

  function closeWelcomeMessage() {
    setShowWelcomeMessage(false);
  }

  return (
    <>
      <NavBar />
      <Outlet />
      <Cookiespages />
      <Footer />
      <div className="fixed bottom-4 right-4">
        <button
          onClick={toggleChatbot}
          className="p-2 rounded-full bg-indigo-600 hover:bg-indigo-700 border-none"
        >
          <img src={bot} className="w-8 h-8" alt="bot" />
        </button>
      </div>

      {showChatbot && (
        <div className="fixed bottom-20 right-4  shadow-lg  rounded-lg z-50">
          <Chatbot
            setShowChatbot={setShowChatbot}
            showChatbot={showChatbot}
            random={sessionStorage.getItem("sessionId")}
          />
        </div>
      )}
      {showWelcomeMessage && (
        <div className=" animate-bounce  fixed bottom-4 right-4 sm:right-10 md:right-20 bg-white px-2 py-1 shadow-lg rounded-lg z-50 hidden md:flex items-center">
          <span className="mr-2">Hello, how can I help you?</span>
          <button onClick={closeWelcomeMessage} className="text-black font-extrabold bg-transparent rounded-full px-2 py-1 border-none text-xl">
            &times; {/* Close button */}
          </button>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import HasPermission from "../UAM/HasPermission";

const Poster = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Initialize as false
  const [posters, setPosters] = useState([]);
  const [formData, setFormData] = useState({
    poster: null,
    name: "",
    form_link: "",
  });
  const [isAddLoading, setIsAddLoading] = useState(true)
  const [currentPoster, setCurrentPoster] = useState(null);
  const action_ids = useSelector(state => state.login.action_ids);

  const API_BASE = "http://192.168.1.31:3001/api";

  useEffect(() => {
    fetchPosters();
  }, []);

  // Function to fetch posters with correct isLoading management
  const fetchPosters = async () => {
    setIsLoading(true); // Set loading to true before the API call
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/poster`);
      setPosters(response.data);
    } catch (error) {
      console.error("Error fetching posters:", error);
      toast.error("Failed to fetch posters.");
    } finally {
      setIsLoading(false); // Set loading to false after the API call
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleAddPoster = async (e) => {
    setIsAddLoading(false)
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during add operation
    const formDataToSend = new FormData();
    formDataToSend.append("poster", formData.poster);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("form_link", formData.form_link);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/insertposter`, formDataToSend);
      toast.success("Poster added successfully!");
      setIsAddOpen(false);
      setFormData({
        poster: null,
        name: "",
        form_link: "",
      });
      fetchPosters();
    } catch (error) {
      console.error("Error adding poster:", error);
      toast.error("Failed to add poster.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after add operation
      setIsAddLoading(true)
    }
  };

  const handleView = (poster) => {
    setCurrentPoster(poster);
    setIsViewOpen(true);
  };

  const openUpdateModal = (poster) => {
    setCurrentPoster(poster);
    setFormData({
      poster: null,
      name: poster.name,
      form_link: poster.form_link || "",
    });
    setIsUpdateOpen(true);
  };

  const handleUpdatePoster = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Optional: Show loading during update operation
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("form_link", formData.form_link);

    if (formData.poster) {
      formDataToSend.append("poster", formData.poster);
    } else if (currentPoster.poster && currentPoster.poster.data) {
      const existingImageBlob = base64ToBlob(
        bufferToBase64(new Uint8Array(currentPoster.poster.data))
      );
      formDataToSend.append("poster", existingImageBlob, "existingImage.png");
    }

    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/updateposter/${currentPoster.id}`, formDataToSend);
      toast.success("Poster updated successfully!");
      setIsUpdateOpen(false);
      setCurrentPoster(null);
      fetchPosters();
    } catch (error) {
      console.error("Error updating poster:", error);
      toast.error("Failed to update poster.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after update operation
    }
  };

  const openDeleteModal = (poster) => {
    setCurrentPoster(poster);
    setIsDeleteOpen(true);
  };

  const handleDeletePoster = async () => {
    setIsLoading(true); // Optional: Show loading during delete operation
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/deleteposter/${currentPoster.id}`);
      toast.success("Poster deleted successfully!");
      setIsDeleteOpen(false);
      setCurrentPoster(null);
      fetchPosters();
    } catch (error) {
      console.error("Error deleting poster:", error);
      toast.error("Failed to delete poster.");
    } finally {
      setIsLoading(false); // Optional: Hide loading after delete operation
    }
  };

  const bufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const base64ToBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([new Uint8Array(byteNumbers)], { type: "image/png" });
  };

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <div className="mb-4 justify-between flex pb-8">
        <div>
          <p className="font-bold text-black text-5xl">Event Poster</p>
        </div>
        <div>
          <HasPermission permissionName={1} permission={action_ids} hideOrDisable={"hide"}>
            {
              isAddOpen ? "" :
                <button
                  onClick={() => setIsAddOpen(true)}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 border-none hover:text-white"
                >
                  Add Poster
                </button>
            }
          </HasPermission>
        </div>
      </div>

      {isAddOpen && (
        <div className="mb-6 p-4 border rounded shadow">
          <h2 className="text-xl mb-4">Add New Poster</h2>
          <form onSubmit={handleAddPoster}>
            <label className="block mb-2">Poster Image <span class="text-red-500">*</span></label>

            <input
              type="file"
              name="poster"
              accept="image/*"
              onChange={handleChange}
              required
              className="border p-2  w-full"
            />
            <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
            <label className="block mb-2 mt-3">Name<span class="text-red-500">*</span></label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="border p-2 mb-4 w-full"
            />
            <label className="block mb-2">Form Link<span class="text-red-500">*</span></label>
            <input
              type="url"
              name="form_link"
              placeholder="Form Link"
              value={formData.form_link}
              onChange={handleChange}
              required
              className="border p-2 mb-4 w-full"
            />
            <div className="flex space-x-2">
              <button
                type="submit"
                className={`px-4 py-2 rounded text-white border-none hover:text-white ${isAddLoading
                    ? "bg-green-500 hover:bg-green-600 cursor-pointer"
                    : "bg-gray-300 cursor-not-allowed"
                  }`}
                disabled={!isAddLoading}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAddOpen(false);
                  setFormData({
                    poster: null,
                    name: "",
                    form_link: "",
                  })
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center items-center my-8">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {/* Conditionally Render the Table Only When Not Loading */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-2 px-4 border">S.No</th>
                <th className="py-2 px-4 border">Poster</th>
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Form Link</th>
                <th className="py-2 px-4 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {posters.length > 0 ? (
                [...posters]
                  .reverse()
                  .map((poster, index) => (
                    <tr key={poster.id} className="text-center hover:bg-gray-100">
                      <td className="py-2 px-4 border">{index + 1}</td>
                      <td className="py-2 px-4 border flex justify-center">
                        {poster.poster && poster.poster.data ? (
                          <img
                            src={`data:image/png;base64,${bufferToBase64(new Uint8Array(poster.poster.data))}`}
                            alt={poster.name}
                            className="w-16 h-16 object-cover rounded"
                          />
                        ) : (
                          "No Image"
                        )}
                      </td>
                      <td className="py-2 px-4 border">{poster.name}</td>
                      <td className="py-2 px-4 border">
                        <a href={poster.form_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                          View Form
                        </a>
                      </td>
                      <td className="py-2 px-4 border space-x-2">
                        <HasPermission permissionName={2} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => handleView(poster)}
                            className="bg-gray-300 text-black px-2 py-1 rounded hover:bg-gray-400 border-none hover:text-black"
                          >
                            View
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={3} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openUpdateModal(poster)}
                            className="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 border-none hover:text-white"
                          >
                            Update
                          </button>
                        </HasPermission>
                        <HasPermission permissionName={4} permission={action_ids} hideOrDisable={"hide"}>
                          <button
                            onClick={() => openDeleteModal(poster)}
                            className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 border-none hover:text-white"
                          >
                            Delete
                          </button>
                        </HasPermission>
                      </td>
                    </tr>
                  ))
              ) : (
                !isLoading && ( // Ensure "No posters available" shows only when not loading
                  <tr>
                    <td colSpan="5" className="text-center py-4">No posters available</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* View Modal */}
      {isViewOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/2">
            <h2 className="text-xl mb-4">View Poster</h2>
            {currentPoster && (
              <div>
                {currentPoster.poster && currentPoster.poster.data ? (
                  <img
                    src={`data:image/png;base64,${bufferToBase64(new Uint8Array(currentPoster.poster.data))}`}
                    alt={currentPoster.name}
                    className="w-full h-64 object-cover mb-4 rounded"
                  />
                ) : (
                  <p className="mb-4">No Image Available</p>
                )}
                <p className="mb-2"><strong>Name:</strong> {currentPoster.name}</p>
                <p className="mb-4">
                  <strong>Form Link:</strong>{" "}
                  <a href={currentPoster.form_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    {currentPoster.form_link}
                  </a>
                </p>
              </div>
            )}
            <button
              onClick={() => setIsViewOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 border-none hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Update Modal */}
      {isUpdateOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-3/4">
            <h2 className="text-xl mb-4">Update Poster</h2>
            <form onSubmit={handleUpdatePoster}>
              <label className="block mb-2">Poster Image<span class="text-red-500">*</span></label>
              <input
                type="file"
                name="poster"
                accept="image/*"
                onChange={handleChange}
                className="border p-2  w-full"
              />
              <small className="mb-5">Only JPEG and PNG images under 5MB are allowed.</small>
              <label className="block mb-2 mt-3">Name<span class="text-red-500">*</span></label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="border p-2 mb-4 w-full"
              />
              <label className="block mb-2">Form Link<span class="text-red-500">*</span></label>
              <input
                type="url"
                name="form_link"
                placeholder="Form Link"
                value={formData.form_link}
                onChange={handleChange}
                required
                className="border p-2 mb-4 w-full"
              />
              <div className="flex space-x-2">
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 hover:text-white border-none"
                >
                  Update
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsUpdateOpen(false)
                    setFormData({
                      poster: null,
                      name: "",
                      form_link: "",
                    })
                  }

                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-1/3">
            <h2 className="text-xl mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this poster?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={handleDeletePoster}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 hover:text-white border-none"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setIsDeleteOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 hover:text-white border-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Poster;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const generateAccessToken = createAsyncThunk(
  "login/generateAccessToken",
  async function generateAccessToken({ unProtectedCall, data }) {
    const response = await unProtectedCall("/api/auth/login", data, "post");
      
    // Return response directly, including success and tokens
    return {
      accessToken: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      success: response.success,
      action_id: response.data.payload.action_ids,
      role_name:response.data.payload.user_type,
      role_id:response.data.payload.role_id
    };
  }
);

// console.log("succes status",success)
const initialState = {
  // loggedUser: { role: null, action_ids:[]},
  role: null,
  roleId:null,
  action_ids :[],
  accessToken: null,
  refreshToken: null,
  success: false,
  status: "idle", 
  error: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateTokens(state, action) {
    
      state.accessToken = action.payload.data.accessToken;
    },
    addLogindetails(state, action) {
      state.loggedUser = action.payload;
    },
    deleteDetails(state) {
      return initialState
    },
    logout(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateAccessToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.role = action.payload.role_name 
      state.roleId=action.payload.role_id
      state.action_ids = action.payload.action_id

      state.success = action.payload.success; 
    
      state.status = "idle";
    });
    builder.addCase(generateAccessToken.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(generateAccessToken.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.success = false; // Reset success flag on failure
      console.log(action.error)
    });
  },
});

// Selectors
export const selectLoginStatus = (state) => state.login.status;
export const selectLoginError = (state) => state.login.error;

export const { addLogindetails, deleteDetails, updateTokens , logout } = loginSlice.actions;
export const selectLoggedUser = (state) => state.login.loggedUser;

export default loginSlice.reducer;

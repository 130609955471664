import React, { useEffect, useRef } from "react";
import HeroAbout from "../../../components/about/HeroAbout";
import TeamELiNA from "../../../components/about/TeamElina";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import pic2 from "../../../images/about/team2.png";
import pic3 from "../../../images/about/team3.png";
import mem1 from "../../../images/about/mem1.png";
import mem2 from "../../../images/about/mem22.jpeg";
import mem4 from "../../../images/about/mem4.jpeg";
import mem5 from "../../../images/about/mem5.jpeg";
import mem6 from "../../../images/about/mem7.jpg";
import mem7 from "../../../images/about/mem8.jpeg";
import mem9 from "../../../images/about/mem9.jpeg";
import StoryOfELiNA from "../../../components/about/StoryOfElina";
import story1 from "../../../images/about/storyofelina/compressed/four.jpg";
import story2 from "../../../images/about/storyofelina/compressed/two.jpg";
import story3 from "../../../images/about/storyofelina/compressed/story (3).jpg";
import story4 from "../../../images/about/storyofelina/compressed/one.jpg";
import ScrollToTop from "react-scroll-to-top";



const storyOfELiNA = [
  {
    src: story4,
    p: "ELiNA emerged from a decade of extensive knowledge gathering and real-life experiences within HLC, inspired by the visionary leadership of Mr. Naveen Mahesh and Mrs. Raaji Naveen, and driven by Ramalakshmi's passion. Officially established as a separate organization in 2020, ELiNA is dedicated to supporting neurodivergent children—those with autism, ADHD, and learning disabilities—and their families.",
  },
  {
    src: story3,
    p: "Ramalakshmi identified two critical gaps in the intervention system: the lack of communication between professionals and the lack of clarity among parents. To bridge these gaps, she founded an advisory and referral center, which quickly became a beacon of hope for families navigating the challenges of raising neurodivergent children. The center's impact grew as it conducted assessments and training programs, providing educators and parents with strategies to create inclusive environments.",
    ImgDirection: "left",
  },
  {
    src: story2,
    p: "The COVID-19 pandemic highlighted the power of technology to overcome physical barriers. ELiNA leveraged this potential, unveiling groundbreaking programs like SAIL and CoMPASS, designed to offer comprehensive support through technology-driven assessments and personalized intervention plans. Collaborations with schools, therapy centers, and pediatric units, alongside the development of a custom-designed Intervention Services Management System (ISMS), led to the creation of a web portal and boosted their social media presence.",
  },
  {
    src: story1,
    p: "Achieving legal status as Vimarshi Solutions Private Limited solidified ELiNA's commitment to fostering a more inclusive society. Today, ELiNA continues to empower neurodivergent children and their families, ensuring every child can reach their full potential in a world where neurodivergence is celebrated.",
    ImgDirection: "left",
  },
];

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );

  return (
    <>
      <HeroAbout />
      <StoryOfELiNA storyOfELiNA={storyOfELiNA} />
      <TeamELiNA
        // teamELiNAContent={teamELiNAContent}
        // teamELiNAContent2={teamELiNAContent2}
      />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#0048ff",
          display: "flex",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          bottom: "70px",
          right: "23px",
          width: "40px",
          height: "40px",
        }}
        component={<YourCustomArrowIcon />}
      />
    </>
  );
};

export default About;

import React from "react";

const JoinUs = () => {
  return (
    <>
      <div className="flex text-justify flex-col justify-center items-center py-28 gap-10 HeroBg2">
        <h1 className="text-4xl md:text-4xl lg:text-6xl text-center  font-bold text-blue-700 ">
        Join Elina and Empower Your Child’s Journey
        </h1>
        <p className="md:text-xl text-base px-5 md:px-10  lg:px-[14%] ">
        Navigating the world of neurodivergence can be challenging, but you don’t have to do it alone. At Elina, we provide expert guidance, resources, and support designed specifically for parents of children with autism, ADHD, and learning differences. Our personalized approach ensures that each family receives tailored support to help their child reach their fullest potential.
        </p>
        <p className="md:text-lg text-base  px-5 md:px-10 lg:px-[14%] ">
        Discover how Elina can make a meaningful difference in your child's academic, social, and emotional growth. Join us today to start your journey towards a brighter future!
        </p>
        {/* <p className="md:text-base  text-sm px-5 md:px-10 lg:px-[14%] cursor-pointer  font-bold ">
          Contact us today to learn more about how you can collaborate with
          ELiNA and contribute to a comprehensive and effective support system
          for children and their families.
        </p> */}
        <a href="/#/parent-form">
          <button className="lg:py-3 lg:px-10 bg-pink-600 border-pink-600 text-xl lg:text-2xl text-white hover:bg-transparent hover:border-pink-600 hover:text-pink-600 py-2 px-4 rounded-full">
            Join Us!
          </button>
        </a>
      </div>
    </>
  );
};

export default JoinUs;

import React from "react";

const ContactDetails = () => {
  return (
    <div className="HeroBg h-fit flex justify-center items-center px-4 lg:px-6">
      <div className="flex flex-col lg:flex-row w-full max-w-screen-xl mx-auto space-y-10 lg:space-y-0 lg:space-x-10 lg:my-32 md:my-24 my-24">
        
        <div className="lg:w-1/2 w-full h-64 lg:h-auto flex justify-center lg:justify-end">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12982.625815308884!2d80.2280795!3d12.9262464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525c5ed050e739%3A0x60bb20fe970f626f!2sElina%20Services!5e1!3m2!1sen!2sin!4v1731050920788!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="w-full h-full rounded-lg shadow-lg"
          ></iframe>
        </div>

        <address className="lg:w-1/2 w-full flex flex-col justify-start text-left lg:text-left gap-8 not-italic">
          <p><span className="font-bold text-base lg:text-lg text-blue-600">Email: </span>info@elinaservices.in</p>
          <p>
            <span className="font-bold text-base lg:text-lg text-blue-600">Location: </span>
            C1 - 301, Pelican Nest, Creek Street, OMR Chennai Tamil Nadu 600097 India.
          </p>
          <p>
            <span className="font-bold text-base lg:text-lg text-blue-600">Phone: </span>
            9841050686, +91 984 079 4434
          </p>
          <p>
            <span className="font-bold text-base lg:text-lg text-blue-600">Office hours: </span>
            <br />
            Business Hours: Monday to Friday: 9 AM - 5 PM <br />
            Saturday and Sunday: Closed
          </p>
          
          {/* Social Media Links */}
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/ELiNA.Edu?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              title="Follow us on Facebook"
              className="bg-blue-600 py-1 px-2 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="32"
                viewBox="0 0 32 32"
                fill="white"
                className="cursor-pointer "
              >
                <path d="M16,2c-7.732,0-14,6.268-14,14,0,6.566,4.52,12.075,10.618,13.588v-9.31h-2.887v-4.278h2.887v-1.843c0-4.765,2.156-6.974,6.835-6.974,.887,0,2.417,.174,3.043,.348v3.878c-.33-.035-.904-.052-1.617-.052-2.296,0-3.183,.87-3.183,3.13v1.513h4.573l-.786,4.278h-3.787v9.619c6.932-.837,12.304-6.74,12.304-13.897,0-7.732-6.268-14-14-14Z"></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/ELiNAservices?igsh=dWVjY3lrZXp2N3Z5"
              target="_blank"
              rel="noopener noreferrer"
              title="Follow us on Instagram"
              className="bg-blue-600 py-1 px-2 rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="32"
                viewBox="0 0 32 32"
                fill="white"
                className="cursor-pointer"
              >
                <path d="M10.202,2.098c-1.49,.07-2.507,.308-3.396,.657-.92,.359-1.7,.84-2.477,1.619-.776,.779-1.254,1.56-1.61,2.481-.345,.891-.578,1.909-.644,3.4-.066,1.49-.08,1.97-.073,5.771s.024,4.278,.096,5.772c.071,1.489,.308,2.506,.657,3.396,.359,.92,.84,1.7,1.619,2.477,.779,.776,1.559,1.253,2.483,1.61,.89,.344,1.909,.579,3.399,.644,1.49,.065,1.97,.08,5.771,.073,3.801-.007,4.279-.024,5.773-.095s2.505-.309,3.395-.657c.92-.36,1.701-.84,2.477-1.62s1.254-1.561,1.609-2.483c.345-.89,.579-1.909,.644-3.398,.065-1.494,.081-1.971,.073-5.773s-.024-4.278-.095-5.771-.308-2.507-.657-3.397c-.36-.92-.84-1.7-1.619-2.477s-1.561-1.254-2.483-1.609c-.891-.345-1.909-.58-3.399-.644s-1.97-.081-5.772-.074-4.278,.024-5.771,.096m.164,25.309c-1.365-.059-2.106-.286-2.6-.476-.654-.252-1.12-.557-1.612-1.044s-.795-.955-1.05-1.608c-.192-.494-.423-1.234-.487-2.599-.069-1.475-.084-1.918-.092-5.656s.006-4.18,.071-5.656c.058-1.364,.286-2.106,.476-2.6,.252-.655,.556-1.12,1.044-1.612s.955-.795,1.608-1.05c.493-.193,1.234-.422,2.598-.487,1.476-.07,1.919-.084,5.656-.092,3.737-.008,4.181,.006,5.658,.071,1.364,.059,2.106,.285,2.599,.476,.654,.252,1.12,.555,1.612,1.044s.795,.954,1.051,1.609c.193,.492,.422,1.232,.486,2.597,.07,1.476,.086,1.919,.093,5.656,.007,3.737-.006,4.181-.071,5.656-.06,1.365-.286,2.106-.476,2.601-.252,.654-.556,1.12-1.045,1.612s-.955,.795-1.608,1.05c-.493,.192-1.234,.422-2.597,.487-1.476,.069-1.919,.084-5.657,.092s-4.18-.007-5.656-.071M21.779,8.517c.002,.928,.755,1.679,1.683,1.677s1.679-.755,1.677-1.683c-.002-.928-.755-1.679-1.683-1.677,0,0,0,0,0,0-.928,.002-1.678,.755-1.677,1.683m-12.967,7.496c.008,3.97,3.232,7.182,7.202,7.174s7.183-3.232,7.176-7.202c-.008-3.97-3.233-7.183-7.203-7.175-3.97,.008-7.182,3.232-7.175,7.202m2.756,.008c-.004-2.45,1.986-4.446,4.435-4.45,2.45-.004,4.447,1.986,4.451,4.435,.004,2.45-1.986,4.447-4.435,4.451s-4.447-1.986-4.451-4.435"></path>
              </svg>
            </a>
            <a
                href="https://www.youtube.com/@ELiNAservices"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 py-1 px-2 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M31.331,8.248c-.368-1.386-1.452-2.477-2.829-2.848-2.496-.673-12.502-.673-12.502-.673,0,0-10.007,0-12.502,.673-1.377,.37-2.461,1.462-2.829,2.848-.669,2.512-.669,7.752-.669,7.752,0,0,0,5.241,.669,7.752,.368,1.386,1.452,2.477,2.829,2.847,2.496,.673,12.502,.673,12.502,.673,0,0,10.007,0,12.502-.673,1.377-.37,2.461-1.462,2.829-2.847,.669-2.512,.669-7.752,.669-7.752,0,0,0-5.24-.669-7.752ZM12.727,20.758V11.242l8.364,4.758-8.364,4.758Z"></path>
                </svg>
              </a>
              <a
                href="https://x.com/ELiNA_service?t=XPIZjyRN0-GxJDrjAkKftw&s=09"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 py-1 px-2 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path d="M18.42,14.009L27.891,3h-2.244l-8.224,9.559L10.855,3H3.28l9.932,14.455L3.28,29h2.244l8.684-10.095,6.936,10.095h7.576l-10.301-14.991h0Zm-3.074,3.573l-1.006-1.439L6.333,4.69h3.447l6.462,9.243,1.006,1.439,8.4,12.015h-3.447l-6.854-9.804h0Z"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/ELiNA-services/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 py-1 px-2 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="white"
                  className="cursor-pointer"
                >
                  <path
                    d="M26.111,3H5.889c-1.595,0-2.889,1.293-2.889,2.889V26.111c0,1.595,1.293,2.889,2.889,2.889H26.111c1.595,0,2.889-1.293,2.889-2.889V5.889c0-1.595-1.293-2.889-2.889-2.889ZM10.861,25.389h-3.877V12.87h3.877v12.519Zm-1.957-14.158c-1.267,0-2.293-1.034-2.293-2.31s1.026-2.31,2.293-2.31,2.292,1.034,2.292,2.31-1.026,2.31-2.292,2.31Zm16.485,14.158h-3.858v-6.571c0-1.802-.685-2.809-2.111-2.809-1.551,0-2.362,1.048-2.362,2.809v6.571h-3.718V12.87h3.718v1.686s1.118-2.069,3.775-2.069,4.556,1.621,4.556,4.975v7.926Z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </a>
          </div>
        </address>
      </div>
    </div>
  );
};

export default ContactDetails;

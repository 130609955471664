import React, { useEffect, useRef } from "react";
import ServiceProviderHero from "../../../components/forms/serviceProvider/ServiceProviderHero";
import ServiceProviderForm from "../../../components/forms/serviceProvider/ServiceProviderForm";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";

const ServiceProviderPageForm = () => {
  // Create a ref for the ServiceProviderForm component
  const formRef = useRef(null);

  useEffect(() => {
    // Scroll to the ServiceProviderForm component when the component mounts
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <ServiceProviderHero />
      {/* Attach the ref to a wrapper element around ServiceProviderForm */}
      <div ref={formRef}>
        <ServiceProviderForm />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ServiceProviderPageForm;

import React from "react";

const ParentContent = [
  {
    key: 1,
    h1: "Log in to ISMS",
    p: "Begin your journey by logging into the Intervention Service Management System (ISMS). Ensure you complete the registration process and pay any necessary fees to activate your account.",
  },
  {
    key: 2,
    h1: "Schedule appointment",
    p: "Once registered, schedule an appointment with our team. This meeting is designed to give an overview of our process and services. During this time.",
  },
  {
    key: 3,
    h1: "Meet 1 - About the child",
    p: "This meeting focuses on understanding your child. You will have the opportunity to share detailed information about your child's strengths.",
  },
  {
    key: 4,
    h1: "Meet 2 - About ELiNA",
    p: "This session provides comprehensive information about ELiNA’s programs and how they can benefit your child.",
  },
  {
    key: 5,
    h1: "Enrollment decision",
    p: "Based on the information shared and discussed in previous meetings, you can make an informed decision about enrolling your child in our programs. ",
  },
  {
    key: 6,
    h1: "SAIL process",
    p: "Once your child is enrolled, we will guide you through the SAIL (Support and Inclusion Learning) process. ",
  },
  {
    key: 7,
    h1: "In-depth Interactions",
    p: "ELiNA conducts in-depth interactions with the child and the family to better understand their needs.",
  },
  {
    key: 8,
    h1: "Reports",
    p: "We provide comprehensive assessment reports that include recommendations and referrals if needed.",
  },
  {
    key: 9,
    h1: "CoMPASS",
    p: "Enroll in the CoMPASS program to track your child's development and progress over time.",
  },
];

const ParentSteps = () => {
  return (
    <>
      <div className="HeroBg2">
        <div className="flex flex-col items-center justify-center py-[5%]  px-[5%] lg:px-[8%]">
          <h1 className="text-center text-4xl md:text-6xl lg:text-7xl font-bold text-blue-700">
            Getting started is simple
          </h1>
          <div className="flex items-center ">
            <div className="flex lg:flex-row flex-col w-full gap-5 flex-wrap  justify-center text-center py-10">
              {ParentContent.map((item, index) => (
                <div className="lg:w-[32%] w-full flex flex-col gap-5 bg-blue-600 bg-opacity-10 rounded-3xl p-10 justify-between">
                  <div className="lg:h-14 md:h-10">
                    <h1 className=" text-blue-600 text-4xl font-black">
                      {index + 1}
                    </h1>
                  </div>
                  <div className="lg:h-16 md:h-20">
                    <h1 className="text-2xl font-bold">{item.h1}</h1>
                  </div>
                  <div className="lg:h-32 md:h-20">
                    <p className="text-lg">{item.p}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParentSteps;

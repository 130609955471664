// src/components/Events.jsx

import React, { useState, useEffect } from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  // Fetch events from API
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/events`);
        setEvents(response.data);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div
      onClick={() => navigate("/events")}
      className="w-full h-10 justify-center cursor-pointer bg-pink-600 items-center flex text-white"
    >
      <div className="scroll w-full">
        <div className="RightToLeft">
          {events.length > 0 ? (
            events.map((event, index) => (
              <React.Fragment key={event.id || index}>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                  {event.description }
                </p>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                  {event.event_name }
                </p>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                  {event.venue }
                </p>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                  {event.time }
                </p>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                {formatDate(event.date)}
                </p>
                <p className="font-semibold lg:text-xl md:text-lg text-base">
                  {event.location }
                </p>
              </React.Fragment>
            ))
          ) : (
            <p className="font-semibold lg:text-xl md:text-lg text-base">
              No upcoming events.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Events;

import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Dropdown } from "primereact/dropdown"; // Use Dropdown instead of MultiSelect
import { protectedCall } from "../../../services/userService";
import TextInput from "../../../components/form/text/TextInput";

import styles from "./styles.module.css";

export default function AddUserCreation() {
  const [roles, setRoles] = useState([]); // State for storing roles
  const [selectedRole, setSelectedRole] = useState(null); // State for storing selected role id

  const navigate = useNavigate("");
  const { register, handleSubmit } = useForm();
  const toast = useRef(null);

  useEffect(() => {
    // Fetch roles from the API
    const fetchRoles = async () => {
      try {
        const response = await protectedCall("/api/roles");
        setRoles(response); // Assuming the API returns an array of roles
      } catch (e) {
        console.error(e.message);
      }
    };

    fetchRoles();
  }, []);

  const showInfo = (info) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: info,
      life: 3000,
    });
  };

  const showError = (errorMsg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMsg,
      life: 3000,
    });
  };

  const handleAdd = async (data) => {
    try {
      const selectedRoleData = roles.find((role) => role.id === selectedRole);

      const transformed = {
        name: data.name,
        email_id: data.email_id,
        password: data.password,
        phone_number: data.phone_number,
        gender: data.gender,
        user_type: selectedRoleData?.name,
        role_id: selectedRole,
      };

      console.log("formdata", transformed);
      await protectedCall("/api/auth/create-user", transformed, "post");
      showInfo("User Added Successfully");
      navigate("/admin/user")

    } catch (e) {
      showError(e?.message);
    }
  };

  const onError = (e) => {
    let count = 0;

    [e?.role, e?.description].forEach((elem) => {
      if (elem && count === 0) {
        toast.current.clear();
        const errorMsg = elem?.message;
        showError(errorMsg);
        count++;
      }
    });
  };

  return (
    <>
      <div className="w-full flex justify-center ">
        <div className="w-9/12 pt-10 gap-2">
          <form onSubmit={handleSubmit(handleAdd, onError)}>
            <div className="gap-6">
              <div className="ml-2 pb-4">
                <TextInput
                  id="name"
                  name="name"
                  register={register}
                  labelText={"Name"}
                  required={"Enter Name"}
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="email_id"
                  name="email_id"
                  register={register}
                  labelText={"Email Id"}
                  required={"Enter Your Email id"}
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="password"
                  name="password"
                  register={register}
                  labelText={"Password"}
                  required={"Enter Your Password"}
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="gender"
                  name="gender"
                  register={register}
                  labelText={"Gender"}
                  required={"Enter gender"}
                />
              </div>
              <div className="ml-2 pb-4">
                <TextInput
                  id="phone_number"
                  name="phone_number"
                  register={register}
                  labelText={"Phone Number"}
                  required={"Enter Your Phone number"}
                />
              </div>

              {/* Display role dropdown (single select) */}
              <div className="ml-2 pb-4">
                <label>Role:</label>
                {roles.length > 0 ? (
                  <Dropdown
                    value={selectedRole}
                    options={roles.map((role) => ({ label: role.name, value: role.id }))}
                    onChange={(e) => setSelectedRole(e.value)}
                    placeholder="Select Role"
                    className="w-full md:w-14rem border-black border-1 bg-blue"
                  />
                ) : (
                  <p>Loading roles...</p>
                )}
              </div>
            </div>

            <div
              className={styles.menuWrapper}
              style={{
                height: "69vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="flex gap-5">
                <button className="p-2 bg-blue-500 border-none hover:text-blue-500 ">
                  Add User
                </button>
                <button
                  className="p-2 bg-blue-500 border-none hover:text-blue-500"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Toast ref={toast} />
    </>
  );
}

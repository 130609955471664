import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import user from "../../images/blog/blogHero/girl.png"; // Assuming this is your default user image

const Testimonial = () => {
  const [carouselContent, setCarouselContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/testimonials_type/home page`
        );
        const activeTestimonials = response.data;
        setCarouselContent(activeTestimonials);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Navigate to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselContent.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Navigate to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselContent.length - 1 : prevIndex - 1
    );
  };

  const hasPrevSlide = currentIndex > 0;
  const hasNextSlide =
    carouselContent.length > 1 && currentIndex < carouselContent.length - 1;

  return (
    <div className="lg:max-w-full">
      <p className="text-4xl p-5 text-center mt-24 mb-10 md:text-5xl lg:text-6xl font-black text-blue-600">
        Voices of our community
      </p>
      <div className="flex justify-center lg:gap-20">
        <button className="bg-transparent border-none" onClick={prevSlide}>
          <ArrowLeftIcon className={`w-5 ${hasPrevSlide ? "text-gray-600" : "text-white"}`} />
        </button>

        {carouselContent.map((item, index) => {
          // Check if item.image and item.image.data exist
          const imgSrc = item.image && item.image.data
            ? `data:image/jpeg;base64,${btoa(
              new Uint8Array(item.image.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
            )}`
            : user; // Fallback to 'user' image if image data is not available

          return (
            <div
              key={item.id}
              className={`max-w-lg ${index === currentIndex ? "" : "hidden"} transition-opacity duration-500 flex flex-col items-center h-[100%] bg-pink-600 shadow-2xl lg:p-10 rounded-3xl`}
            >
              <div className="lg:h-[300px] md:p-2 py-10 flex flex-col justify-center items-center">
                <h1 className="text-center text-white md:text-base p-5">
                  {item.content || "Error on fetching data..."}
                </h1>
              </div>
              <p className="text-center text-white font-semibold md:text-lg p-5 flex items-center">
                <img
                  src={imgSrc}
                  className="md:w-7 w-11 mr-2"
                  alt="user"
                />
                {item.name || "Error on fetching data..."}
              </p>
            </div>
          );
        })}


        <button className="bg-transparent border-none" onClick={nextSlide}>
          <ArrowRightIcon className={`w-5 ${hasNextSlide ? "text-gray-600" : "text-white"}`} />
        </button>
      </div>

      {/* Dots Indicator */}
      <div className="flex justify-center mt-8">
        {carouselContent.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-2 w-2 mx-4 border-none rounded-full ${index === currentIndex
              ? "bg-gray-700"
              : "bg-gray-400 hover:bg-gray-500"
              }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;

import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../../reuseable/BreadCrumbs";

import { internPageForm } from "../../schemas/formSchemas";
import axios from "axios";
import SkeletonLoader from "../../reuseable/SkeletonLoader";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_FORMS_URL;

const serviceProviderContent1 = [
  {
    li: "Hybrid",
  },
  {
    li: "Speech Therapy",
  },
  {
    li: "Special Education",
  },
  {
    li: "Physical Trainer",
  },
  {
    li: "Art Therapy",
  },
  {
    li: "Music Therapy",
  },
  {
    li: "Physiotherapy",
  },
  {
    li: "Yoga Therapy",
  },
  {
    li: "Other",
  },
];

const serviceProviderContent2 = [
  {
    li: "Online",
  },
  {
    li: "Offline",
  },
  {
    li: "Hybrid",
  },
];

const serviceProviderContent3 = [
  {
    li: "Diploma",
  },
  {
    li: "Bachelor",
  },
  {
    li: "Master",
  },
  {
    li: "Phd",
  },
];

const serviceProviderContent4 = [
  {
    li: "I shall provide services to the child referred by ELiNA according to my availability.",
  },
  {
    li: "I shall share reports and other observations about the child with ELiNA.",
  },
  {
    li: "I shall seek information from ELiNA about the child.",
  },
  {
    li: "I shall adopt an open door policy with the parents so as to enable and empower them to be part of their child’s progress.",
  },
  {
    li: "I shall operate on trust and transparency with ELiNA.",
  },
  {
    li: "I do not have any financial arrangements with ELiNA and all my professional charges towards providing service to the child will be charged to the Parents/ Guardian.",
  },
];

const InternForm = () => {
  const formRef = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const currentDate = new Date().toISOString().split("T")[0];
  // const handleFileChange = (event) => {
  //   const name = event.target.id;
  //   const file = event.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     // Set the file to formik values
  //     formik.setFieldValue(name, file);
  //   }
  // };

 

  const handleFileChange = (event) => {
    const name = event.target.id;
    const file = event.target.files[0];

    // Allowed file types
    const allowedFormats = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // Check if a file is selected
    if (file) {
      // Validate file size
      if (file.size > 2 * 1024 * 1024) {
        // Set error for the specific field
        formik.setFieldError(name, "File size should be less than 2MB");
        return;
      }

      // Validate file type
      if (!allowedFormats.includes(file.type)) {
        // Set error for the specific field
        formik.setFieldError(name, "Only PDF and DOCX files are allowed");
        return;
      }

      // If file passes validations, set it to Formik values
      formik.setFieldValue(name, file);
    }
  };

  const formik = useFormik({
    initialValues: {
      internName: "",
      phoneNumber: "",
      email: "",
      dob: "",
      parentName: "",
      internDob: "",
      workinghour: "",
      agree: false,
      captcha: "",
      shortAboutELiNA: null,
      knowAboutELiNA: null,
      lookingAboutELiNA: null,
    },
    validationSchema: internPageForm,
    onSubmit: async (values, actions) => {
      setLoading(true);
      setSubmissionStatus(null);

      const formData = new FormData();

      formData.append("name", values.internName);
      formData.append("contact_number", values.phoneNumber);
      formData.append("email_address", values.email);
      formData.append("date_of_birth", values.dob);
      formData.append("parent_guardian_contact_number", values.parentName);
      formData.append("start_date_with_elina", values.internDob);
      formData.append("hours_intern_ELiNA_per_week", values.workinghour);
      formData.append("short_introduction", values.shortAboutELiNA);
      formData.append("about_elina", values.knowAboutELiNA);
      formData.append("intern_with_elina", values.lookingAboutELiNA);
      formData.append("agreement", values.agree ? "Agreed":"Declined");
      formData.append("g-recaptcha-response", values.captcha);
      formData.append("react_web", "true");

      // console.log(formData);

      try {
        // isSubmitting(true);
        const response = await axios.post(
          // "https://onlineappointment.onrender.com/internship",
          // "http://183.83.188.205:60161/internship",
          `${apiUrl}/internship/new/store`,
          formData
        );
        // alert("Form submitted successfully");
        setSubmitting(false);
        setSubmissionStatus("success");

        actions.resetForm();
      } catch (error) {
        if (error.response.data.code === 400) {
          setSubmissionStatus("emailerr");
        } else setSubmissionStatus("error");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
    // console.log("value : ", value);
    formik.setFieldValue("captcha", value);
  };

  useEffect(() => {
    if (submissionStatus === "success") {
      toast.success("Internship form has been submitted successfully!", {
        position: "top-center",
        className: "text-white",
      });
    } else if (submissionStatus === "emailerr") {
      toast.error(
        "The provided email address has already been registered. Please log in using this email or register with a different email address.",
        {
          position: "top-center",
          className: "text-red-500 bg-white",
        }
      );
    } else if (submissionStatus === "error") {
      toast.error(
        "There was an error submitting the form. Please contact admin.",
        {
          position: "top-center",
          className: "text-red-500 bg-white",
        }
      );
    }
  }, [submissionStatus]);

  return (
    <>
      <div className="md:ml-14 md:pt-5">
        <BreadCrumbs />
      </div>
      {loading ? (
        <>
          <SkeletonLoader itemCount={6} />{" "}
        </>
      ) : (
        <div
          class="flex items-center justify-center py-10 md:px-5 lg:p-12"
          ref={formRef}
        >
          <div class="mx-auto w-full max-w-[90%] md:max-w-[100%] lg:max-w-[80%] bg-gray-200 rounded-3xl p-10">
            <form
              onSubmit={(e) => {
                formik.handleSubmit(e);
                formRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <div className="step1">
                {/* Personal Details */}
                <>
                  <h1 className="text-center font-black text-3xl md:text-5xl text-blue-950 pb-8 ">
                    Internship form
                  </h1>
                  {/* GENERAL INFORMATION */}
                  <>
                    <div class="mb-5 pt-3">
                      <h1 className="font-black text-2xl text-[#07074D] mb-5">
                        Personal details<span className="text-red-500">*</span>
                      </h1>
                      <div class="-mx-3 flex flex-wrap">
                        {/* NAME */}
                        <div class="px-3 w-full md:w-1/2">
                          <div class="mb-5">
                            <label class="mb-3 block text-base font-medium text-[#07074D]">
                              Name<span className="text-red-500">*</span>
                            </label>
                            <input
                              type="text"
                              id="internName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.internName}
                              placeholder="Enter your name"
                              class={`${
                                formik.errors.internName &&
                                formik.touched.internName
                                  ? "border border-red-600"
                                  : " "
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.internName &&
                              formik.touched.internName && (
                                <p className="text-sm font-semibold text-red-500">
                                  {formik.errors.internName}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* D.O.B */}
                        <div class="w-full px-3 sm:w-1/2 ">
                          <label class="mb-3 block text-base font-medium text-[#07074D]">
                            Date of birth<span className="text-red-500">*</span>
                          </label>
                          <div class="mb-5">
                            <input
                              type="date"
                              id="dob"
                              max={currentDate}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.dob}
                              placeholder="D.O.B"
                              class={`${
                                formik.errors.dob && formik.touched.dob
                                  ? "border border-red-600"
                                  : ""
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.dob && formik.touched.dob ? (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.dob}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* PHONE NUMBER */}
                        <div class="px-3 w-full md:w-1/2">
                          <div class="mb-5">
                            <label class="mb-3 block text-base font-medium text-[#07074D]">
                              Phone number
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="number"
                              id="phoneNumber"
                              onBlur={formik.handleBlur}
                              onChange={(e)=>{if(e.target.value.length<=10)formik.handleChange(e)}}
                              value={formik.values.phoneNumber}
                              placeholder="Phone Number"
                              class={`${
                                formik.errors.phoneNumber &&
                                formik.touched.phoneNumber
                                  ? "border border-red-600"
                                  : " "
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.phoneNumber &&
                              formik.touched.phoneNumber && (
                                <p className="text-sm font-semibold text-red-500">
                                  {formik.errors.phoneNumber}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* PARENT NAME */}
                        <div class="w-full px-3 sm:w-1/2 mb-5">
                          <label
                            for="name"
                            class="mb-3 block text-base font-medium text-[#07074D]"
                          >
                            Phone Number of the parents/guardian
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="number"
                            id="parentName"
                            onChange={(e)=>{if(e.target.value.length<=10)formik.handleChange(e)}}
                            onBlur={formik.handleBlur}
                            value={formik.values.parentName}
                            placeholder="Phone Number of the Parents/Guardian"
                            class={`${
                              formik.errors.parentName &&
                              formik.touched.parentName
                                ? "border border-red-600"
                                : ""
                            } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                          />
                          {formik.errors.parentName &&
                          formik.touched.parentName ? (
                            <p className="text-sm font-semibold text-red-500">
                              {formik.errors.parentName}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                  <>
                    <div class="mb-5 pt-3">
                      <h1 className="font-black text-2xl text-[#07074D] mb-5">
                        Additional details
                        <span className="text-red-500">*</span>
                      </h1>
                      <div class="-mx-3 flex flex-wrap">
                        {/* When Could You Start Interning with ELiNA? */}
                        <div class="w-full px-3 sm:w-1/2 ">
                          <label class="mb-3 block text-base font-medium text-[#07074D]">
                            When could you start interning with ELiNA?
                            <span className="text-red-500">*</span>
                          </label>
                          <div class="mb-5">
                            <input
                              type="date"
                              id="internDob"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.internDob}
                              placeholder="D.O.B"
                              class={`${
                                formik.errors.internDob &&
                                formik.touched.internDob
                                  ? "border border-red-600"
                                  : ""
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.internDob &&
                            formik.touched.internDob ? (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.internDob}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {/* How Many Hours Can you Intern with ELiNA per week */}
                        <div class="px-3 w-full md:w-1/2">
                          <div class="mb-5">
                            <label class="mb-3 block text-base font-medium text-[#07074D]">
                              How many hours can you intern with ELiNA per week
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="tel"
                              id="workinghour"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.workinghour}
                              placeholder="Enter working hours"
                              class={`${
                                formik.errors.workinghour &&
                                formik.touched.workinghour
                                  ? "border border-red-600"
                                  : " "
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.workinghour &&
                              formik.touched.workinghour && (
                                <p className="text-sm font-semibold text-red-500">
                                  {formik.errors.workinghour}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* EMAIL ADDRESS */}
                        <div class="px-3 w-full md:w-1/2">
                          <div class="mb-5">
                            <label class="mb-3 block text-base font-medium text-[#07074D]">
                              Email address
                              <span className="text-red-500">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              placeholder="Email Address"
                              class={`${
                                formik.errors.email && formik.touched.email
                                  ? "border border-red-600"
                                  : " "
                              } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                            />
                            {formik.errors.email && formik.touched.email && (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </>

                <>
                  <div className="mb-5 pt-3">
                    <h1 className="font-black text-2xl text-[#07074D] mb-5">
                      Acknowledgement<span className="text-red-500">*</span>
                    </h1>
                    <div className="-mx-3 flex flex-wrap ">
                      {/* A Short Introduction about Yourself? */}
                      <div class="px-3 w-full md:w-1/2 mb-5">
                        <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-[#07074D]">
                            A Short Introduction about Yourself?
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            id="shortAboutELiNA"
                            onBlur={formik.handleBlur}
                            onChange={handleFileChange}
                            accept=".pdf"
                            class={`${
                              formik.errors.shortAboutELiNA &&
                              formik.touched.shortAboutELiNA
                                ? "border border-red-600"
                                : " "
                            } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                          />
                          {formik.errors.shortAboutELiNA &&
                            formik.touched.shortAboutELiNA && (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.shortAboutELiNA}
                              </p>
                            )}
                        </div>
                      </div>
                      {/* What do you know about ELiNA? */}
                      <div class="px-3 w-full md:w-1/2 mb-5">
                        <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-[#07074D]">
                            What do you know about ELiNA?
                            <span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            id="knowAboutELiNA"
                            onBlur={formik.handleBlur}
                            onChange={handleFileChange}
                            accept=".pdf"
                            class={`${
                              formik.errors.knowAboutELiNA &&
                              formik.touched.knowAboutELiNA
                                ? "border border-red-600"
                                : " "
                            } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                          />
                          {formik.errors.knowAboutELiNA &&
                            formik.touched.knowAboutELiNA && (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.knowAboutELiNA}
                              </p>
                            )}
                        </div>
                      </div>
                      {/* What are you looking forward to while interning with ELiNA? */}
                      <div class="px-3 w-full md:w-1/2 mb-5">
                        <div class="mb-5">
                          <label class="mb-3 block text-base font-medium text-[#07074D]">
                            What are you looking forward to while interning with
                            ELiNA?<span className="text-red-500">*</span>
                          </label>
                          <input
                            type="file"
                            id="lookingAboutELiNA"
                            onBlur={formik.handleBlur}
                            onChange={handleFileChange}
                            accept=".pdf"
                            class={`${
                              formik.errors.lookingAboutELiNA &&
                              formik.touched.lookingAboutELiNA
                                ? "border border-red-600"
                                : " "
                            } w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md`}
                          />
                          {formik.errors.lookingAboutELiNA &&
                            formik.touched.lookingAboutELiNA && (
                              <p className="text-sm font-semibold text-red-500">
                                {formik.errors.lookingAboutELiNA}
                              </p>
                            )}
                        </div>
                        <div className="text-blue-500 text-xs font-bold w-full">
                          <p>All file size must be below 2MB.</p>
                          <p>
                            Only following extension files could be uploaded
                            .PDF 
                          </p>
                        </div>
                      </div>

                      <div className="lg:px-[1%]">
                        <p className="text-gray-700">
                        I hereby declare that the details and information given above are complete and true to the best of my knowledge and I will abide by the policies of the organisation.
                          <span className="text-red-500">*</span>
                        </p>

                        <input
                          id="agree"
                          onChange={formik.handleChange}
                          value={formik.values.agree}
                          type="checkbox"
                        ></input>
                        <label className="ml-3 font-bold text-[#07074D]">
                          I Agree
                        </label>
                        {formik.touched.agree && formik.errors.agree && (
                          <p className="text-sm text-red-600">
                            {formik.errors.agree}
                          </p>
                        )}
                        <div id="captcha" className="py-5">
                          <ReCAPTCHA
                            onChange={handleRecaptcha}
                            // sitekey="6LceNQYqAAAAANmxHgRcfdU_e8KW_c05MKTOBai3"
                            sitekey="6LcfLFUoAAAAACno3hdClnckkDsl4ERrkfhX7Alr"
                          />
                          {formik.errors.captcha && formik.touched.captcha ? (
                            <p className="text-sm font-semibold text-red-500">
                              {formik.errors.captcha}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="md:w-[20%] w-[90%] p-3 bg-blue-950 border-blue-950 hover:text-blue-950 hover:bg-transparent"
                >
                  Submit
                </button>
              </div>
              
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default InternForm;

import React from "react";
// import register from "../../images/professional/content/teacher.png";
import teacher from "../../images/professional/content/teacher.gif";
import doctor from "../../images/professional/content/doctor.gif";
import counsellor from "../../images/professional/content/counsellor.gif";
import educator from "../../images/professional/content/educator.gif";
import Therapists from "../../images/professional/content/Therapists.gif";
import physical from "../../images/professional/content/physical.gif";
import Speech from "../../images/professional/content/Speech.gif";
import artbased from "../../images/professional/content/artbased.gif";
import yoga from "../../images/professional/content/yoga.gif";
import life from "../../images/professional/content/life.gif";
const professionalLiContent = [
  {
    img: educator,
    li: "Special educators",
    p: "Experts in adapting teaching methods and materials to meet the needs of students with learning disabilities.",
  },
  {
    img: teacher,
    li: "Teachers",
    p: "Classroom educators who support children with ADHD, Autism, and Learning Disabilities, ensuring inclusive education.",
  },
  {
    img: counsellor,
    li: "Counselors",
    p: "Professionals providing emotional and psychological support to help children navigate their challenges.",
  },
  {
    img: doctor,
    li: "Doctors",
    p: "Medical professionals specializing in pediatric care, neurology, psychiatry, and other relevant fields.",
  },
  {
    img: Therapists,
    li: "Occupational therapists",
    p: "Specialists in helping children develop fine motor skills, sensory processing, and daily living skills.",
  },
  {
    img: Speech,
    li: "Speech therapists",
    p: "Experts in supporting children with communication disorders and language development.",
  },
  {
    img: artbased,
    li: "Art-Based therapists",
    p: "Therapists using creative expression to help children explore emotions and enhance mental health.",
  },
  {
    img: physical,
    li: "Physical educators",
    p: "Instructors focusing on physical development and fitness, including adapted physical education.",
  },
  {
    img: yoga,
    li: "Yoga trainers",
    p: "Practitioners teaching yoga to promote physical and mental well-being.",
  },
  {
    img: life,
    li: "Life skill coaches",
    p: "Coaches helping children develop essential life skills, including social skills, self-management, and problem-solving.",
  },
];

const SpecialNeedCare = () => {
  return (
    <>
      <div className="HeroBg2">
        <div className="flex flex-col  items-center gap-10 p-[5%]">
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold text-blue-700 text-center">
            Who can be part of our network?
          </h1>
          <div className="lg:p-10 p-0 ">
            <ul className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1  lg:gap-5  w-full">
              {professionalLiContent.map((item) => (
                <div
                  className="flex flex-col text-center justify-around gap-4 my-8 mx-8"
                  key={item.li}
                >
                  <div className="w-full flex justify-center">
                    <img
                      className="w-20 h-20 animate-spin-slow  "
                      src={item.img}
                      alt={item.li}
                    ></img>
                  </div>
                  <li className="lg:text-xl text-base font-black text-pink-600">
                    {item.li}
                  </li>
                  <p className="lg:text-base text-sm">{item.p}</p>
                </div>
              ))}
            </ul>
          </div>
          {/* <a href="/#/service-form">
            <button className="p-3 rounded-2xl text-xl bg-pink-600 border-pink-600 text-white hover:bg-transparent hover:border-pink-600 hover:text-pink-600 lg:px-5 lg:text-2xl">
              Join our therapy team
            </button>
          </a> */}
        </div>
      </div>
    </>
  );
};

export default SpecialNeedCare;

import React from "react";
const PartnershipContent = () => {
  return (
    <div className="w-full mx-auto flex justify-center HeroBg2 py-16 md:py-24 lg:py-36">
      <div className="max-w-screen-xl border-pink-100 rounded-2xl shadow-2xl px-6 md:px-10">
        <div className="text-center text-3xl md:text-4xl lg:text-5xl font-bold text-pink-600 my-4">
          <span>Benefits of partnering with ELiNA</span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 text-center my-8 pt-4">
          <div className="px-4 md:px-6 lg:px-8 gap-3 flex flex-col text-start mb-6 lg:mb-0">
            <div>
              <span className="font-bold text-lg md:text-xl lg:text-xl text-green-500">
                Mission alignment:
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm md:text-base lg:text-md">
                Join a cause-driven organization dedicated to championing
                neurodiversity and fostering inclusivity.
              </span>
            </div>
          </div>
          <div className="px-4 md:px-6 lg:px-8 gap-3 flex flex-col text-start mb-6 lg:mb-0 lg:border-x-2 border-gray-200">
            <div>
              <span className="font-bold text-lg md:text-xl lg:text-xl text-orange-500">
                Proven expertise:
              </span>
            </div>
            <div>
              <p className="text-gray-500 text-sm md:text-base lg:text-md">
                Leverage ELiNA’s established programs, resources, and knowledge
                in neurodiversity to create meaningful impact in your community.
              </p>
            </div>
          </div>
          <div className="px-4 md:px-6 lg:px-8 gap-3 flex flex-col text-start">
            <div>
              <span className="font-bold text-lg md:text-xl lg:text-xl text-yellow-400">
                Comprehensive support:
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm md:text-base lg:text-md">
                Receive ongoing support and training to ensure the success of
                your franchise, from initial setup to continuous operations.
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:mx-32 lg:grid-cols-2 text-center pb-8 md:pb-10  lg:my-8 lg:pt-10 lg:pb-8">
          <div className="px-4 md:px-6 lg:px-8  gap-3 flex flex-col text-start lg:border-r-2 border-gray-200 mb-6 lg:mb-0">
            <div>
              <span className="font-bold text-lg md:text-xl lg:text-xl text-blue-500">
                Brand recognition:
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm md:text-base lg:text-md">
                Benefit from ELiNA’s reputable brand and network, enhancing your
                credibility and reach.
              </span>
            </div>
          </div>
          <div className="px-4 md:px-6 lg:px-8 gap-3 flex flex-col text-start">
            <div>
              <span className="font-bold text-lg md:text-xl lg:text-xl text-red-600">
                Positive community impact:
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm md:text-base lg:text-md">
                Make a lasting difference in the lives of neurodiverse
                individuals and their families by promoting inclusion and
                empowerment.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnershipContent;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout} from "../../pages/login/loginSlice"
const Logout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // Handle logout: Remove token and navigate to login page
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    dispatch(logout())
    setIsModalOpen(false); // Close the modal
    navigate('/loginpage'); // Redirect to login page after logout
  };

  // Open the modal
  const openModal = () => setIsModalOpen(true);

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-grey-800">
     
      <div className="w-1/3 bg-white h-1/3 flex flex-col justify-center items-center rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-black mb-4">Logout </h2>
        <h2 className="text-xl font-semibold mb-4">Are you sure you want to log out?</h2>
        
        <div className="flex justify-around w-full px-11">
        
          <button
            onClick={() => navigate('/admin/')} // Or wherever you want to redirect without logging out
            className="bg-white border-black text-black px-4 py-2 rounded-md mx-2 hover:bg-gray-700 hover:text-white"
          >
            Cancel
          </button>
          
          <button
            onClick={handleLogout}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mx-2 hover:bg-blue-700 hover:text-white border-none"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;

import React from "react";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
const Internfaq = () => {
  const faqItems = {
    parents: [
      {
        id: "1",
        question: "What types of internships are available at ELiNA?",
        answer:
          "We offer internships focused on social media content creation, design, and blog/research content development. Interns will gain experience in creating engaging social media content, designing graphics, managing online platforms, and developing insightful blog and research articles that align with ELiNA’s mission. ",
      },
      {
        id: "2",
        question: "What are the eligibility criteria for applying?",
        answer:
          "This internship is open to high school students, graduates in related disciplines, or anyone who wants to understand neurodivergence while contributing to our organization. A strong passion for social media, writing, research, and creativity, along with an interest in supporting neurodivergent children, is essential.",
      },
      {
        id: "3",
        question: "How long is the internship program?",
        answer:
          "The internship program is 6 weeks long, with a minimum commitment of 3-4 hours per week.",
      },
      {
        id: "4",
        question: " What will I be doing as an intern at ELiNA?",
        answer:
          "As an intern, you will be involved in  Social Media Content Creation: Developing visual content for our social media channels, assisting with content planning, and managing our online presence,Design: Creating engaging graphics and marketing materials that reflect ELiNA’s mission.Blog and Research Content: Writing and researching articles on topics related to ADHD, Autism, Learning Disabilities, and inclusive education. You’ll help create content that informs and engages our audience, contributing to our blog and other publications.",
      },
      {
        id: "5",
        question: "Will I receive training or orientation?",
        answer:
          "Yes, all interns undergo an initial orientation to familiarize them with ELiNA’s mission, values, and content strategy. Ongoing mentorship and training will be provided to help you develop your skills in content creation, design, and research.",
      },
      {
        id: "6",
        question: " Is this a paid internship?",
        answer:
          "No, this is an unpaid internship. However, you will receive valuable experience and insights, along with a certificate and letter of completion at the end of the internship.",
      },
      {
        id: "7",
        question: "Can I earn academic credit for this internship?",
        answer:
          "Depending on your school’s policies, you may be able to earn academic credit for this internship. Please check with your school or university for specific guidelines, and we are happy to assist with any necessary documentation.",
      },
      {
        id: "8",
        question: " How do I apply for an internship?",
        answer:
          "You can apply by filling out the internship application form on our website. Make sure to include your resume, a cover letter explaining your interest in social media content creation, design, and research, and any relevant academic or professional references.",
      },
      {
        id: "9",
        question: "What skills will I gain during my internship?",
        answer:
          "Interns at ELiNA gain valuable skills in social media strategy, content creation, graphic design, research, and blog writing. You’ll also develop strong communication, creativity, and time-management skills, preparing you for future academic or career pursuits in related fields.",
      },
      {
        id: "10",
        question: "What is the work environment like at ELiNA?",
        answer:
          "ELiNA offers a supportive and collaborative work environment. Our team is dedicated to creating a positive and inclusive space where creativity and innovation thrive. Interns are valued members of our team and are encouraged to share their ideas and contribute to our content strategy.",
      },
      {
        id: "11",
        question: "Can I work remotely as an intern?",
        answer:
          "Yes, this internship is conducted entirely online, allowing you to work remotely and manage your schedule flexibly.",
      },
      {
        id: "12",
        question:
          " What are the opportunities for growth after the internship?",
        answer:
          "While this internship is not paid, it offers valuable experience and insights into neurodivergence and digital content creation. Interns will receive a certificate and letter of completion, which can be a valuable addition to your academic or professional portfolio.",
      },
      {
        id: "13",
        question: "Who can I contact for more information?",
        answer:
          "If you have any further questions, please reach out to our internship coordinator at alka@ELiNAservices.in or visit our website for more details.",
      },
    ],
  };
  const [openId, setOpenId] = useState(null);
  const toggleAccordion = (id) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };
  return (
    <div className="flex flex-col items-center mb-20">
      <div className="p-4 w-full max-w-3xl bg-pink-500 rounded-lg shadow-lg text-center text-xl lg:text-3xl md:text-2xl font-bold text-white mb-4">
        For Intern
      </div>
      {faqItems.parents.map((data) => (
        <div
          key={data.id}
          className="w-full max-w-3xl p-5 mb-4 bg-white rounded-lg shadow-lg"
        >
          <div
            className="flex justify-between items-center cursor-pointer text-lg font-semibold"
            onClick={() => toggleAccordion(data.id)}
          >
            <span className="md:text-xl text-base">{data.question}</span>
            <span>
              {openId === data.id ? (
                <FaMinus className="text-pink-500" />
              ) : (
                <FaPlus className="text-pink-500" />
              )}
            </span>
          </div>
          {openId === data.id && (
            <p className="mt-3 text-grey-600  lg:text-lg md:text-lg text-base rounded-lg p-2 border-l-4 border-pink-500">
              {data.answer}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Internfaq;

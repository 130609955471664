
import parentFamily from "../../images/compressed/parent/parentHero-min2.jpg";


const ParentHero = () => {
  return (
    <>
      <div className="HeroBg relative ">
        <div className=" flex flex-col  lg:flex-row mx:[4%] lg:mx-[6%]   md:pt-[3%]  lg:pb-10 ">
          <div className=" flex flex-col justify-center text-center lg:text-start md:text-center gap-14 md:gap-6 my-6 mx-10 mt-7 md:mx-24 md:my-10  lg:pr-10 lg:m-0">
            <h1 className=" text-5xl font-black text-blue-700 md:text-4xl lg:text-6xl">
              No Parent Should feel alone EVER.
              <br />
              <span className="text-3xl  text-pink-600 md:text-6xl lg:text-4xl">
                Empowering Your Child's Journey to a Brighter Future!
              </span>
            </h1>
            <p className="  md:pb-0  text-xl text-center lg:text-start">
              Get personalized plans and expert guidance to help your
              neurodivergent child
            </p>
            {/* <button className="bg-pink-600 w-[60%] lg:w-[50%] p-1  md:p-0 mx-auto lg:mx-0 border-pink-600 h-10 lg:h-12 text-xl hover:text-pink-600 hover:border-pink-600">
              <a href="/#/parent-form" className="">
                Join our program{" "}
              </a>
            </button> */}
          </div>
          <>
            <img
              className="m-10 lg:m-0 rounded-3xl object-cover lg:block lg:w-[45%]"
              src={parentFamily}
              alt="Hero Bg"
            />
          </>
        </div>
      </div>
    </>
  );
};
export default ParentHero;





// import React, { useState, useEffect } from "react";

// // import parentFamily from "../../images/parentFamily.jpg";
// import parentFamily from "../../images/compressed/parent/parentHero-min2.jpg";
// import BreadCrumbs from "../reuseable/BreadCrumbs";
// const ParetnHero = () => {
//   const [currentPage, setCurrentPage] = useState("");

//   useEffect(() => {
//     const updateCurrentPage = () => {
//       const pathname = window.location.pathname;
//       setCurrentPage(pathname === "/" ? "Home" : pathname);
//     };
//     updateCurrentPage();

//     // Listen to route changes
//     window.addEventListener("popstate", updateCurrentPage);

//     return () => {
//       window.removeEventListener("popstate", updateCurrentPage);
//     };

//   }, []);

//   return (
//     <>
//       <BreadCrumbs />
//       <div className="parentBg">
//         <div className="lg:bg-black-bg bg-black-rgba">
//           <div className="flex flex-col lg:gap-5 items-center text-transparent px-5  lg:px-[10%] lg:pt-[10%] lg:pb-[6%]">
//             <div className="lg:bg-transparent lg:p-10 md:p-5 my-5  lg:rounded-3xl">
//               <h1 className="text-6xl lg:text-[4.75rem] text-transparent py-5 md:py-0 text-center font-black">
//                 No Parent Should feel alone EVER.
//               </h1>
//               <h2 className="text-3xl font-black text-center p-4 text-gray-200 lg:text-transparent lg:text-2xl">
//                 "Empowering Your Child's Journey to a Brighter Future!"
//               </h2>
//               <p className="text-sm p-3 text-center text-gray-200 lg:text-transparent lg:text-lg">
//                 <span className="underline font-bold cursor-pointer">
//                   <a href="/#/parent-form">Join us today!</a>
//                 </span>{" "}
//                 Get personalized plans and expert guidance to help your
//                 neurodivergent child
//               </p>
//             </div>
//           </div>
//           <div className="flex justify-center pb-8">
//             <a
//               href="/#/parent-form"
//               class="flex flex-col items-center w-[90%] bg-white border border-gray-200 rounded-lg md:py-7 md:px-5 shadow md:flex-row md:max-w-3xl "
//             >
//               <img
//                 class="object-contain w-[90%] py-5 lg:py-0 lg:w-64 lg:mx-5 rounded-t-lg lg:h-auto  md:h-auto md:w-48 md:rounded-lg"
//                 src={parentFamily}
//                 alt="Get personalized plans"
//               />
//               <div class="flex flex-col justify-between px-4 pb-3 leading-normal">
//                 <h5 class="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
//                   No Parent Should feel alone EVER.
//                 </h5>

//                 <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
//                   Let us help you find clarity and hope with ELiNA. Discover how
//                   our coordinated approach can make a difference in your child's
//                   life
//                 </p>
//                 <h1 className="mb-2 text-xl md:text-lg font-bold tracking-tight text-gray-600 dark:text-white">
//                   Get personalized plans and expert guidance
//                 </h1>
//                 <button className="bg-pink-600 w-[60%] lg:w-[50%] p-1 md:p-0 mx-auto lg:mx-0 border-pink-600 lg:h-8 text-lg hover:text-pink-600 hover:border-pink-600">
//                   <a href="/#/parent-form">Join our program </a>
//                 </button>
//               </div>
//             </a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ParetnHero;

import React, { useEffect, useState } from "react";
import eventposter from "../../images/events/eventposter.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Currentevent = () => {
  const [posterImg, setPosterImg] = useState([]);
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/poster`)
        const posterData = response.data;

        setPosterImg(posterData)
      } catch (error) {
        console.log("Error while fetching the poster Data", error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData();
  }, [])

  return (
    <div className="lg:my-16  justify-center flex flex-col">
      <div className="text-black text-center my-12 px-2 md:px-0 lg:px-0">
        <p className="text-3xl md:text-5xl lg:text-6xl font-bold text-indigo-600">
          Welcome to ELiNA's events page
        </p>
        <p className="text-xl md:text-xl lg:text-xl font-normal lg:mt-3 sm:mt-3 mt-2 text-gray-500 lg:px-32 md:px-20 px-4">
          Here, you’ll find information about our upcoming workshops, seminars,
          and community events designed to support and empower families and
          professionals involved in the special needs community.
        </p>
      </div>
      {
        isLoading ? (
          <div className="flex  flex-col items-center justify-center h-48">
            <i className="pi pi-spin pi-spinner text-indigo-600 text-4xl"></i>
            <h1 className="ml-4 text-indigo-600 md:text-lg text-base">please Wait Loading Event Poster...</h1>
          </div>
        ) : (
      <div>
        {posterImg.map((items, index) => {
          const base64String = btoa(
            new Uint8Array(items.poster.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
          const imgSrc = `data:image/jpeg;base64,${base64String}`;

          return (
            <div key={index} className="items-center flex justify-center relative">
              <img
                className="lg:w-1/2 md:w-2/3 w-4/5 h-fit"
                src={imgSrc}
                alt={items.name}
              />
              <div className="absolute lg:bottom-36 md:bottom-28 bottom-16">
                <a
                  className="bg-blue-500 md:p-2 p-1 sm:px-3 md:px-4 lg:px-5 text-sm px-2 md:text-lg lg:text-3xl rounded-md font-bold text-white"
                  href={items.form_link}
                >
                  REGISTER NOW
                </a>
              </div>
            </div>
          );
        })}
      </div>
      )
      }




    </div>
  );
};

export default Currentevent;
